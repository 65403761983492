@import './minix/common';
@import './minix/_swiper';
@import './minix/_animate';
html
    overflow-x hidden
.layout
    overflow-x hidden
$color = #1f6bb8
$lineColor = #cccccc
.fly
    width 15px
    height 15px
    border-radius 50%
    background red
    z-index 9999

/* 下拉菜单 */
.sp-select{
        width 220px
        border-width: 1px;
        border-color: rgb(220, 225, 230);
        border-style: solid;
        max-width: 100%;
        height: 45px;
        border-radius: 4px;
        position: relative;
        transition: 0.5s;
        background: #ffffff;
        .icon-arr{
            width 19px
            height 40px
            background-image: url(../images/select-arr.png)
        }
        h3{
            line-height: 45px;
            font-size: 14px;
            color: #242526;
            padding:0 10px;
            position: relative;
            cursor: pointer;
            user-select: none;
            .select-title{
                display flex
                .icon{
                    width 16px
                    margin-right 5px
                }
                .text{
                    display flex
                    align-items center
                    height 42px
                    flex 1
                }
            }
            .icon-arr{
                position: absolute;
                right: 8px;
                bottom: auto;
                top: 50%;
                transform: translateY(-50%);
                line-height: normal;
            }
        }

        .select-list{
            opacity: 0;
            transition: 0.5s;
            visibility: hidden;
            position: absolute;
            z-index: 999;
            left: 0;
            right: 0;
            top: 65px;
            background: #ffffff;
            z-index: 193;
            border-radius: 5px;
            padding-top: 9px;
            padding-bottom: 9px;
            box-shadow: 0 0 30px rgba($color, 0.25);
            .item{
                height: 36px;
                line-height: 36px;
                padding:0 20px;
                cursor pointer
                .icon{
                    width 16px
                    margin-right 5px
                }
                .text{
                    display flex
                    align-items center
                    font-size 12px
                    color #666666
                }
                &.is-active{
                    background: #f0f7ff;
                    color: #1d87f1;
                }
            }
            .select-search{
                margin-bottom: 10px;
                padding: 9px;
                position: relative;
                &::before{
                    content: '';
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    background: url(../imgs/search.png) no-repeat;
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    transform: translateY(-50%);
                }
                .text{
                    width: 100%;
                    border-radius: 3px;
                    background-color: rgb(242, 246, 250);
                    width: 330px;
                    height: 40px;
                    padding:0 10px;
                    font-size: 14px;
                    padding-right: 40px;
                }
            }
            ul{
                max-height: 180px;
                overflow-y: auto;
            }
        }
        &.is-active{
            border: 1px solid $color;
            box-shadow: 0 0 30px rgba($color, 0.25);
            .select-list{
                opacity: 1;
                top: 55px;
                visibility: visible;
            }
        }
    }

img
    max-width 100%
body,html
    min-width 1280px
    overflow-x: auto;
    background #f9f6f7
*
    box-sizing: border-box
    outline none
.index-more
    text-align right
    .more
        background-repeat no-repeat
        background-image url(../images/index-more.png)
        background-position left center
        height 25px
        display inline-block
        line-height 25px
        font-size 26px
        color #414141
        padding-left 30px
        &:hover
            color $color
            background-image url(../images/index-more-hover.png)

.toe
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
    display inline-block
    width 100%
.icon
    position relative
    display inline-block
    background-repeat no-repeat
    background-position center
.header
    position fixed
    right 0
    top 0
    left 0
    z-index 998
    color #ffffff
    font-size 16px
    height 85px
    align-items center
    display flex
    -webkit-perspective: 15000px;
    -moz-perspective: 15000px;
    perspective: 15000px;
    transition: background .5s;
    .cube
        width 100%
        height 100%
        display flex
        position: relative;
        transform-style: preserve-3d;
        perspective: 15000px;
        transform: translateZ(-40px);
        transition: transform .5s,-webkit-transform .5s,-moz-transform .5s,-o-transform .5s;
    &.is-open
        .site-nav
            background rgba(#fff,0.6)
            opacity 1
        .nav
            opacity 0
        .cube
            webkit-transform: translateZ(-40px) rotateX(-90deg);
            -moz-transform: translateZ(-40px) rotateX(-90deg);
            transform: translateZ(-40px) rotateX(-90deg);
    &.is-active
        background-image url('../images/header-bg.png')
        background-repeat repeat-x
        background-position top center
    &.is-white
        background #ffffff
    &.is-active
        .nav .meun-icon
            opacity 0
    &.is-white
        color #000000
        .site-nav
            background #ffffff
            border-bottom 1px solid $lineColor
        .nav
            background #ffffff
            border-bottom 1px solid $lineColor
            .icon-call
                background-image url('../images/icon-call2.png')
            .icon-arr
                background-image url('../images/icon-head2-1.png')
            .icon-login
                background-image url(../images/icon-login-1.png)
            .icon-sear
                background-image url(../images/icon-header-sear-1.png)
            .icon-app
                background-image url(../images/icon-header-app-1.png)
        .meun-icon
            opacity 0
            visibility hidden
        .nav a
            color #000000
    &.is-white
        .nav .meun-icon
            color #333333
            opacity 1
            visibility visible
            background-image url(../images/meun-icon2.png)
    &.is-active.is-white
        .nav .meun-icon
            visibility hidden
            opacity 0
    &.is-ucenter
        .nav .meun-icon
            visibility hidden
            opacity 0
    .nav
        display flex
        justify-content space-between
        position: absolute;
        align-items center
        height: 100%;
        width: 100%;
        flex 1
        -webkit-transform: rotateY(0) translateZ(40px);
        -moz-transform: rotateY(0) translateZ(40px);
        transform: rotateY(0) translateZ(40px);
        padding 0 24px
        .meun-icon
            @extend .icon
            background-image url('../images/meun-icon.png')
            background-position left center
            height 40px
            line-height 40px
            font-size 18px
            padding-left 45px
            cursor pointer

    .site-nav
        display flex
        flex 1
        align-items center
        justify-content space-between
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transform: rotateX(90deg) translateZ(40px);
        -moz-transform: rotateX(90deg) translateZ(40px);
        transform: rotateX(90deg) translateZ(40px);
        padding 0 24px
        opacity 0
        .meun-close
            background-image url(../images/meun-close.png)
            @extend .icon
            width 30px
            height 30px
            cursor pointer
            transition 0.3s
            &:hover
                transform rotate(90deg)
        a
            font-size 18px
            color #231815
            height 45px
            padding 0 20px
            display inline-block
            line-height 45px
            border-radius 3px
            transition none
        a:hover
            color $color
        a.active
            color #ffffff
            background $color
    .nav
        .qrcode
            position relative
            &:hover
              .code
                visibility visible
                opacity 1
                top 30px
            .code
              position absolute
              visibility hidden
              transition 0.3s
              opacity 0
              top 40px
              left -30px
              width 100px
              height 100px
        .icon-sear
            width 30px
            height 20px
            margin-right 20px
            top 4px
            background-image url(../images/icon-header-sear.png)
        .icon-app
            width 30px
            height 20px
            margin-right 20px
            top 4px
            background-image url(../images/icon-header-app.png)
        .exchange
            cursor pointer
        .en
            margin-left 20px
            display inline-block
            text-align center
            .icon
                width 24px
                height 14px
                margin-right 5px
                background-size contain
                top 2px
            .icon-arr
                width 8px
            .icon-en
                background-size cover
                background-image url(../images/gb.png)
            .icon-cn
                background-size cover
                background-image url(../images/cn.png)
            .icon-alb
                background-size cover
                background-image url(../images/icon-head1.png)
        .phone
            margin-left 20px
        .en
            cursor pointer
            position relative
            &:hover
                .subnav
                    visibility visible
                    opacity 1
            .subnav
                width 80px
                line-height 27px
                position absolute
                background #ffffff
                margin-top 10px
                opacity 0
                visibility hidden
                transition 0.3s
                text-align left
                left -10px
                a
                    display block
                    color #000000
                    padding-left 10px
                    &:hover
                        background $color
                        color #ffffff
        .icon-login
            background-image url(../images/icon-login.png)
            width 15px
            height 17px
            margin-right 5px
            top 3px
        .icon-call
            background-image url(../images/icon-call.png)
            width 12px
            height 16px
            margin-right 5px
            position relative
            top 2px
        .icon-arr
            width 8px
            height 5px
            position relative
            top -2px
            margin-left 5px
            background-image url(../images/icon-head2.png)
        .icon-rate
            margin-right 5px
            background-image url(../images/icon-head1.png)
            background-size contain
            width 24px
            height 12px
        a
            color #ffffff
            transition none
        .login
            margin-left 26px
            position relative
            &.login.top_logintext
                margin-left 0
            .icon
                margin-right 10px
            em
                font-size 12px
                position absolute
                left 18px
                top -5px
                width 13px
                height 13px
                display inline-block
                border-radius 50%
                color #ffffff
                background $color
                text-align center
                line-height 13px
.layout
    // padding-left 60px
    transition: 0.5s
    &.active
        padding-left 60px
    &.is-ucenter
        padding-top 85px
        padding-left 60px
.aside-nav
    width 215px
    position fixed
    z-index 999
    top 0
    bottom 0
    left 0
    background #ffffff
    padding-bottom 40px
    transition: width 0.5s
    border-right 1px solid #e0e0e0
    transform translate(-100%)
    .nav-btn
        background-image url(../images/aside-btn.png)
        background-repeat no-repeat
        background-position center
        transition 0.4s
        position fixed
        width 215px
        height 40px
        bottom 0
        left 0
        cursor pointer
    &.is-ucenter.active
        transform translateX(0)
        .logo
            display block
    .logo
        padding 0 12px
        border-bottom 1px solid #bebebe
        height 85px
        line-height 85px
        img
            height 55px
            transition: 1s
            max-width none
        a
            display block
    .nav
        margin-top 20px
        li
            display flex
            position relative
            overflow hidden
            span
                transition 0.3s
                flex 0 0 140px
                @extend .toe
            &.is-subnav:before
                position absolute
                top 50%
                transform translateY(-50%)
                right 20px
                background-image url(../images/arrow.png)
                background-repeat no-repeat
                background-position center
                content ''
                display inline-block
                width 10px
                height 15px
            &.is-subnav:hover
                &:before
                    background-image url(../images/arrow-actice.png)
                .subnav
                    top 0
                    opacity 1
                    visibility visible
            .subnav
                width 256px
                padding 10px 0
                position absolute
                transform translateX(218px)
                top 20px
                opacity 0
                visibility hidden
                transition 0.2s
                background #ffffff
                box-shadow 1px -1px 14px 0px rgba(0,0,0,0.1)
                a
                    @extend .toe
                    font-size 14px
                    padding 0 15px
                    &:hover
                        background $color
                        color #fff

            .icon
                flex 0 0 40px
                background-repeat no-repeat
                background-position center
            .icon1
                background-image url(../images/icon1.png)
            .icon2
                background-image url(../images/icon2.png)
            .icon3
                background-image url(../images/icon3.png)
            .icon4
                background-image url(../images/icon4.png)
            .icon5
                background-image url(../images/icon5.png)
            .icon6
                background-image url(../images/icon6.png)
            .icon7
                background-image url(../images/icon8.png)
            .icon8
                background-image url(../images/icon7.png)
            .icon9
                background-image url(../images/icon9.png)
            .icon10
                background-image url(../images/icon10.png)
            .icon11
                background-image url(../images/icon11.png)
            a
                padding-left 10px
                display flex
                flex 1
                height 50px
                line-height 50px
                color #000000
                font-size 14px
                &:hover
                    color $color
                    .icon1
                        background-image url(../images/icon1-1.png)
                    .icon2
                        background-image url(../images/icon2-1.png)
                    .icon3
                        background-image url(../images/icon3-1.png)
                    .icon4
                        background-image url(../images/icon4-1.png)
                    .icon5
                        background-image url(../images/icon5-1.png)
                    .icon6
                        background-image url(../images/icon6-1.png)
                    .icon7
                        background-image url(../images/icon8-1.png)
                    .icon8
                        background-image url(../images/icon7-1.png)
                    .icon9
                        background-image url(../images/icon9-1.png)
                    .icon10
                        background-image url(../images/icon10-1.png)
                    .icon11
                        background-image url(../images/icon11-1.png)
    &.active
        width 60px
        .logo
            display none
        .nav
            // border-top 1px solid #bebebe
            margin-top 5px
            li
                // &:hover
                //     span
                //         opacity 1
                //         position absolute
                //         left 60px
                //         background #ffffff
                &.is-subnav:before
                    display none
                span
                    flex:  0 0 1px
                    opacity 0
                    overflow hidden
                    width 1px
                    visibility hidden
        .logo
            overflow hidden
            img
                height 39px
        .nav-btn

            width 60px
            transform rotateY(-180deg)
            transform-origin center
        .nav li .subnav
            transform translateX(62px)
    &.is-show
        transform translateX(0)
        .nav li
            overflow visible
.index-home
    height 100vh
    position relative
    .con
        .head
            dl
                display inline-flex
                justify-content space-between
                align-items center
            dd
                font-size 28px
                color #ffffff
                padding 0 25px
                position relative
                font-weight bold
                cursor pointer
                &.active
                    color $color
                &:after
                    content ''
                    display inline-block
                    width 9px
                    height 24px
                    background url(../images/line.png)
                    position absolute
                    right -8px
                    top 5px
                &:nth-child(5)
                    &:after
                        display none
            dt
                font-size 22px
                font-weight bold
                a
                    color #ffffff
                    width 277px
                    height 36px
                    line-height 36px
                    background #072933
                    border-radius 18px
                    display inline-block
                    transition 0.3s
                    &:hover
                        background $color
        .body
            .icon
                width 30px
                height 20px
                position relative
                top 4px
                margin-left 5px
                background-image url(../images/icon-home.png)
            .icon-type
                background-image url(../images/icon-type.png)
            .icon-room
                background-image url(../images/icon-room.png)
            .icon-price
                background-image url(../images/icon-price.png)

            .item
                display none
                &:first-child
                    display block
            .search
                display flex
                justify-content center
                margin-top 20px
            .left-input
                background #ffffff
                height 64px
                width 900px
                display flex
                justify-content center
                position relative
                z-index 999
                .location
                    align-items center
                    display flex
                    position relative
                    .icon-home
                        top -1px
                    &::before
                        display none
                    input
                        font-size 18px
                    .select-list
                        position absolute
                        left 0
                        opacity 1
                        visibility visible
                        top 100%
                        right 0
                .type
                    >span
                        @extend .toe
                        // width: 184px;
                        // position: relative;
                        // overflow: hidden;
                        // top: 10px;
                        // height: 30px;
                        // display: inline-block;
                        // line-height: 30px;
                        padding-left 35px
                        position absolute
                        top 0
                        left 0
                        right 0
                        bottom 0
                    .price-input
                        right auto
                        left auto
                .text
                    width 100%
                    padding 0 10px
                    font-size 18px
                .price-input
                    opacity 0
                    visibility hidden
                    transition 0.3s
                    background #ffffff
                    height 80px
                    width 450px
                    margin-top 1px
                    position absolute
                    right 0
                    display flex
                    align-items center
                    justify-content center
                    span
                        display inline-block
                        text-align center
                        color #000000
                        width 27px
                    input
                        width 186px
                        height 40px
                        border 1px solid #d1d1d1
                        color #5a5a5a
                        padding 0 10px
                        font-size 14px
                        border-radius 3px
                .select-list
                    opacity 0
                    visibility hidden
                    margin-top 1px
                    padding 10px 0
                    background #ffffff
                    overflow-y auto
                    font-size 14px
                    transition 0.2s
                    z-index 99
                    max-height 200px
                    box-shadow 0 0 10px rgba(#000,0.1)
                    li
                        line-height 37px
                        color #5a5a5a
                        padding 0 23px
                        @extend .toe
                        display block
                        &.active,&:hover
                            background $color
                            color #ffffff
                >div
                    flex 1
                    border-right 1px solid #cccccc
                    position relative
                    cursor pointer
                    &:before
                        position absolute
                        right 0
                        width 13px
                        height 7px
                        background-image url('../images/arrow-down.png')
                        content ''
                        display inline-block
                        right 10px
                        top 50%
                        transform translateY(-50%)
                    &:hover
                        color #1f6bb8
                        .select-list, .price-input
                            opacity 1
                            visibility visible
                font-size 18px
                color #5a5a5a
                line-height 64px
                text-align left
            .submit
                height 64px
                width 142px
                background $color
                color #ffffff
                font-size 22px
                transition 0.2s
                cursor pointer
                &:hover
                    background $color+10%
    .nav
        position absolute
        bottom 0
        height 102px
        background #ffffff
        left 0
        right 0
        z-index 99
        display flex
        align-items center
        .wrap
            display flex
            justify-content center
            align-items center
            text-align center
            padding 28px 0
        .item
            flex 1
            border-right 1px solid #e2e2e2
            line-height 32px
            .icon
                width 42px
                height 32px
                position relative
                top 7px
            .icon1
                background-image url(../images/nav-icon1.png)
            .icon2
                background-image url(../images/nav-icon2.png)
            .icon3
                background-image url(../images/nav-icon3.png)
            .icon4
                background-image url(../images/nav-icon4.png)
            &:last-child
                border-right none
            a
                font-size 22px
                color #414141
                &:hover
                    color $color
    .index-banner
        position relative
        overflow hidden
        .banner-con
            z-index 99
            text-align center
            width 1100px
            left 50%
            top 50%
            transform translate(-50%, -80%)
            position absolute
            color #ffffff
            .icon-publish
                background-image url(../images/icon-home1.png)
                width 17px
                height 16px
                position relative
                top -1px
                margin-right 10px
            .slogen
                margin-bottom 60px
                li
                    height 173px
                    display flex
                    flex-direction column
                    align-items center
                    justify-content center
                    display none
                h3
                    font-size 40px
                    font-weight bold
                h4
                    font-size 60px
                    font-weight bold
                h5
                    font-size 16px
        .swiper-slide
            height 100vh
            background-size cover
            background-position center
.search-modal
    top 0
    bottom 0
    left 0
    right 0
    z-index 999
    position fixed
    display none
    background rgba(#000000,0.9)
    .header-sear-close
        width 44px
        height 44px
        display inline-block
        background-image url(../images/header-close.png)
        position absolute
        right 10%
        top 20px
        cursor pointer
        &:hover
            opacity 0.9
    .icon-publish
        background-image url(../images/icon-home1.png)
        width 17px
        height 16px
        position relative
        top -1px
        margin-right 10px
    .con
        position absolute
        top 50%
        left 50%
        transform translate(-50%, -50%)
        @extend .index-home .con
        .head dt a
            text-align center
            background-color transparent
.share-links
    background #faf6f7
    height 155px
    display flex
    align-items center
    justify-content center
    a
        width 48px
        height 48px
        border-radius 50%
        background-color #282828
        @extend .icon
        margin 0 11px
        transition 0.2s
        &:hover
            background-color $color
    .share-link1
        background-image url(../images/twitter.png)
    .share-link2
        background-image url(../images/linkedin.png)
    .share-link3
        background-image url(../images/facebook.png)
    .share-link4
        background-image url(../images/ins.png)
    .share-link5
        background-image url(../images/google-plus.png)
.index-wrap
    background url(../images/index-bg.jpg)
    background-repeat no-repeat
.wrap
    width 1458px
    // overflow hidden
    margin 0 auto

.index-list
    padding-top 73px
    position relative
    .wrap
        // width 1628px
        overflow visible
        padding-bottom 115px
    .head
        margin-bottom 10px
        ul
            display flex
            justify-content center
            align-items center
        li
            font-size 50px
            padding 0 54px
            text-align center
            color #000000
            border-right 1px solid #d5d2d3
            cursor pointer
            .icon
                width 45px
                height 40px
                margin-right 15px
            .icon1
                background-image url(../images/vr-icon1.png)
            .icon2
                background-image url(../images/vr-icon2.png)
            .icon3
                background-image url(../images/vr-icon3.png)
            &.active,&:hover
                color #1f6bb8
                .icon1
                    background-image url(../images/vr-icon1-1.png)
                .icon2
                    background-image url(../images/vr-icon2-1.png)
                .icon3
                    background-image url(../images/vr-icon3-1.png)
            &:last-child
                border-right none
    .body
        .tab-item
            display none
            position relative
            .slide
                overflow hidden
                padding 15px 0
            .page
                .swiper-button-prev
                    background-image url(../images/left-arrow-3.png)
                    left -40px
                    transition 0.3s
                    &:hover
                        background-image url(../images/left-arrow-3-1.png)
                        left -45px
                .swiper-button-next
                    background-image url(../images/right-arrow-3.png)
                    right -40px
                    transition 0.3s
                    &:hover
                        background-image url(../images/right-arrow-3-1.png)
                        right -45px
            .pager
                font-size 28px
                color #414141
                position absolute
                bottom -80px
                left 0
                right 0
                display flex
                justify-content center
                align-items center
                width 420px
                margin 0 auto
                line-height 46px
                .line
                    width 150px
                    background #b3afb0
                    height 1px
                    margin 0 15px
                .swiper-pagination
                    font-size 28px
                    color $color
                    .swiper-pagination-total
                        color #414141
                .swiper-pagination
                    position static
                    width:auto
                .swiper-button-next,.swiper-button-prev
                    margin-top 0
                    position static
                    width 46px
                    height 46px
                    display inline-block
                    background-size contain
                    background-repeat no-repeat
                    background-image url(../images/left-arrow.png)
                    &:hover
                        background-image url(../images/left-arrow-1.png)
                .swiper-button-next
                    margin-left 10px
                    background-image url(../images/right-arrow.png)
                    &:hover
                        background-image url(../images/right-arrow-1.png)
        .tab-item:first-child
            display block
        .slide-item
            // display flex
            // overflow hidden
            // position relative
            .slick-arrow
                cursor pointer
                position absolute
                width 38px
                height 68px
                top 50%
                transform translateY(-50%)
                background-position center
                background-repeat no-repeat
                background-color transparent
                color transparent
                background-image url(../images/left-arrow.png)
            .slick-prev
                left -40px
            .slick-next
                right -40px
                background-image url(../images/right-arrow.png)

        .item
            height 450px
            position relative
            // flex 1
            background #ffffff
            // margin 0 15px
            transition: 0.5s

            &:hover
                box-shadow -2.179px 3.355px 9px 0px rgba(0, 0, 0, 0.1)
            .img
                overflow hidden
                position relative
                .right-link
                    position absolute
                    top 15px
                    right 15px
                    .icon
                        width 20px
                        height 18px
                    .icon-heart
                        background-image url(../images/vr-icon5.png)
                    .icon-add
                        margin-left 10px
                        background-image url(../images/vr-icon6.png)
                .left-text
                    position absolute
                    bottom 0
                    left 23px
                    color #ffffff
                    bottom 14px
                    span
                        font-weight bold
                        font-size 16px
                        padding-right 15px
                        background-image url(../images/vr-icon7.png)
                        background-repeat no-repeat
                        background-position right center
                    .number
                        font-size 40px
                        color #ffffff
                        font-weight bold
                img
                    transition 0.4s
                    width 100%
                    display block
                    height 292px
            &:before
                content ''
                width 49px
                height 49px
                background-color #2f2f2f
                @extend .icon
                position absolute
                background-image url(../images/vr-icon4.png)
                top 0
                left 0
                z-index 9
            &.no-vr
                &:before
                    display none
            h3
                margin-bottom 10px
                a
                    font-size 30px
                    color #414141
                    @extend .toe
                    &:hover
                        color $color
            h4
                a
                    font-size 16px
                    color #828282
            .text
                border-bottom 1px solid #f2f2f2
                padding 15px 20px
                height 102px
            .icon-date
                width 16px
                height 16px
                position relative
                top 2px
                margin-right 5px
                background-image url(../images/icon-date.png)
            .tags
                padding 0 25px
                display flex
                justify-content space-between
                align-items center
                font-size 16px
                height 52px
                color #828282
                .tag:nth-child(2)
                    text-align center
                .tag:last-child
                    text-align right
                .icon
                    width 16px
                    height 16px
                    position relative
                    top 2px
                    margin-right 5px
                .icon-home
                    background-image url(../images/vr-icon8.png)
                .icon-room
                    background-image url(../images/vr-icon9.png)
                .icon-bath
                    background-image url(../images/vr-icon10.png)
                .tag
                    flex 1
        .slide3
            .item
                &:before
                    width: 90px;
                    height: 90px;
                    display: inline-block;
                    background-color: transparent;
                    background-image none
                &.item-luxury
                    &:before
                        background-image url('../images/item-luxury.png')
                &.item-new
                    &:before
                        background-image url('../images/item-news.png')
.index-averages
    padding-top 65px
    .wrap
        padding-bottom 20px
    .head
        text-align center
        h3
            font-size 50px
            margin-bottom 30px
            color #000000
        p
            font-size 16px
            color #000000
            line-height 26px
    .body
        margin-top 40px
    .tab-head
        margin-bottom 25px
        ul
            display flex
        li
            flex 0 0 150px
            font-size 20px
            height 50px
            line-height 48px
            text-align center
            border 1px solid #c4c4c4
            border-right none
            &:last-child
                border-right 1px solid #c4c4c4
            &.active,&:hover
                border 1px solid $color
                background $color
                border-right none
                color #ffffff
                cursor pointer
    .tab-body
        display flex
    .right-list
        flex 1
        display flex
        .item
            flex 1
            background #ffffff
            box-shadow: -2.723px 4.193px 13px 0px rgba(0, 0, 0, 0.1);
            margin-left 25px
            position relative
            padding 52px 25px
            text-align center
            &:hover
                h3,h4
                    color $color
            h3
                font-size 50px
                color #000000
            h4
                font-size 24px
                color #414141
            .tit
                height 75px
                font-size 18px
                color #000000
                line-height 1.3
                margin-bottom 40px
            .icon
                position absolute
                top 0
                left 50%
                transform translate(-50% , -50%)
                width 70px
                height 70px
                border 10px solid #f9f3f5
                background-color $color
                border-radius 50%
            .icon1
                background-image url(../images/icon-averages1.png)
            .icon2
                background-image url(../images/icon-averages2.png)
            .icon3
                background-image url(../images/icon-averages3.png)
    .left-chart
        flex 0 0 653px
        border 1px solid #ececec
        border-top 4px solid $color
        background-color: rgb(255, 255, 255);
        box-shadow: -2.723px 4.193px 13px 0px rgba(0, 0, 0, 0.1);
        height: 343px;
        display flex
        flex-direction column
        position relative
        .tag
            position absolute
            top 20px
            right 3%
            display flex
            height 24px
            font-size 12px
            color #414141
            border 1px solid #c4c4c4
            width 162px
            line-height 22px
            span
                flex 1
                cursor pointer
                text-align center
                &.active,&:hover
                    background $color
                    color #ffffff
        #index-chart
            flex 1

.index-vr
    padding-top 74px
    .head
        text-align center
        h3
            font-size 50px
            color #000000
            margin-bottom 30px
        p
            font-size 16px
            color #000000
            line-height 26px
    .body
        margin-top 45px
        .img
            position relative
            &:before
                width 207px
                height 143px
                display inline-block
                background-size contain
                background-position bottom center
                background-repeat no-repeat
                background-image url(../images/vr-img.png)
                position absolute
                left -129px
                bottom -2px
                content ''
        .btns
            text-align center
            margin-top 30px
            .icon
                height 35px
                width 35px
                position relative
                top 10px
            .icon1
                background-image url(../images/vr-icon11.png)
            .icon2
                background-image url(../images/vr-icon12.png)
            a
                &:first-child
                    border-right none
                width 425px
                height 57px
                line-height 57px
                border 1px solid #a9a9a9
                display inline-block
                font-size 16px
                color #000000
                &:hover
                    background $color
                    color #ffffff
                    .icon1
                        background-image url(../images/vr-icon11-1.png)
                    .icon2
                        background-image url(../images/vr-icon12-1.png)
.index-download
    border-bottom 1px solid #e0e0e0
    margin-top 110px
    height 599px
    display flex
    .right-img
        align-self flex-end
    .left-con
        padding-top 30px
        width 645px
    .small-title
        font-size 14px
        color #666666
        margin-bottom 26px
    .title
        font-size 50px
        color #000000
        line-height 1.1
        margin-bottom 30px
    .txt
        font-size 16px
        line-height 1.3
        color #414141
    .qrcode
        display flex
        margin-top 55px
        margin-bottom 40px
        .btns
            margin-left 33px
        .btn
            width 180px
            height 48px
            border 1px solid #a9a9a9
            @extend .icon
            display block
            border-radius 3px
            transition 0.2s
            &:hover
                box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
                border 1px solid #000000
        .google
            background-image url(../images/google.png)
        .appStore
            margin-bottom 18px
            background-image url(../images/appStore.png)
    .tip
        font-size 16px
        color #000000
    .icon-scan
        width 15px
        height 15px
        position relative
        top 2px
        margin-right 5px
        background-image url(../images/icon-scan.png)
    .wrap
        display flex
        justify-content space-between
        overflow visible
.index-news
    padding-top 74px
    padding-bottom 10px
    background #ffffff
    // padding-left 2%
    // padding-right 2%
    position relative
    .item
        display flex
        .swiper-slide
            flex 0 0 33.33%
            height 340px
    .slick-list
        height 420px
    .slick-dots
        position absolute
        bottom 45px
        left 0
        right 0
        display flex
        justify-content center
        align-items center
        li
            margin 0 5px
            width 16px
            height 16px
            background #d9d9d9
            border-radius 8px
            transition 0.2s
            cursor pointer
            button
                display none
            &.slick-active
                background #4c4c4c
                width 60px
    .title
        text-align center
        margin-bottom 22px
        h3
            font-size 50px
            color #000000
    .index-more
        margin-bottom 20px
    .tag
        color #666666
        font-size 14px
        margin-bottom 10px
        text-transform uppercase
    .swiper-slide
        padding 0 54px
        border-left 1px solid #d8d8d8
        &:first-child
            border-left none
        h3
            position relative
            padding-bottom 20px
            margin-bottom 30px
            toe(3)
            &:before
                    width 60px
                    height 2px
                    content ""
                    display inline-block
                    background $color
                    position absolute
                    left 0
                    bottom 0
            a
                font-size 30px
                color #000000
                line-height 32px
                &:hover
                    color $color
        p
            font-size 14px
            line-height 24px
            color #414141
            toe(3)
        .btn
            border 1px solid #333333
            border-radius: 3px;
            box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
            text-align center
            width 149px
            height 48px
            line-height 48px
            display inline-block
            color #414141
            font-size 14px
            cursor pointer
            position absolute
            left 54px
            bottom 0
            &:hover
                border 1px solid $color
                background $color
                color #ffffff
    .slick-current
        border-left none
.index-about
    padding 80px 0
    position relative
    .video
        width 1041px
        height 532px
        position relative
        img
            width 100%
            height 100%
    .video-begin
        width 99px
        height 99px
        cursor pointer
        background url(../images/video-begin.png) no-repeat
        background-size contain
        position absolute
        z-index 9
        left 50%
        top 50%
        transform translate(-50%,-50%)
    .content
        width 779px
        height 418px
        position absolute
        top 50%
        left 1000px
        transform translateY(-50%)
        background #ffffff
        padding 53px 86px 0 86px
        transition 0.8s
        opacity 0
        &.animated
            left 48%
            opacity 1
        .title
            font-size 16px
            color #236a86
            text-transform uppercase
            em
                margin 0 10px
            a
                color #1f6bb8
        .texts li
                display none
                &.active
                    display inline-block
                .tit
                    font-size 50px
                    color #000000
                    margin-top 30px
                .txt
                    font-size 16px
                    line-height 20px
                    height 100px
                    color #414141
                h3
                    font-size 50px
                    color #000000
        .text-tab
            ul
                display flex
                font-size 16
                color #414141
                li
                    margin-right 40px
                    text-align center
                    h3
                        font-size 16px
                        color #414141
                .icon
                    margin-bottom 10px
                    width 65px
                    height 55px
                    display inline-block
                .icon1
                    background-image url(../images/indexabout-icon1.png)
                .icon2
                    background-image url(../images/indexabout-icon2.png)
                .icon3
                    background-image url(../images/indexabout-icon3.png)
                .icon4
                    background-image url(../images/indexabout-icon4.png)
                li:hover,li.active
                    cursor pointer
                    h3
                        color $color
                    .icon1
                        background-image url(../images/about-icon1-1.png)
                    .icon2
                        background-image url(../images/about-icon2-1.png)
                    .icon3
                        background-image url(../images/about-icon3-1.png)
                    .icon4
                        background-image url(../images/about-icon4-1.png)

.footer
    .footer-links
        padding 45px 88px
        background url('../images/foot-bg.jpg') #fff no-repeat bottom center
        display flex
    .item
        flex 1
        .tit
            font-size 22px
            font-weight bold
            color #000000
            position relative
            padding-bottom 10px
            margin-bottom 10px
            &:before
                width 60px
                height 2px
                content ""
                display inline-block
                background $color
                position absolute
                left 0
                bottom 0
        li
            line-height 22px
            margin-bottom 8px
        a,p
            font-size 16px
            color #3f3f3f
        a:hover
            color $color

    .last
        flex 0 0 40%
        display flex
        justify-content space-between
        border-left 1px solid #999
        padding-left 2%
        .item
            flex 0 0 218px
        .qrcode ul
            display flex
            text-align center
            li
                margin-left 30px
            img
                width 160px
                height 160px
            h3
                font-size 16px
                color #3f3f3f
                margin-top 20px
    .foot-copyright
        padding 0 90px
        background #282828
        height 74px
        display flex
        justify-content space-between
        align-items center
        font-size 16px
        color #aaaaaa
        a
            color #ffffff
        .btn
            width 150px
            height 33px
            display inline-block
            background-position center
            background-repeat no-repeat
            background-color #ffffff
            border-radius 5px
            margin-left 15px
        .right-btn
            display flex
            align-items center
        .google
            background-image url(../images/google.png)
        .appStore
            background-image url(../images/appStore.png)
.aside-right
    position fixed
    top 45%
    right 0
    width 44px
    z-index 995
    background #000000
    transform translateY(-50%)
    .sub
        position absolute
        visibility hidden
        transform translateX(-120%)
        box-shadow 0 0 20px rgba($color, 0.1)
        transition 0.4s
        left 0
        opacity 0
        top 0
    .subList
        top 0
        padding-left 27px
        padding-bottom 30px
        transform translateX(-110%)
        .head
            padding-top 15px
            line-height 50px
            font-size 18px
            color #494949
            border-bottom 1px solid #dfdfdf
            span
                color #8a8a8a
                margin-left 10px
        .body
            .img
                margin-right 15px
                flex 0 0 94px
                img
                    width 94px
                    height 60px
            .text
                flex 0 0 110px
                h3
                    font-size 14px
                    line-height 16px
                    height 44px
                    toe(2)
                    a
                        color #414141
                    a:hover
                        color $color

                h4
                    font-size 12px
                    color #8a8a8a
                    position relative
                    .icon
                        position absolute
                        top -3px
                        right 0
                        width 18px
                        height 18px
                        cursor pointer
                        background-image url(../images/icon-del.png)
                    .icon:hover
                        background-size contain
                        background-image url(../images/icon-del2.png)
        ul
            height 275px
            padding-top 28px
            overflow-y auto
        li
            line-height normal
            display flex
            margin-bottom 23px
            a
                display flex
        .btn
            width 204px
            height 40px
            background $color
            color #ffffff
            display inline-block
            text-align center
            line-height 40px
            border-radius 3px
            font-size 14px
    >ul>li
        height 50px
        line-height 50px
        position relative
        >a
            display block
            height 50px
    .icon1
        background-image url(../images/aside-icon1.png)
    .icon2
        background-image url(../images/aside-icon2.png)
    .icon3
        background-image url(../images/aside-icon3.png)
        position relative
    .icon4
        background-image url(../images/aside-icon4.png)
    .icon5
        background-image url(../images/aside-icon5.png)
    .icon6
        background-image url(../images/aside-icon7.png)
    .icon7
        background-image url(../images/aside-icon6.png)
    .subList
        position absolute
        right 44px
        width 267px
        background #ffffff
    li.is-new
        .icon3
            position relative
        .icon3:before
            content ''
            right 8px
            top 8px
            border-radius 100%
            display inline-block
            width 8px
            height 8px
            background red
            position absolute
    li:hover,li.active
        background #ffffff
        .sub
            opacity 1
            visibility visible
            transform translateX(-100%)
        .icon1
            background-image url(../images/aside-icon1-1.png)
        .icon2
            background-image url(../images/aside-icon2-1.png)
        .icon3
            background-image url(../images/aside-icon3-1.png)
        .icon4
            background-image url(../images/aside-icon4-1.png)
        .icon5
            background-image url(../images/aside-icon5-1.png)
        .icon6
            background-image url(../images/aside-icon7-1.png)
        .icon7
            background-image url(../images/aside-icon6-1.png)
    .attention
        width 117px
        height 50px
        background #ffffff
        line-height 50px
        display inline-block
        font-size 16px
        color #414141
        position absolute
        text-align center
    .wx
        width 105px
        height 105px
        background #ffffff
        padding 10px
    .subscriptions
        width 267px
        height 202px
        background #ffffff
        padding 27px
        .hd
            font-size 18px
            color #494949
            padding-bottom 10px
            border-bottom 1px solid #dfdfdf
            margin-bottom 16px
            line-height 1.2
        .bd
            input
                width 100%
                line-height 40px
                height 40px
                display block
                font-size 14px
                border-radius: 3px
            input.text
                border 1px solid #dfdfdf
                color #999999
                padding 0 10px
            .submit
                background #1f6bb8
                color #ffffff
                margin-top 10px
                cursor pointer
                &:hover
                    opacity 0.9
.md-content
    background #ffffff
    padding 80px 0
    min-height 600px
    .gb-title
        display flex
        justify-content space-between
        align-items center
        margin-bottom 28px
        .icon-home
            width 16px
            height 13px
            background-image url(../images/home-icon1.png)
            margin-right 5px
            top 2px
        a
            color #5a5a5a
            margin 0 5px
            font-size 14px
            &:first-child
                margin-left 0
            &:hover
                color $color

        &.line
            border-bottom 1px solid #919191
            padding-bottom 15px
        h3
            font-size 30px
            color #000000
        .num
            font-size 16px
            color #666666

// 联系我们
.md-contact
    background #ffffff
    padding-top 85px
    .wrap
        padding-top 70px
    .map-box
        height 630px
        background-position center
        background-repeat no-repeat
        background-size cover
    .text
        font-size 16px
        line-height 1.8
        color #666666
        margin-bottom 65px
    .list
        @extend .clearfix
        .icon
            width 55px
            height 55px
            border 1px solid #cccccc
            border-radius 50%
            position absolute
            left 0
        .item
            width 25%
            float left
            position relative
            padding-left 70px
            min-height 55px
            display flex
            align-items center
            margin-bottom 30px
            h3
                font-size 20px
                color #333333
        .item:last-child
            width auto
    .social-platform
        margin-top 20px
        dl
            display flex
            font-size 16px
            color #666666
            align-items center
            dt
                margin-right 5px
        a
            width 55px
            height 55px
            display inline-block
            margin 0 5px
            border-radius 50%
            background-color #a7b4c0
            transition background-color 0.3s
            @extend .icon
            &.icon-youtb
                background-image url(../images/ic-youtube.png)
            &.icon-in
                background-image url(../images/icon-in.png)
            &.icon-facebook
                background-image url(../images/icon-facebook.png)
            &.icon-twitter
                background-image url(../images/icon-twitter.png)
            &:hover
                background-color $color
    .service-form
        margin-top 40px
.md-careers
    ul
        display flex
        flex-wrap wrap
    li
        height 410px
        flex 0 0 33.333%
        border 1px solid #e0e0e0
        margin-bottom 49px
        padding 35px
        margin-left -1px
        transition box-shadow 0.2s
        &:hover
            background #1f6bb8
            box-shadow: 0px 0px 20px 0px rgba(31, 107, 184, 0.3);
            .title,.text,.tags,.btn
                color #ffffff
            .tags
                .icon1
                    background-image url(../images/careers-icon1-1.png)
                .icon2
                    background-image url(../images/careers-icon2-1.png)
        a
            display block
    .title
        font-size 30px
        color #000000
        margin-bottom 15px
    .tags
        font-size 16px
        color #999999
        padding-bottom 30px
        border-bottom 1px solid #e0e0e0
        margin-bottom 25px
        .icon
            width 25px
            height 22px
            top 4px
            margin-right 3px
        .icon1
            background-image url(../images/careers-icon1.png)
        .icon2
            margin-left 20px
            background-image url(../images/careers-icon2.png)
    .text
        font-size 16px
        line-height 1.8
        color #666666
        height 175px
        toe(5)
    .btn
        font-size 18px
        color #1f6bb8
    &-post
        .head
            display flex
            justify-content space-between
            .btn
                width 300px
                height 60px
                line-height 60px
                background $color
                transition 0.2s
                text-align center
                font-size 18px
                color #ffffff
                cursor pointer
                &:hover
                    background $color +10%
            .title
                @extend .md-careers .title
            .tags
                @extend .md-careers .tags
                padding-bottom 0
                margin-bottom 0
                border-bottom none
        .body
            padding-top 30px
            padding-bottom 105px
            font-size 16px
            line-height 1.8
            color #828282

.gb-head-menu
    border-bottom 1px solid #b3b3b3
    .line
        width 11px
        height 26px
        background url(../images/line-icon.png) no-repeat center
        display inline-block
        position relative
        top 2px
        margin 0 25px
    a
        color #000000
        font-size 30px
        position relative
        padding-bottom 14px
        display inline-block
        &:first-child
            padding-left 0
        &.active,&:hover
            color #1f6bb8
            &:after
                content: ''
                display inline-block
                height 1px
                background $color
                left 0
                right 0
                bottom -1px
                position absolute

.fixed-wx
    background #ffffff
    width 150px
    height 150px
    padding 15px
    position fixed
    z-index 999
    top 50%
    left 50%
    margin-top -75px
    margin-left -75px
    img
        width 100%
        height 100%
.md-team

    .body
        padding 40px 0
        ul
            width 108%
            display flex
            flex-wrap wrap
            position relative
            .img
                position relative
                .qrcode
                    display none
                    position absolute
                    left 0
                    right 0
                    bottom 0
                    top 0
                    background rgba(#000,0.8)
                    display flex
                    align-items center
                    justify-content center
                    z-index 99
                    .close
                        position absolute
                        width 10px
                        height 10px
                        top 10px
                        right 10px
                        background url(../images/login-close.png)
                        z-index 999
                        background-repeat no-repeat
                    img
                        max-width 80%
                        height auto
                .icon
                    padding-right 0
                .icon-phone,.icon-wx
                    position absolute
                    bottom 10px
                    left 10px
                    width 24px
                    height 24px
                    display inline-block
                    background-size contain
                    background-image url(../images/team-img1.png)
                .icon-wx
                    width 30px
                    height 30px
                    left 45px
                    bottom 7px
                    background-image url(../images/team-img2.png)
            li
                flex 0 0 252px
                margin-right 50px
                margin-bottom 50px
                position relative
                &.is-best
                    &:before
                        width 90px
                        height 90px
                        display inline-block
                        background-image url(../images/best.png)
                        content ''
                        right 0
                        top 0
                        position absolute
                        z-index 9
                h3
                    font-size 28px
                    margin-top 25px
                    margin-bottom 10px
                    a
                        color #222222
                        &:hover
                            color $color
                h4
                    font-size 16px
                    color #838383
                .tags
                    margin-top 5px
                .icon
                    font-size 16px
                    color #838383
                    background-repeat no-repeat
                    background-position left center
                    padding-left 20px
                .icon-cn
                    background-size 16px auto
                    background-image url(../images/cn.png)
                .icon-usa
                    margin-left 10px
                    background-size 16px auto
                    background-image url(../images/us.png)
        .img
            width 100%
            height 338px
            overflow hidden
    .gb-pager
        padding-top 40px

.md-team-details
    height auto
    overflow hidden
    .item
        width 252px
        position relative
        @extend .md-team .body ul li
        .img
            @extend .md-team .body ul .img
        .contact
            margin-top 12px
            font-size 16px
            color #606060
            line-height 32px
    .right-con
        margin-left 358px
        .title
            font-size 28px
            margin-bottom 15px
            color #222222
        .text
            font-size 16px
            line-height 24px
            margin-bottom 40px
            color #838383
    .form
        .submit
            margin-top 20px
            border-radius 3px
            width 300px
            height 60px
            line-height 60px
            background $color
            transition 0.2s
            text-align center
            font-size 18px
            color #ffffff
            cursor pointer
            &:hover
                background $color +10%
        li
            margin-bottom 15px
            display flex
            .input
                flex 1
                padding 0 15px
                font-size 16px
                transition 0.3s
                &:focus
                    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
                    border-color #000
            textarea.input
                height 250px
            select.input
                flex 0 0 120px
                border-radius 3px 0 0 3px !important
            &.select
                height 50px
                line-height 48px
                border 1px solid $lineColor
                border-radius 3px
                .input
                    border none
                    line-height 48px
                    height 48px
                    &:focus
                        box-shadow none
                input.input
                    border-left 1px solid $lineColor
                    border-radius 0
        .row
            justify-content space-between
            .input
                flex 0 0 49%
        .input
            height 50px
            line-height 48px
            border 1px solid $lineColor
            border-radius 3px
    .left-con
        float left
        .contact
            .icon
                padding-left 0
                width 25px
                height 20px
                top 5px
            .icon-phone
                background-image url(../images/team-phone.png)
            .icon-tel
                background-image url(../images/team-tell.png)
            .icon-mail
                background-image url(../images/team-mail.png)

.gb-meun
    padding-top 10px
    display flex
    justify-content space-between
    .right-sear
        width 515px
        border-width: 1px;
        border-color: rgb(169, 169, 169);
        border-style: solid;
        border-radius: 3px;
        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
        height: 46px;
        border 1px solid #000
        position relative
        top -5px
        .select-list
              position absolute
              left 120px
              right 0
              top 100%
              margin-top 1px
              padding 10px 0
              background #ffffff
              overflow-y auto
              font-size 14px
              transition 0.2s
              z-index 99
              max-height 200px
              box-shadow 0 0 10px rgba(#000,0.1)
              li
                    line-height 37px
                    color #5a5a5a
                    padding 0 23px
                    @extend .toe
                    display block
                    cursor pointer
                    &.active,&:hover
                        background $color
                        color #ffffff
        form
            display flex
            align-items center
            height 46px
        .submit
            flex: 0 0 60px;
            cursor: pointer;
            background-image: url(../images/sear-btn.png);
            background-repeat: no-repeat;
            background-position: center;
            position relative
            top -2px
        .select
            border-right 1px solid #000
            border-radius 0
            margin-left 10px
            select
                width 114px
                background #fff
                font-size 16px
                color #494949
                height 35px
        input
            height 35px
            line-height 35px
            flex 1
            font-size 14px
            color #494949
            padding 0 20px
    .right-con
        display flex
        align-content center
        justify-content center
        .right-sear
            width 465px
            overflow hidden
        .select-yaer
            height 44px
            display flex
            margin-left 10px
            align-content center
            position relative
            .select-arr
                position absolute
                width 15px
                height 9px
                top 17px
                right 10px
                background-repeat no-repeat
                background-image url(../images/select-arr.png)
            select
                flex 0 0 120%
                font-size 20px
                color #000
        .goToYears
            margin-left 20px
            width 180px
            height 48px
            font-size 18px
            color #ffffff
            line-height 48px
            text-align center
            background $color
            position relative
            top -5px
            &:hover
                background $color +10%
                cursor pointer
.clearfix {
    display: block;
    zoom: 1
}
.clearfix:after{
    content: "";
    display: block;
    font-size: 0;
    height: 0;
    clear: both;
    visibility: hidden
}
.md-reference
    .body
        @extend .clearfix;
    &-list
        .sort
            border-bottom 1px solid #eeeeee
            margin-top 25px
            ul
                @extend .clearfix
                li
                    float left
                    line-height 50px
                    font-size 14px
                    margin-right 32px
                    position relative
                    a
                        color #5a5a5a
                        display block
            .icon-vr
                width 24px
                height 15px
                display inline-block
                top 3px
                margin-left 6px
                background-image url(../images/icon-vr2.png)
            .icon-arr
                width 7px
                height 10px
                display inline-block
                top 1px
                margin-left 6px
                background-image url(../images/icon-sort-arr.png)

            li.active,li:hover
                a
                    color $color
                    &:before
                        content ''
                        display inline-block
                        height 1px
                        background $color
                        position absolute
                        bottom -1px
                        left 0
                        right 0
                .icon-arr
                    background-image url(../images/icon-sort-arr-1.png)
                .icon-vr
                    background-image url(../images/icon-vr2-1.png)
        .select-filter
            ul
                display flex
                padding 30px
            li
                flex 1
                margin 0 15px
                select
                    padding 0 10px
                    font-size 18px
                    width 100%
                    height 50px
                    border 1px solid #eeeeee
                    border-radius 3px
                    color #000000
            .search-btn
                width 180px
                display block
                background $color
                color #ffffff
                line-height 50px
                font-size 18px
                border-radius 3px
                cursor pointer
                text-align center
                margin 0 auto
                &:hover
                    background $color +10%
        .filter
            .filter-items
                position relative
                padding-left 140px
                min-height: 45px
                max-height 76px
                overflow hidden
                border-bottom 1px solid #e4e4e4
            .filter-type
                line-height 45px
                background #f8f6f7
                width 140px
                position absolute
                left 0
                top 0
                bottom 0
                font-size 14px
                color #000000
                padding 0 15px
                font-weight bold
            .filter-item
                position relative
                padding 10px 25px
                font-size 14px
                padding-right 100px
                .input
                    display inline-block
                .btn
                    color #5a5a5a
                    font-size 14px
                    width 75px
                    height 26px
                    line-height 24px
                    padding 0
                    border 1px solid #d1d1d1
                    text-align center
                    display inline-block
                    position relative
                    top 2px
                    margin-left 10px
                    background-repeat no-repeat
                    background-image url(../images/icon-confirm.png)
                    background-position  95% 55%
                    &:hover
                        background-image url(../images/icon-confirm2.png)
                        border 1px solid $color
                .more
                    width 67px
                    height 26px
                    border 1px solid #5a5a5a
                    font-size 14px
                    color #5a5a5a
                    line-height 24px
                    padding-left 12px
                    position absolute
                    padding 0
                    right 0
                    top 5px
                    .icon-arrdown
                        top -1px
                        margin-left 5px
                        width 7px
                        height 4px
                        background-image url(../images/arr-down4.png)
                .to
                    font-size 16px
                    color #000000
                    padding 0 5px
                .text
                    width 144px
                    height 26px
                    border 1px solid #d1d1d1
                    border-radius 3px
                    padding 0 5px
                    font-size 12px
                a
                    color #5a5a5a
                    margin-right 20px
                    padding 5px
                    line-height 1.4
                    display inline-block
                    &:hover
                        color $color
                    &.active
                        background $color
                        border-radius 3px
                        color #ffffff
        .filter-selected
            margin-top 20px
            a
                height 25px
                border 1px solid #dfdfdf
                padding 0 10px
                line-height 23px
                font-size 12px
                color #5a5a5a
                display inline-block
                margin-right 5px
                .icon-del
                    width 7px
                    height 7px
                    background-image url(../images/icon-del4.png)
                    margin-left 10px
        .body
            margin-top 30px
    .item
        width 32%
        float left
        margin-right 2%
        margin-bottom 2%
        border 1px solid #f2f2f2
        &:nth-child(3n)
            margin-right 0
        @extend .index-list .body .item
    .sear-box
        display flex
        border 1px solid #000
        margin 30px 0
        border-width: 1px;
        border-color: rgb(223, 223, 223);
        border-style: solid;
        border-radius: 3px;
        background-color: rgb(255, 255, 255);
        height: 46px;
        input.text
            flex 1
            font-size 16px
            color #999999
            padding 0 15px
        input.submit
            flex 0 0 60px
            cursor pointer
            background-image url(../images/sear-btn.png)
            background-repeat no-repeat
            background-position center

.error-modal,.success-modal
    width 360px
    height 79px
    background #eef6f9
    align-items center
    overflow hidden
    display none
    position fixed
    top 10%
    left 50%
    transform translateX(-50%)
    z-index 9999
    .close
        width 22px
        height 22px
        display inline-block
        background-image url(../images/icon-close-5.png)
        background-repeat no-repeat
        background-color #000000
        background-position center
        cursor pointer
        position absolute
        right 0
        top 0
        z-index 9
    .icon-err
        width 80px
        height 79px
        float left
        background-image url(../images/icon-error2.png)
        background-position center
        background-repeat no-repeat
    .icon-success
        width 80px
        height 79px
        float left
        background-image url('../images/icon-success2.png')
        background-position center
        background-repeat no-repeat
        background-size 40px 40px
    .text
        float left
        padding-top 15px
        width 250px
        font-size 14px
        line-height 1.6
        color #000000
        a
            color #d72638
.success-modal
    .text
        a
            color green
.gb-banner
    height 310px
    position relative
    background-size cover
    background-position center
    .publish
        .icon-publish
                background-image url(../images/icon-home1.png)
                width 17px
                height 16px
                position relative
                top -1px
                margin-right 10px
        display inline-block
        position absolute
        right 10px
        bottom -40px
        width 241px
        height 30px
        background #424447
        border-radius: 15px
        color #ffffff
        display flex
        justify-content center
        align-items center
        font-size 18px
        &:hover
            background $color
    .icon-home
        width 16px
        height 13px
        background-image url(../images/home-icon1.png)
        margin-right 5px
        top 2px
    .nav
        background #ffffff
        height 72px
        width 1620px
        left 50%
        bottom 0
        transform translateX(-50%)
        position absolute
        font-size 14px
        align-items center
        display flex
        padding 0 80px
        color #5a5a5a
        justify-content space-between
        .right-btn
            .icon
                wd(18,18)
                top 4px
                margin-right 5px
            .icon-heart
                bg(house-heart,png)
            .icon-add
                bg(house-add,png)
            a
                width 140px
                height 32px
                border 1px solid #cacaca
                display inline-block
                text-align center
                line-height 30px
                font-size 14px
                color #000000
                border-radius 3px
                &.active,&:hover
                    border 1px solid transparent
                    background $color
                    color #ffffff
                    .icon-heart
                        bg(house-heart-1,png)
                    .icon-add
                        bg(house-add-1,png)

        // @extend .clearfix
        .right-con
          .checkbox
              color #000000
              font-size 14px
              height 30px
              line-height 30px
              display inline-block
              .icon
                width 60px
                height 30px
                top 12px
                margin-left 10px
                display inline-block
                background-image url(../images/icon-open.png)
              &.active
                .icon
                    background-image url(../images/icon-open1.png)
        //   float right
        a
            color #5a5a5a
            margin 0 10px
            &:first-child
                margin-left 0
            &:hover
                color $color
        .back
            font-size 20px
            color #333333
            position absolute
            left 0
            top 0
            width 73px
            height 73px
            display inline-block
            transition 0.2s
            background-image url(../images/icon-big-back.png)
            &:hover
                background-image url(../images/icon-big-back2.png)

    .title
        font-size 50px
        color #ffffff
        position absolute
        width 1460px
        left 50%
        transform translateX(-50%)
        bottom 124px
        z-index 99
        span
            position relative
            padding-bottom 30px
            font-weight bold
            text-transform uppercase
            &:before
                content: ''
                height 5px
                background #ffffff
                display inline-block
                left 0
                right 0
                bottom 0
                width 100px
                position absolute

.md-service
    .title
        font-size 30px
        color #000000
        margin-bottom 100px
    li
        height auto
        margin-bottom 128px
        overflow hidden
        position relative
        >div
            position relative
            z-index 9
        .texts
            width 634px
            float left
            padding-top 140px
            padding-right 20px
            .tit
                font-size 68px
                color #000000
                line-height 1.1
                margin-bottom 10px
            .txt
                font-size 16px
                color #666666
                line-height 1.6
                height 90px

        &:nth-child(2n)
            .img
                float left
                margin-left 0
                img
                    width 734px
            .texts
                width auto
                float none
                padding-left 90px
                margin-left 734px
                padding-right 0
                padding-top 60px
            &:before
                content ''
                display inline-block
                position absolute
                left 0
                height 550px
                background #fafafa
                right -1000px
        .img
            margin-left 634px
        .imgs
            display flex
            .item
                flex 1
                margin-right 20px
                img
                    width 100%
                    height 240px
            .item:last-child
                margin-right 0
.service-form
    margin-top 80px
    .form-title
        font-size 50px
        color #000000
        text-align center
    .form-wrap
        ul
            display flex
            justify-content space-between
            flex-wrap wrap
            li
                display flex
                flex 0 0 32%
                height 50px
                border 1px solid $lineColor
                line-height 48px
                margin-top 20px
                .tit
                    flex 0 0 110px
                    border-right 1px solid $lineColor
                    font-size 16px
                    color #999999
                    padding 0 10px
                textarea.input
                    padding 10px
                    font-size 16px
                .input
                    flex 1
                    display flex
                    input
                        padding 0 10px
                        flex 1
                        font-size 16px
            select
                font-size 16px
                color #999999
            li.row
                flex 1
                height 250px
        .submit
            @extend .md-team-details .form .submit
            display block
            margin 0 auto
            margin-top 60px

.md-developers
    ul
        display flex
        flex-wrap wrap
    li
        height 235px
        flex 0 0 33.3333%
        display flex
        align-items center
        justify-content center
        position relative
        transition 0.4s
        &:hover
            box-shadow 0 3px 15px rgba(0,0,0,0.5)
            z-index 9
            .hide
                opacity 1
                visibility visible
        &:nth-child(2n)
            background #f9f9f9
        .black-pic
            img
                width 100px
                height 100px
        .hide
            position absolute
            top 0
            bottom 0
            left 0
            right 0
            background #ffffff
            padding 30px
            padding-bottom 0
            opacity 0
            visibility hidden
            transition 0.3s
            .color-pic
                flex 0 0 100px
                height 100px
                margin-right 16px
                position relative
                top 6px
                img{
                    width 100%
                    height 100%
                }
            .more
                font-size 16px
                text-align center
                position absolute
                bottom 20px
                left 0
                right 0
                a
                    color #000000
                    &:hover
                        color $color
                        text-decoration underline
            .text
                display flex
            .txt
                font-size 16px
                line-height 1.6
                color #666666
        // .text
        //     display none
        // .more
        //     display none

.gb-pager
    display flex
    justify-content center
    padding 80px 0
    a
        display inline-block
        width 48px
        height 48px
        line-height 48px
        text-align center
        font-size 18px
        color #000000
        border 1px solid $lineColor
        margin 0 3px
        &:hover,&.active
            border 1px solid $color
            background $color
            color #ffffff
    span
        display inline-block
        width 48px
        text-align center
        line-height 48px
        font-size 18px
        color #000000
    .page-btn
        width 126px


.md-news-post
    .head
        padding-bottom 40px
        border-bottom 1px solid #e0e0e0
        position relative
        .title
           font-size 50px
           color #000000
           line-height 1.2
        .tags
            color #666666
            font-size 14px
            display flex
            align-items center
            position absolute
            right 0
            bottom 0
            .line
                margin 0 20px
                color #e5e5e5
            .icon
                width 25px
                height 25px
                background-repeat no-repeat
                background-position center
                padding 0
            .icon1
                background-image url(../images/news-icon1.png)
            .icon2
                background-image url(../images/news-icon2.png)
            .icon3
                background-image url(../images/news-icon3.png)
            .icon4
                background-image url(../images/news-icon4.png)
            .icon5
                background-image url(../images/news-icon5.png)
    .body
        padding 70px 0
        font-size 16px
        line-height 1.8
        color #414141
        height auto
        overflow hidden
        .left-text
            width 1050px
            float left
        .right-list
            margin-left 1150px
            li
                padding 37px 0
                border-bottom 1px solid $lineColor
                &:first-child
                   padding-top 0
                .date
                    font-size 14px
                    color #666666
                    margin-bottom 10px
                .title a
                    font-size 24px
                    line-height 1.4
                    color #000000
                    &:hover
                        color $color

// 新闻
.md-news
    li
        height auto
        padding 50px 0
        border-bottom 1px solid #e4e4ea
        .img
            width 400px
            height 225px
            float left
            a
                display block
            img
                width 100%
                height 100%
        .text
            margin-left 437px
            h3
                @extend .toe
        .date
            font-size 14px
            color #666666
            margin-bottom 10px
        .title
            font-size 30px
            color #000000
            position relative
            padding-bottom 20px
            margin-bottom 10px
            &:before
                width 60px
                height 2px
                background $color
                content ''
                display inline-block
                position absolute
                bottom 0
                left 0
        .txt
            font-size 14px
            color #414141
            line-height 1.6
            height 65px
            margin-bottom 15px
            toe(2)
        .btn
            border-width: 1px;
            border-color: rgb(51, 51, 51);
            border-style: solid;
            border-radius: 3px;
            box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
            width: 147px;
            height: 46px;
            text-align center
            line-height 44px
            font-size 14px
            color #414141
            transition 0.3s
        .title:hover
            color $color
        .btn:hover
            color #ffffff
            background $color
            border 1px solid $color
.md-honer
    .item
        display flex
        >div
            flex 0 0 50%
            height 540px
        .img
            overflow hidden
            img
                width 100%
                height 100%
        .text
            background:rgba(249,249,249,1);
            padding 100px 60px
            .title
                font-size:50px;
                color:rgba(51,51,51,1);
                line-height:1.2;
                margin-bottom 40px
            .txt
                font-size:16px;
                font-weight:400;
                color:rgba(102,102,102,1);
                line-height:30px;
.mt30
    margin-top 30px
.md-dubai
    .sear-box
        @extend .md-reference .sear-box
        align-items center
        border 1px solid #000
        display flex
        width 465px
        margin 0
        input.submit
            background-image url(../images/icon-sear-btn.png)
        input.submit,
        input.text
            height 40px
            color #000000
        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
    .body
        @extend .clearfix
        .item
            background-color: rgb(255, 255, 255);
            box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.08);
            height: 413px;
            width 31.3%
            margin-right 2%
            float left
            margin-bottom 2%
            &:nth-child(3n)
                margin-right 0
            .img
                height 290px
                img
                    height 290px
                    width 100%
            .text
                height 123px
                &>div
                    display flex
                    justify-content center
                    flex-direction: column
                    height 123px
                &-left
                    width 120px
                    align-items center
                    float left
                    border-right 1px solid #f2f2f2
                    h3
                        font-size 16px
                        color #828282
                    h4
                        font-weight bold
                        font-size 40px
                        color #000000
                        font-weight bold
                &-right
                    margin-left 144px
                    h3
                        font-size 30px
                        a
                            color #414141
                            @extend .toe
                            &:hover
                                color $color
                    p
                        font-size 16px
                        color #828282
                        @extend .toe
                        background url(../images/location.png) left center no-repeat
                        padding-left 18px
.md-project
    .item
        box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.08);
        height 536px
        .text
            overflow hidden
            .img
                width:63px
                height 63px
                float left
                img
                    width 100%
                    height 100%
            .title
                margin-left 80px
        .date
            font-size 16px
            color #828282
            padding 0 25px
            margin-bottom 15px
        .btns
            display flex
            align-items center
            justify-content space-between
            padding 0 25px
            a
                width 195px
                height 34px
                line-height 32px
                text-align center
                border-radius: 3px
                border 1px solid #ababab
                color #171727
                cursor pointer
                display inline-block
                transition background 0.3s
                &:hover
                    background $color
                    border 1px solid $color
                    color #ffffff
        &:before
            width 90px
            height 90px
            display inline-block
            background-color transparent
        &.item-luxury
            &:before
                background-image url(../images/item-luxury.png)
        &.item-new
            &:before
                background-image url(../images/item-news.png)
    .post-text
        font-size 14px
        line-height 1.6
        color #525252
        margin-bottom 40px

    .map-box
        margin-bottom 50px
        img
            width 100%
            height 430px
    .back-nav
        margin-bottom 20px
        .back
            font-size 20px
            color #333333
            .icon-back
                width 13px
                height 24px
                display inline-block
                top 5px
                margin-right 10px
                background-image url(../images/icon-back.png)
            &:hover
                color $color
                .icon-back
                   background-image url(../images/icon-back2.png)

.md-padding
    padding-top 20px
.md-emaar
    .gb-title.line
        margin-bottom 0
    .emaar-text
        background #f7f7f7
        padding 40px 60px
        font-size 16px
        line-height 26px
        color #666666
        margin-bottom 60px
        border-bottom 1px solid #eeeeee
        .img
            width 95px
            height 130px
            margin-left 38px
            float left
            padding-top 17px
            padding-bottom 17px
            img
                width 100%
                height 100%
        .txt
            padding-top 17px
            padding-bottom 17px
            margin-left 230px
            border-left 1px solid #eeeeee
            padding-left 58px
.detail-banner
    height 675px
    background-repeat no-repeat
    background-size cover
    background-position center
    color #ffffff
    display flex
    align-items center
    justify-content center
    flex-direction: column
    position relative
    overflow hidden
    .nav
        z-index 9
    .swiper-slide
        display flex
        align-items center
        justify-content center
        flex-direction: column
    .text
        text-align center
        color #ffffff
        width 950px
        margin 0 auto
        h3
            font-size 50px
        p
            font-size 16px
            line-height 26px
        .btn
            width 150px
            height 46px
            background #ffffff
            color #000000
            text-align centr
            line-height 46px
            display inline-block
            border-radius: 5px
            font-size 16px
            margin-top 30px
            &:hover
                background $color
                color #ffffff
    &.gb-banner
        .swiper-button-prev,.swiper-button-next
            bottom 80px
    .swiper-button-prev,.swiper-button-next
        width 46px
        height 46px
        background-size contain
        position absolute
        bottom 20px
        left 50%
        right auto
        top auto
        opacity 1
    .swiper-button-prev
        background-image url(../images/left-arrow-4.png)
        transition 0.3s
        transform translateX(-60px)
        &:hover
            background-image url(../images/left-arrow-1.png)
    .swiper-button-next
        background-image url(../images/right-arrow-4.png)
        transition 0.3s
        transform translateX(0px)
        &:hover
            background-image url(../images/right-arrow-1.png)
    .wrap
        display flex
        align-items center
        justify-content center
        flex-direction: column
    .data
        width 680px
        background rgba(#000,0.2)
        ul
            display flex
            li
                height 200px
                flex 1
                display flex
                flex-direction column
                align-items center
                justify-content center
                &:last-child
                    border-left 1px solid rgba(#fff,0.1)
            h3
                font-size 80px
            h4
                font-size 20px
    .txt
        font-size 16px
        line-height 26px
        text-align center
        margin-top 30px

.md-guide
    .title
        font-size 34px
        color #333333
        margin 90px 0
    .list
        ul
            position relative
            &:before
                position absolute
                content: ''
                display inline-block
                width 1px
                top 0
                bottom 0
                left 55px
                background #cccccc
        li
            padding-left 142px
            position relative
            min-height 115px
            padding-top 26px
            .icon
                width 113px
                height 113px
                border-radius 50%
                border 1px solid #cccccc
                background-color #fff
                position absolute
                left 0
                top 26px
            .icon1
                background-image url(../images/guide-icon1.png)
            .icon2
                background-image url(../images/guide-icon2.png)
            .icon3
                background-image url(../images/guide-icon3.png)
            .icon4
                background-image url(../images/guide-icon4.png)
            .icon5
                background-image url(../images/guide-icon5.png)
            .icon6
                background-image url(../images/guide-icon6.png)
            .icon7
                background-image url(../images/guide-icon7.png)
            &:last-child
                .text
                    border-bottom none
                    padding-bottom 0
            &:first-child
                padding-top 0
                .icon
                    top 0
        .text
            padding-top 32px
            padding-bottom 60px
            border-bottom 1px solid #f0f0f0
            h3
                font-size 30px
                color #000000
                margin-bottom 10px
            p
                font-size 16px
                line-height 1.8
                color #999999

.md-about
    .texts
        padding-top 100px
    .title
        font-size 50px
        color #333333
        margin-bottom 20px
    .txt
        font-size 16px
        color #666666
        line-height 1.8
    .ceo-msg
        display flex
        background #f9f9f9
        overflow hidden
        margin-top 80px
        >div
            height 500px
            flex 0 0 50%
        .img
            position relative
            &:hover
                 .logo:after
                     transform scale(1.02)
            img
                width 100%
                height 100%
        .logo
            width 650px
            height 340px
            position absolute
            right 78px
            top 50%
            transform translateY(-50%)
            &:after,&:before
                content ''
                position absolute
                left 0
                right 0
                bottom 0
                top 0
                display inline-block
            &:after
                border 1px solid #fff
                transition 0.3s
            &:before
                background url(../images/about-logo.png) no-repeat center
        .text
            padding 80px
            // h3
            //     font-size 50px
            //     color #333333
            //     margin-bottom 50px
            // p
            //     font-size 16px
            //     color #666666
            //     line-height 1.8
    .our
        @extend .clearfix
        padding 80px 0
        .icon
            width 85px
            height 85px
            margin-bottom 15px
        .icon1
            background-image url(../images/about-icon1.png)
        .icon2
            background-image url(../images/about-icon2.png)
        .icon3
            background-image url(../images/about-icon3.png)
        .icon4
            background-image url(../images/about-icon4.png)
        .item
            width 48%
            float left
            margin-bottom 60px
            &:nth-child(2n+1)
                margin-right 4%
        // h3
        //     font-size 50px
        //     color #333333
        // p
        //     font-size 16px
        //     color #666666
        //     line-height 1.8
    .talent
        background #f9f9f9
        position relative
        .odd
            .img
                left 0
                right auto
            .text
                margin-left 50%
                padding-left 78px
        .item
            display flex
            .img
                position absolute
                right 0
            &>div
                width 50%
                height 540px
                img
                    width 100%
                    height 100%
            .text
                padding-top 75px
                padding-right 50px
                // h3
                //     margin-bottom 30px
                //     font-size 50px
                //     color #333333
                // p
                //     font-size 16px
                //     color #666666
                //     line-height 1.8
    .choose
        padding-top 100px
        .items
            display flex
            flex-wrap wrap
            margin-top 40px
        .item
            flex  0 0 33.333%
            display flex
            align-items center
            height 115px
            .txt
                margin-left 20px
                font-size 16px
                color #666666
                width 305px
        // .title
            // h3
            //     font-size 50px
            //     color #333333
            // p
            //     margin-top 38px
            //     line-height 1.8
            //     font-size 16px
            //     color #666666
        .icon
            width 55px
            height 55px


.md-ucenter
    margin-bottom 40px
    .bread-nav
        color #000000
        line-height 40px
        span
            margin 0 5px
        a
            font-size 14px
            color #000000
            &:hover
                color $color
    .left-con
            width 267px
            background #4a4c4e
            float left
    .right-con
        margin-left 267px
        padding 0 60px
        .pager
            text-align center
            margin-top 50px
            a
                width 32px
                height 32px
                border 1px solid #cbcbcb
                display inline-block
                line-height 32px
                text-align center
                color #000000
                margin 0 3px
                &:hover,&.active
                    border 1px solid #4a4c4e
                    background #4a4c4e
                    color #ffffff
            .page-btn
                width 55px
        &-publishlist
            li
                height 102px
                border-bottom 1px solid #f2f2f2
                padding-top 26px
                position relative
                padding-left 10px
                .btns
                    opacity 0
                    visibility hidden
                    position absolute
                    transition 0.2s
                    right 26px
                    top 50%
                    margin-top -17px
                    z-index 9
                    .icon
                        width 40px
                        height 35px
                        cursor pointer
                    .icon-edit
                        background-image url(../images/icon-edit3.png)
                    .icon-del
                        background-image url(../images/icon-del3.png)
                h3
                    margin-bottom 5px
                    a
                        font-size 18px
                        color #424447
                        &:hover
                            color $color
                .date
                    font-size 16px
                    color #686868
                &:hover
                    background #faf6f7
                    .btns
                        visibility visible
                        opacity 1
        &-submit2
            .body
                padding-top 40px
                .submit
                    margin-left 155px
                    margin-top 50px
                    input
                        width 275px
                        height 44px
                        background $color
                        font-size 22px
                        color #ffffff
                        border-radius: 3px
                        cursor pointer
                        &:hover
                            opacity 0.9
            .item
                display flex
                margin-bottom 18px
                align-items center
                .tit
                    font-size 18px
                    color #333333
                    width 155px
                .text
                    width 362px
                    height 48px
                    line-height 46px
                    border-radius 3px
                    border 1px solid #dbdbdb
                    padding 0 10px
                    transition 0.3s
                    font-size 14px
                    color #333333
                    &:focus
                        border-width: 1px;
                        border-color: rgb(43, 43, 43);
                        border-style: solid;
                        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
                .line
                    flex 1
                textarea.text
                    height 155px
        &-submit
            .body
                padding-top 40px
                .submit
                    margin-top 50px
                    input
                        width 275px
                        height 44px
                        background $color
                        font-size 22px
                        color #ffffff
                        border-radius: 3px
                        cursor pointer
                        &:hover
                            opacity 0.9
            .item
                margin-bottom 32px
                .input
                    border-radius 3px
                    border 1px solid #dbdbdb
                    height 48px
                    line-height 46px
                    font-size 16px
                    color #333333
                    position relative
                .btn
                    position absolute
                    right 0
                    bottom 0
                    top 0
                    width 90px
                    border-radius 3px
                    background #424447
                    color #ffffff
                    text-align center
                    cursor pointer
                    overflow hidden
                    input
                        position absolute
                        top 0
                        bottom 0
                        left 0
                        right 0
                        z-index 9
                        opacity 0
                        cursor pointer
                span
                    padding 0 10px
                h3
                    font-size 18px
                    color #333333
                    margin-bottom 10px
        &-reservation
            .body
                .title
                    margin-top 30px
                    font-size 22px
                    color #000000
                    margin-bottom 10px
                .title-small
                    font-size 14px
                    color #919191
                .row
                    margin-bottom 24px
                    display flex
                    .input
                        flex 0 0 320px
                        margin-right 25px
                    .text
                        padding 0 10px
                        font-size 16px
                        border-radius 3px
                        border 1px solid #dbdbdb
                        height 48px
                        width 100%
                        line-height 46px
                .row-text
                    .text
                        height 200px
                .row-submit
                    text-align center
                    margin-top 30px
                    .submit
                        width 275px
                        height 44px
                        background $color
                        border-radius 4px
                        font-size 22px
                        color #ffffff
                        cursor pointer
                        &:hover
                            opacity 0.9
                .date
                    margin-top 22px
                    display flex
                    border-radius 3px
                    width 615px
                    border 1px solid #dbdbdb
                    li
                        border-right 1px solid #dbdbdb
                        height 45px
                        line-height 45px
                        flex 1
                        padding-left 23px
                        padding-right 23px
                        background-image url(../images/icon-arrdwon3.png)
                        background-position 90% center
                        background-repeat no-repeat
                        position relative
                        cursor pointer
                        &:last-child
                            border-right none
                        span
                            font-size 20px
                            margin-right 8px
                            color #000000
                        em
                            font-size 14px
                            color #919191

                    .subnav
                        position absolute
                        left -1px
                        right -1px
                        max-height 200px
                        overflow-y auto
                        background #ffffff
                        border 1px solid #dbdbdb
                        top 45px
                        display none
                        a
                            font-size 20px
                            color #000000
                            padding-left 23px
                            display block
                            line-height 35px
                            &:hover,&.active
                                background #1f6ab7
                                color #ffffff
        &-browse
            .body
                .noData
                    text-align center
                    display flex
                    align-items center
                    justify-content center
                    min-height 500px
                    font-size 24px
                li
                    padding 20px 0
                    border-bottom 1px solid #dbdbdb
                    position relative
                    .reserved
                        position absolute
                        right 22px
                        top 50%
                        transform translateY(-50%)
                        line-height 22px
                        font-size 16px
                        color #4a4c4e
                    .icon-reserved
                        width 21px
                        height 21px
                        top 5px
                        margin-right 5px
                        background-image url(../images/icon-reserved.png)
                    .btn-del
                        right 30px
                        top 50%
                        transform translateY(-50%)
                        position absolute
                        opacity 0
                        visibility hidden
                        transition: 0.2s
                    .icon-del
                        width 22px
                        height 24px
                        background-image url(../images/icon-del5.png)
                    .btn
                        position absolute
                        width 142px
                        height 30px
                        background #424447
                        text-align center
                        color #ffffff
                        line-height 30px
                        display inline-block
                        right 35px
                        top 50%
                        margin-top -15px
                        border-radius: 3px
                        transition: 0.2s
                        visibility hidden
                        opacity 0
                    .btn2
                        right 75px
                    &:hover
                        background #faf6f7
                        .btn,.btn-del
                            cursor pointer
                            opacity 1
                            visibility visible
                       .text h3
                           color $color
                    .icon-del:hover
                        background-image url(../images/icon-del5-1.png)
                a
                    @extend .clearfix
                .img
                    float left
                    img
                        width 207px
                        height 130px
                .text
                    margin-left 235px
                    h3
                        font-size 20px
                        color #000000
                        margin-bottom 10px
                    h4
                        font-size 14px
                        margin-bottom 20px
                        color #828282
                    .price
                        margin-bottom 15px
                        font-size 22px
                        color #000000
                        span
                            background-image url(../images/icon-arr-down.png)
                            background-repeat no-repeat
                            background-position right center
                            padding-right 10px
                            font-size 14px
                            color #000000
                            display inline-block
                            margin-right 10px
                    .tags
                        display flex
                    .tag
                        flex 0 0 158px
                        font-size 16px
                        color #828282
                    .icon
                        width 16px
                        height 16px
                        position relative
                        top 2px
                        margin-right 5px
                    .icon-home
                        background-image url(../images/vr-icon8.png)
                    .icon-room
                        background-image url(../images/vr-icon9.png)
                    .icon-bath
                        background-image url(../images/vr-icon10.png)
    .right-con-message
        .texts
            .texts-date
                font-size 16px
                color #505050
                line-height 40px
            .texts-title
                font-size 30px
                line-height 1.2
                color #000000
            .text-body
                font-size 14px
                line-height 1.8
                color #505050

        .body
            padding-top 20px
            li
                a
                    display flex
                    justify-content space-between
                    font-size 14px
                    line-height 45px
                    color #333333
                    padding-right 10px
                    padding-left 10px
                    &:hover
                        background #faf6f7
                        color #1f6ab7
                    .new
                        height 17px
                        background $color
                        color #ffffff
                        line-height 17px
                        padding 0 10px
                        text-align center
                        line-height 17px
                        border-radius 3px
                        display inline-block
                        margin-left 10px

    .right-con-password
        .password-form
            width 500px
            margin 0 auto
            padding-top 120px
            li
                display flex
                margin-bottom 15px
                justify-content space-between
            .text
                flex 1
                height 50px
                line-height 48px
                border-radius 3px
                border 1px solid #dbdbdb
                padding 0 10px
                transition 0.3s
                font-size 14px
                color #333333
                &:focus
                    border-width: 1px;
                    border-color: rgb(43, 43, 43);
                    border-style: solid;
                    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
            .btn
                width 242px
                height 44px
                border-radius 3px
                background #2b2b2b
                color #ffffff
                font-size 22px
                text-align center
                &:hover
                    opacity 0.9
            .btn2
                background $color

    .right-con-publish
        .foot
            display flex
            justify-content space-between
            a
                width 100px
                height 44px
                display inline-block
                line-height 44px
                color #ffffff
                font-size 22px
                text-align center
                border-radius 3px
                &:hover
                    opacity 0.9
            .left-btn a
                background #424447
            .right-btn a
                background $color
        ul
            margin-top 35px
            margin-bottom 35px
            display flex
            align-items center
            flex-wrap wrap
        li
            margin-bottom 32px
            flex 0 0 340px
            display flex
            flex-direction column
            margin-right 25px
            &:nth-child(3n)
                margin-right 0
        .tit
            font-size 18px
            color #333333
            margin-bottom 10px
        .text
            flex 1
            height 47px
            line-height 47px
            border-radius 5px
            border 1px solid #dbdbdb
            padding 0 10px
            transition 0.3s
            font-size 14px
            color #333333
            &:focus
                border-width: 1px;
                border-color: rgb(43, 43, 43);
                border-style: solid;
                box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);

    .bottom-con
        background #ffffff
        margin-top 20px
        min-height 680px
        @extend .clearfix
        position relative
        .right-con
            padding 20px 60px
            padding-bottom 100px
            .head
                border-bottom 1px solid #dbdbdb
                display flex
                justify-content space-between
                .left-nav
                    display flex
                    .title
                        span,a
                            &:before
                                background transparent
                .title
                    margin-right 45px
                    .icon-backup
                        width 15px
                        height 13px
                        background-image url(../images/icon-backup.png)
                        margin-right 10px
                    span,a
                        position relative
                        padding-bottom 15px
                        display inline-block
                        font-size 20px
                        color #333333
                        &:before
                            content ''
                            display inline-block
                            height 2px
                            background #333333
                            left 0
                            right 0
                            bottom -1px
                            position absolute
                    &.active,&:hover
                        .icon-backup
                               background-image url(../images/icon-backup2.png)
                        a
                            color $color
                            &:before
                                background $color
                .icon-publish
                    background-image url(../images/icon-home1.png)
                    width 17px
                    height 16px
                    position relative
                    top -1px
                    margin-right 10px
                .right-link
                    a
                        display inline-block
                        width 241px
                        height 30px
                        background #424447
                        border-radius: 15px
                        color #ffffff
                        display flex
                        justify-content center
                        align-items center
                        font-size 18px
                        &:hover
                            background $color
        .left-con
            padding-top 20px
            padding-bottom 20px
            position absolute
            top 0
            bottom 0
            li
                em
                    height 20px
                    display inline-block
                    left 150px
                    border-radius 20px
                    color #ffffff
                    font-size 14px
                    font-weight bold
                    text-align center
                    background $color
                    padding 0 6px
                    line-height 20px
                    margin-left 10px
                a
                    color #ffffff
                    font-size 18px
                    padding-left 62px
                    line-height 60px
                    display flex
                    border 3px solid transparent
                    align-items center
                    &:hover
                        border-left-color #1f6ab7
                        background #ffffff
                        color #000000
                &.active
                    a
                        border-left-color #1f6ab7
                        background #ffffff
                        color #000000
    .top-con
        height 170px
        background #ffffff
        @extend .clearfix
        >div
            height 170px
        .user-infos
            padding-top 30px
            position relative
            .icon-close
              width 30px
              height 30px
              position absolute
              right 18px
              top 18px
              transition 0.3s
              background-image url(../images/icon-close.png)
              &:hover
                transform rotate(180deg)
            .submit
              width 85px
              height 37px
              border-radius 3px
              font-size 16px
              background #424447
              color #ffffff
              position absolute
              right 18px
              bottom 18px
              cursor pointer
              &:hover
                opacity 0.9
            ul
                display flex
                flex-wrap wrap
            .edit
                background-image url(../images/icon-edit2.png)
                background-repeat no-repeat
                background-position left center
                padding-left 29px
                position absolute
                right 60px
                top 20px
                line-height 24px
                display inline-block
                font-size 14px
                color #333333
                &:hover
                    color #333333
            .texts
                .tit
                    width auto
                    margin-right 10px
                li
                    position relative
                    padding-left 15px
                    &:before
                        content ''
                        display inline-block
                        width 6px
                        height 6px
                        background #c7c7c7
                        border-radius 6px
                        position absolute
                        left 0
                        top 50%
                        margin-top -3px

            li
                display flex
                flex 0 0 430px
                margin-bottom 8px
                margin-right 43px
            .tit
                font-size 16px
                color #333333
                font-weight bold
                width 131px
                line-height 34px
            .txt
                font-size 16px
                line-height 34px
                color #333333
            .text
                flex 1
                height 34px
                line-height 32px
                border-radius 5px
                border 1px solid #dbdbdb
                padding 0 10px
                transition 0.3s
                font-size 14px
                color #333333
                &:focus
                    border-width: 1px;
                    border-color: rgb(43, 43, 43);
                    border-style: solid;
                    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);

        .user-info
            display flex
            flex-direction: column
            align-items center
            justify-content center
            .img
                width 97px
                height 97px
                border 1px solid #5d6164
                border-radius 50%
                overflow hidden
                margin-bottom 10px
                position relative
                input[type='file']
                    position absolute
                    top 0
                    bottom 0
                    right 0
                    left 0
                    opacity 0
                    z-index 99
                    cursor pointer
                &:before
                    content ''
                    position absolute
                    display inline-block
                    background rgba(#fff,0.95) url(../images/icon-upload.png) center no-repeat
                    top 0
                    bottom 0
                    right 0
                    left 0
                    visibility hidden
                    opacity 0
                    transition 0.3s
                &:hover:before
                    visibility visible
                    opacity 1
                &.no-img:before
                    visibility visible
                    opacity 1

            h3
                font-size 16px
                color #ffffff


.message-wrap
  width 200px
  height 60px
  position fixed
  top 80px
  left 50%
  opacity 0
  visibility hidden
  transform translateX(-50%) scale(0.9)
  transition 0.1s
  z-index 99999
  .error,.success
    width 220px
    height 45px
    border-radius 45px
    background #ea644a
    color #ffffff
    text-align center
    line-height 45px
    font-size 16px
  .success
    background $color
  &.fadeIn
    top 120px
    opacity 1
    visibility visible
    transform translateX(-50%) scale(1)




@import './minix/modal';
.video-modal
    position static
    transform none
    .video-box
        width 1000px
        height 600px
        position fixed
        z-index 999
        top 50%
        left 50%
        transform translate(-50%,-50%)
    .close
      width 40px
      height 40px
      background-repeat no-repeat
      background-position center
      background-image url(../images/icon-big-close.png)
      position fixed
      z-index 1000
      top 30px
      right 30px
#fly
    width 8px
    height 8px
    background red
    border-radius 50%
.customInfobox
    min-width 322px
    height 140px
    max-width 400px
    background #ffffff
    box-shadow 0 0 10px rgba(#000,0.3)
    position relative
    display flex
    align-items center
    padding 20px
    cursor pointer
    .img
        flex 0 0 140px
        height 80px
        overflow hidden
        img
            width 100%
    .customInfobox-info
        padding 15px
        display flex
        .img
            width 128px
            margin-right 15px
    .customInfobox-texts
        padding 0 20px
        .title
            margin-bottom 10px
            font-size 16px
            color #000000
        .desc
            font-size 12px
            color #333
            line-height 1.5
    .desc
        font-size 16px
        line-height 24px
        color #414141
    .icon-close
        position absolute
        right 5px
        top 5px
        width 15px
        height 15px
        cursor pointer
        background-repeat no-repeat
        background-image url(../images/close.png)
body
    .MicrosoftMap .Infobox
        border none !important
        width 222px
        height 82px
        box-shadow 0 0 10px rgba(#000,0.3)
.md-comparison
    .head
        dl
            display flex
        dt
            width 250px
            h3
                font-size 30px
                color #000000
                line-height 1.6
                min-height 117px
        dd
            width 289px
            margin-right 20px
            text-align center
        dd:last-child
            margin-right 0
        .item
            display block
            height 254px
            position relative
            background #ffffff
            transition: 0.5s
            box-shadow -2.179px 3.355px 9px 0px rgba(0, 0, 0, 0.1)
            .img
                overflow hidden
                position relative
                .left-text
                    position absolute
                    bottom 0
                    left 23px
                    color #ffffff
                    bottom 14px
                    span
                        font-weight bold
                        font-size 12px
                        padding-right 15px
                        background-image url(../images/vr-icon7.png)
                        background-repeat no-repeat
                        background-position right center
                    .number
                        font-size 24px
                        color #ffffff
                        font-weight bold
                img
                    transition 0.4s
                    width 100%
                    height 178px
            &:before
                content ''
                width 31px
                height 31px
                background-color #2f2f2f
                @extend .icon
                position absolute
                background-image url(../images/vr-icon4.png)
                background-size 16px 9px
                top 0
                left 0
                z-index 9
            h3
                margin-bottom 10px
                @extend .toe
                a
                    font-size 18px
                    color #414141
                    &:hover
                        color $color
            h4
                a
                    font-size 12px
                    color #828282
            .text
                padding 15px 20px
            .icon-date
                width 16px
                height 16px
                position relative
                top 2px
                margin-right 5px
                background-image url(../images/icon-date.png)
        .item.add
            display flex
            align-items center
            justify-content center
            flex-direction column
            h3
                font-size 16px
                color #999999
                margin-top 10px
            &:before
                display none
        .icon-add
            width 30px
            height 30px
            background-image url(../images/icon-add2.png)
        .share
            .icon
                width 25px
                height 25px
                background-repeat no-repeat
                background-position center
                padding 0
            .icon1
                background-image url(../images/news-icon1.png)
            .icon2
                background-image url(../images/news-icon2.png)
            .icon3
                background-image url(../images/news-icon3.png)
            .icon4
                background-image url(../images/news-icon4.png)
            .icon5
                background-image url(../images/news-icon5.png)
    .body
        margin-top 20px
        table
            width 100%
            border 1px solid #f5f5f5
        tr:nth-child(2n)
            td
                background #ffffff
        tr:nth-child(2n+1)
            td
                background #f5f5f5
        tr
            td
                padding 0 20px
                height 50px
                font-size 16px
                color #2e2e2e
                transition 0.2s
            .icon-nosupport
                width 12px
                height 12px
                background-image url(../images/icon-nosupport.png)
            .icon-support
                width 12px
                height 12px
                background-image url(../images/support.png)
            &:hover
                td
                    background #d4d3d3

@import './minix/house';

//     .footer .last .qrcode ul
//         display block
//     .index-home .index-banner .banner-con
//         width 1040px
//     .wrap
//         width 1100px
//     .aside-nav .logo
//         line-height 60px
//         height 60px
//         img
//             max-height: 40px
//     .aside-nav .nav
//         margin-top 10px
//     .aside-nav .nav li a
//         line-height 40px
//         height 40px


.md-wellcom
    height 100vh
    position relative
    .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction
        z-index 999
        bottom :80px
    .top-link a
        letter-spacing 4px
        font-family optimariman
    .swiper-pagination-bullet
        width 16px
        height 16px
    .swiper-slide
        background-repeat no-repeat
        background-size cover
    .swiper-con
        position absolute
        left 0
        right 0
        top 54px
        bottom 70px
        z-index 99
        font-size 24px
        color #ffffff
        text-align center
        a
            color #ffffff
        span
            display inline-block
            margin 0 10px
        .logo
            margin 0 auto
            margin-top 3%
            text-align center
            width 520px
            margin-bottom 3%
        .slogn
            color #ffffff
            h3
                font-size 28px
                margin-bottom 20px
                letter-spacing 4px
                font-family optimariman
            h4
                font-size 26px
        .link
            text-align center
            line-height 48px
            margin-top 4%
            a
                display inline-block
                width 130px
                height 48px
                background #3b393a
                font-size 18px
                color #ffffff
                &:hover
                    background $color
    .swiper-foot
        position absolute
        bottom 0
        left 0
        right 0
        padding 0 90px
        z-index 99
        height 74px
        display flex
        justify-content space-between
        align-items center
        font-size 16px
        color #fff
        a
            color #ffffff
        .btn
            width 150px
            height 33px
            display inline-block
            background-position center
            background-repeat no-repeat
            background-color #ffffff
            border-radius 5px
            margin-left 15px
        .right-btn
            display flex
            align-items center
        .google
            background-image url(../images/google.png)
        .appStore
            background-image url(../images/appStore.png)
@media (max-width: 1600px)
    .md-wellcom .swiper-con .logo
        width 420px
    .md-wellcom .swiper-con a
        font-size 20px
    .md-wellcom .swiper-con .slogn h3
        font-size 22px
    .md-wellcom .swiper-con .slogn h4
        font-size 20px
    .md-wellcom .swiper-con .link a
        font-size 16px
        height 40px
        line-height 40px
        margin-right 1px
    .md-wellcom .swiper-foot
        padding 0 25px
        height 60px
        line-height 60px
        font-size 14px
    .md-wellcom .swiper-foot .btn
        width: 107px;
        height: 23px;
        background-size: 77px 19px;
    .md-wellcom .swiper-pagination-bullet
        width 10px
        height 10px
@media (max-width: 1366px)
    .md-wellcom .swiper-con .logo
        width 320px


.md-chartdata
    .chart-copyright
        text-align right
        margin-top 5px
        margin-right 10px
        color #333
    .chart-row
        display flex
        justify-content space-between
        margin-top 35px
        .head
            height 53px
            background #f1f1f1
            border-bottom 1px solid #dedede
            font-size 24px
            color #000000
            padding-left 20px
            line-height 52px
            position relative
            .change-btns
                color #000000
                font-size 14px
                text-align center
                display flex
                position absolute
                top 68px
                right 20px
                z-index 99
                .btn
                    padding 0 10px
                    height 29px
                    line-height 29px
                    border 1px solid #c0c0c0
                    min-width 88px
                    cursor pointer
                    &:first-child
                        border-right none
                    &.active
                        border none
                        background #1f6bb8
                        color #ffffff
        .flex-50
            flex 0 0 48%
            border 1px solid #dedede
        .flex1
            flex 1
            border 1px solid #dedede
.layout.active
    padding-left 0 !important
@import './minix/responsive';
