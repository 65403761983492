.modal-mask
    position fixed
    top 0
    bottom 0
    left 0
    right 0
    background rgba(#000,0.6)
    z-index 999
    display none
.modal-wrapper
    display none
    position fixed
    left 50%
    top 50%
    z-index 9999
.floor-modal
    width 1460px
    height 810px
    line-height 810px
    text-align center
    margin-top -405px
    margin-left -730px
    background #ffffff
    padding 0 80px
    .swiper-con
        overflow hidden
    .swiper-button-prev
        background-image url(../images/left-arrow-3.png)
        left -40px
        transition 0.3s
        &:hover
            background-image url(../images/left-arrow-3-1.png)
            left -45px
    .swiper-button-next
        background-image url(../images/right-arrow-3.png)
        right -40px
        transition 0.3s
        &:hover
            background-image url(../images/right-arrow-3-1.png)
            right -45px
.lose-password-modal
    .icon-success
        background-image url(../images/icon-susses.png)
        width 104px
        height 91px
    .success-tip
        text-align center
        margin-bottom 55px
        .tip
            font-size 18px
            line-height 1.5
            color #292929
.login-modal
    width 706px
    margin-left -353px
    margin-top 15vh
    top 0
    background #ffffff
    transition 0.3s
    display block
    .close
        width 38px
        height 38px
        background #000000 url(../images/login-close.png) no-repeat center
        border-radius 50%
        position absolute
        right -19px
        top -19px
        transition 0.3s
        cursor pointer
        &:hover
            transform rotate(180deg)
    .item
        display flex
        margin-bottom 15px
        position relative
        .code
            position absolute
            right 0
            top 2px
            right 2px
            height 44px
            line-height 44px
            width 110px
            text-align center
        .yzm-code
            background #e8e8e8
            color #4a4a4a
            font-size 12px
            top 1px
            bottom 1px
            right 1px
            border-left 1px solid #dbdbdb
            height auto
            display flex
            align-items center
            justify-content center
            &:hover
                background #e8e8e8+10%
    .remember
        margin-top 20px
        margin-bottom 20px
        font-size 12px
        color #787878
        input
            margin-right 10px
    .submit
        background #2b2b2b
        color #ffffff
        font-size 22px
        flex 1
        border-radius 3px
        height 44px
        transition 0.3s
        cursor pointer
        &:hover
            background $color
    .link-btn
        background transparent
        color #000000
        font-size 20px
        text-align center
        line-height 42px
        border 1px solid #c6c6c6
        &:hover
            border 1px solid $color
            color #ffffff
    .link
        margin-top 30px
        a
            color #787878
            &:hover
                color $color
    .text
        flex 1
        height 50px
        line-height 48px
        border-radius 3px
        border 1px solid #dbdbdb
        padding 0 10px
        transition 0.3s
        font-size 14px
        color #333333
        &:focus
            border-width: 1px;
            border-color: rgb(43, 43, 43);
            border-style: solid;
            box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
    .head
        background #e1e1e1
        ul
            display flex
            li
                height 70px
                width 178.5px
                display flex
                align-items center
                justify-content center
                font-size 18px
                color #000000
                font-weight bold
                cursor pointer
                &.active
                    background #ffffff
                &:hover:not(.active)
                    background #e1e1e1 + 10%
            .sms-title
                flex 1
                background #e1e1e1 !important
    .body
        display flex
        min-height 346px
        .sms-reg
            padding 25px 35px
            flex 1
            background #f8f6f7
            .txt
                font-size 12px
                color #323232
                margin-top 30px
        .texts
            padding 38px
            width 354px
            background #f8f6f7
            display flex
            align-items center
            flex-direction column
            .txt
                margin-top 55px
                color #8d8d8d
                font-size 14px
                line-height 1.8
                a
                    color #1f6ab7
                    &:hover
                        text-decoration: underline
            .tit
                font-size 14px
                line-height 1.8
                color #8d8d8d
            .btn
                margin-top 20px
                background-color: rgb(59, 89, 152);
                width: 275px;
                height: 54px;
                border-radius 3px
                display inline-block
                font-size 16px
                color #ffffff
                padding-left 65px
                line-height 54px
                background-image url(../images/fb-icon.png)
                background-position 20px 50%
                background-repeat no-repeat
                &:hover
                    opacity 0.9
            .google
                background-color #d72638
                background-image url(../images/goo-icon.png)

        .tab
            flex 1
            padding 34px 39px
            li
                display none
                &.active
                    display block
.md-error
    background #ffffff
    height 100vh
    display flex
    flex-direction column
    align-items center
    justify-content center
    >div
        width 100%
    .top
        border-bottom 1px solid #303030
        font-size 140px
        color #303030
        font-weight bold
        text-align center
    .bottom
        text-align center
        margin-top 25px
        h3
            font-size 26px
            color #303030
        .btns
            width 270px
            border 1px solid #a9a9a9
            margin 0 auto
            margin-top 100px
            display flex
            a
                flex 1
                text-align center
                font-size 22px
                color #303030
                display inline-block
                line-height 46px
                height 46px
            a:last-child
                background #1f6ab7
                color #ffffff

.submit-success-modal
    width 760px
    height 380px
    background #ffffff
    border-radius 5px
    transform none
    margin-left -380px
    margin-top -190px
    text-align center
    padding 90px
    padding-bottom 0
    .close
        width 45px
        height 45px
        cursor pointer
        background-image url(../images/icon-submit-close.png)
        position absolute
        right 0
        top 0
    .title
        font-size 30px
        color #1f6bb8
    .txt
        font-size 16px
        line-height 1.8
        color #666666
        margin-top 20px
    .icon-success
        background-image url(../images/icon-susses.png)
        width 104px
        height 91px
.careers-modal
    width 1460px
    padding 80px
    background #ffffff
    margin-top 8vh
    top 0
    transform none
    left 50%
    margin-left -730px
    form
        height 70vh
        display flex
        flex-direction: column
    &-head
        padding-bottom 40px
        border-bottom 1px solid $lineColor
        flex 0 0 100px
        h3
            font-size 26px
            color #000000
            margin-bottom 10px
        p
            font-size 16px
            color #999999
        .close
            cursor pointer
            position absolute
            width 33px
            height 33px
            top 80px
            right 80px
            background $color
            background-repeat no-repeat
            background-position center
            background-image url(../images/icon-close2.png)
    &-body
        padding 30px 0
        flex 1
        overflow-y auto
        padding 20px
        ul
            display flex
            justify-content space-between
            flex-wrap wrap
            li
                width 48%
                margin-bottom 50px
                .tit
                    font-size 20px
                    color #666666
                    margin-bottom 10px
                input,.text
                    border 1px solid $lineColor
                    border-radius 3px
                    height 48px
                    width 100%
                    padding 0 10px
                    font-size 16px
                &.row
                    flex 1
                    position relative
                    .input
                        position relative
                    input
                        border-radius 3px 0 0 3px
                    .btn
                        position absolute
                        top 0
                        right 0
                        border-radius 0 3px 3px 0
                        width 100px
                        height 48px
                        background #000000
                        color #ffffff
                        font-size 16px
                        line-height 48px
                        text-align center
                        overflow hidden
                        cursor pointer
                        input
                            cursor pointer
                            position absolute
                            left 0
                            right 0
                            bottom 0
                            top 0
                            opacity 0
    &-foot
        flex 0 0 80px
        padding-top 30px
        text-align center
        .submit
            width 300px
            height 60px
            line-height 60px
            background $color
            transition 0.2s
            text-align center
            font-size 18px
            color #ffffff
            cursor pointer
            &:hover
                background $color +10%

.preferences-modal
    width 620px
    height 375px
    background #ffffff
    transform none
    margin-left -310px
    margin-top -187px
    .apply-btn
        margin 30px 0
        input
            height 50px
            line-height 50px
            background $color
            color #ffffff
            text-align center
            border-radius 3px
            width 100%
            font-size 20px
            cursor pointer
            &:hover
                background $color + 30%
    .info
        ul
            display flex
            text-align center
            li
                flex 1
                .icon
                    height 16px
                    width 18px
                    background-image url(../images/img-tel.png)
                .icon2
                    background-image url(../images/img-tel2.png)

        font-size 18px
        color #666666
    .head
        background #f5f7fa
        height 90px
        line-height 90px
        position relative
        border-bottom 1px solid #e9eaed
        h3
            text-align center
            font-size 28px
            color #333333
        .close
            background-color #000000
            background-image url(../images/close-icon.png)
            background-repeat no-repeat
            background-position center
            transition 0.3s
            width 45px
            height 45px
            position absolute
            right 0
            top 0
            cursor pointer
            &:hover
                background-color $color
    .body
        padding 50px
        .con
            display flex
            align-items center
            .tit
                width 88px
                display inline-block
                text-align right
                padding-right 10px
                font-size 18px
            .btns
                width 125px
                border 1px solid #cccccc
                height 50px
                display flex
                border-radius 3px
                button
                    flex 1
                    cursor pointer
                    &.active
                        background #000000
                        color #ffffff
