@charset 'UTF-8';
@font-face {
  font-family: 'RobotoRegular';
  src: url("../fonts/Roboto-Regular.woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'optimariman';
  src: url("../fonts/OPTIMAROMAN.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'RobotoThin';
  src: url("../fonts/Roboto-Thin.ttf");
  font-weight: normal;
  font-style: normal;
}
select {
  background: none;
}
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}
body {
  font-family: 'RobotoThin', 'Helvetica Neue', Helvetica, Tahoma, Arial, 'Microsoft Yahei', 'PingFang SC', 'Hiragino Sans GB', 'WenQuanYi Micro Hei', sans-serif;
  font-size: 12px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
ul,
div,
article,
aside,
footer,
header,
hgroup,
menu,
nav,
section {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
a {
  text-decoration: none;
  transition: background 218ms cubic-bezier(0.42, 0, 0.58, 1);
}
a:hover {
  text-decoration: none;
}
li {
  list-style: none;
}
img {
  border: 0;
  vertical-align: middle;
}
input,
button,
textarea,
select {
  border: 0 none;
  outline: 0 none;
  font-family: Arial, sans-serif;
}
address,
em,
i {
  font-style: normal;
}
textarea {
  resize: none;
  -webkit-appearance: none;
}
html {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
label {
  cursor: pointer;
}
input[type='submit'] {
  background: transparent;
}
::selection {
  color: #fff;
  background: #0b61b1;
}
::-moz-selection {
  color: #fff;
  background: #0b61b1;
}
::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  background-color: #0b61b1;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #ccc;
}
.clearfix,
.md-contact .list,
.md-reference .body,
.md-reference-list .sort ul,
.md-dubai .body,
.md-about .our,
.md-ucenter .right-con-browse .body a,
.md-ucenter .bottom-con,
.md-ucenter .top-con {
  display: block;
  zoom: 1;
}
.clearfix:after,
.md-contact .list:after,
.md-reference .body:after,
.md-reference-list .sort ul:after,
.md-dubai .body:after,
.md-about .our:after,
.md-ucenter .right-con-browse .body a:after,
.md-ucenter .bottom-con:after,
.md-ucenter .top-con:after {
  content: '';
  display: block;
  font-size: 0;
  height: 0;
  clear: both;
  visibility: hidden;
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}
.swiper-container-no-flexbox .swiper-slide {
  float: left;
}
.swiper-container-vertical>.swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.swiper-container-multirow>.swiper-wrapper {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.swiper-container-free-mode>.swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}
.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.swiper-invisible-blank-slide {
  visibility: hidden;
}
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition-property: height, -webkit-transform;
  transition-property: height, -webkit-transform;
  -o-transition-property: transform, height;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform;
}
.swiper-container-3d {
  -webkit-perspective: 1200px;
  perspective: 1200px;
}
.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}
.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0,0,0,0.5)), to(rgba(0,0,0,0)));
  background-image: -webkit-linear-gradient(right, rgba(0,0,0,0.5), rgba(0,0,0,0));
  background-image: -o-linear-gradient(right, rgba(0,0,0,0.5), rgba(0,0,0,0));
  background-image: linear-gradient(to left, rgba(0,0,0,0.5), rgba(0,0,0,0));
}
.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0,0,0,0.5)), to(rgba(0,0,0,0)));
  background-image: -webkit-linear-gradient(left, rgba(0,0,0,0.5), rgba(0,0,0,0));
  background-image: -o-linear-gradient(left, rgba(0,0,0,0.5), rgba(0,0,0,0));
  background-image: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0));
}
.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,0.5)), to(rgba(0,0,0,0)));
  background-image: -webkit-linear-gradient(bottom, rgba(0,0,0,0.5), rgba(0,0,0,0));
  background-image: -o-linear-gradient(bottom, rgba(0,0,0,0.5), rgba(0,0,0,0));
  background-image: linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0));
}
.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0.5)), to(rgba(0,0,0,0)));
  background-image: -webkit-linear-gradient(top, rgba(0,0,0,0.5), rgba(0,0,0,0));
  background-image: -o-linear-gradient(top, rgba(0,0,0,0.5), rgba(0,0,0,0));
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0));
}
.swiper-container-wp8-horizontal,
.swiper-container-wp8-horizontal>.swiper-wrapper {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.swiper-container-wp8-vertical,
.swiper-container-wp8-vertical>.swiper-wrapper {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}
.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-lock {
  display: none;
}
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 0.3s opacity;
  -o-transition: 0.3s opacity;
  transition: 0.3s opacity;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}
.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
  position: relative;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
}
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}
button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}
.swiper-container-vertical>.swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 6px 0;
  display: block;
}
.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 8px;
}
.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  display: inline-block;
  -webkit-transition: 0.2s top, 0.2s -webkit-transform;
  transition: 0.2s top, 0.2s -webkit-transform;
  -o-transition: 0.2s transform, 0.2s top;
  transition: 0.2s transform, 0.2s top;
  transition: 0.2s transform, 0.2s top, 0.2s -webkit-transform;
}
.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}
.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap;
}
.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: 0.2s left, 0.2s -webkit-transform;
  transition: 0.2s left, 0.2s -webkit-transform;
  -o-transition: 0.2s transform, 0.2s left;
  transition: 0.2s transform, 0.2s left;
  transition: 0.2s transform, 0.2s left, 0.2s -webkit-transform;
}
.swiper-container-horizontal.swiper-container-rtl>.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: 0.2s right, 0.2s -webkit-transform;
  transition: 0.2s right, 0.2s -webkit-transform;
  -o-transition: 0.2s transform, 0.2s right;
  transition: 0.2s transform, 0.2s right;
  transition: 0.2s transform, 0.2s right, 0.2s -webkit-transform;
}
.swiper-pagination-progressbar {
  background: rgba(0,0,0,0.25);
  position: absolute;
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
}
.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
}
.swiper-container-horizontal>.swiper-pagination-progressbar,
.swiper-container-vertical>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}
.swiper-container-horizontal>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-container-vertical>.swiper-pagination-progressbar {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}
.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}
.swiper-pagination-progressbar.swiper-pagination-white {
  background: rgba(255,255,255,0.25);
}
.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
  background: #fff;
}
.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}
.swiper-pagination-progressbar.swiper-pagination-black {
  background: rgba(0,0,0,0.25);
}
.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
  background: #000;
}
.swiper-pagination-lock {
  display: none;
}
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0,0,0,0.1);
}
.swiper-container-horizontal>.swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}
.swiper-container-vertical>.swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}
.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0,0,0,0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}
.swiper-scrollbar-cursor-drag {
  cursor: move;
}
.swiper-scrollbar-lock {
  display: none;
}
.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.swiper-zoom-container>canvas,
.swiper-zoom-container>img,
.swiper-zoom-container>svg {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.swiper-slide-zoomed {
  cursor: move;
}
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}
.swiper-lazy-preloader:after {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}
.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}
.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-cube {
  overflow: visible;
}
.swiper-container-cube .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}
.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-next+.swiper-slide,
.swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible;
}
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-cube .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}
.swiper-container-flip {
  overflow: visible;
}
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.swiper-container-coverflow .swiper-wrapper {
  -ms-perspective: 1200px;
}
@-moz-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@charset 'UTF-8';
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}
.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}
.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}
.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}
.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}
.headShake {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: headShake;
  animation-name: headShake;
}
.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}
.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}
.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}
.jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center;
}
.bounceIn {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}
.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}
.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}
.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
}
.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}
.bounceOut {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
}
.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}
.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}
.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}
.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}
.fadeInLeft {
  animation-name: fadeInLeft;
}
.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}
.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}
.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}
.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
}
.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}
.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
}
.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}
.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
}
.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
}
.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}
.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
}
.flipOutX {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
.flipOutY {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY;
}
.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}
.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}
.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
}
.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
}
.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
}
.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
}
.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}
.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut;
}
.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
}
.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
}
.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
}
.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
}
.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-name: hinge;
  animation-name: hinge;
}
.jackInTheBox {
  -webkit-animation-name: jackInTheBox;
  animation-name: jackInTheBox;
}
.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn;
}
.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut;
}
.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}
.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
}
.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
}
.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight;
}
.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp;
}
.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}
.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown;
}
.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft;
}
.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight;
}
.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp;
}
.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}
.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}
.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}
.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}
.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}
.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}
.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}
@-moz-keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
@-webkit-keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
@-o-keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
@keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
@-moz-keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
@-webkit-keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
@-o-keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
@keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
@-moz-keyframes flash {
  from, 50%, to {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@-webkit-keyframes flash {
  from, 50%, to {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@-o-keyframes flash {
  from, 50%, to {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@-moz-keyframes flash {
  from, 50%, to {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@-webkit-keyframes flash {
  from, 50%, to {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@-o-keyframes flash {
  from, 50%, to {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@-moz-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-o-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-moz-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-o-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-moz-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-o-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-moz-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-o-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-moz-keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
@-webkit-keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
@-o-keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
@keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
@-moz-keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
@-webkit-keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
@-o-keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
@keyframes shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
@-moz-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@-o-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@-moz-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@-o-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@-moz-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-o-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-moz-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-o-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-moz-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes jello {
  from, 11.1%, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
@-webkit-keyframes jello {
  from, 11.1%, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
@-o-keyframes jello {
  from, 11.1%, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
@keyframes jello {
  from, 11.1%, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
@-moz-keyframes jello {
  from, 11.1%, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
@-webkit-keyframes jello {
  from, 11.1%, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
@-o-keyframes jello {
  from, 11.1%, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
@keyframes jello {
  from, 11.1%, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
@-moz-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-o-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-moz-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-o-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-moz-keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
@-o-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
@-moz-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
@-o-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
@-moz-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@-o-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@-moz-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@-o-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@-moz-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@-o-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@-moz-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@-o-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@-moz-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@-o-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@-moz-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@-o-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@-moz-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@-o-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@-moz-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@-o-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-80px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-80px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-80px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-80px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(80px, 0, 0);
    transform: translate3d(80px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(80px, 0, 0);
    transform: translate3d(80px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(80px, 0, 0);
    transform: translate3d(80px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(80px, 0, 0);
    transform: translate3d(80px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 80px, 0);
    transform: translate3d(0, 80px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 80px, 0);
    transform: translate3d(0, 80px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 80px, 0);
    transform: translate3d(0, 80px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 80px, 0);
    transform: translate3d(0, 80px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@-o-keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@-moz-keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@-o-keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@-moz-keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@-webkit-keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@-o-keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@-moz-keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@-webkit-keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@-o-keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
@-moz-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@-o-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@-moz-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@-o-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@-moz-keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@-webkit-keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@-o-keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@-moz-keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@-webkit-keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@-o-keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}
@-moz-keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@-o-keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@-moz-keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@-o-keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@-moz-keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@-webkit-keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@-o-keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@-moz-keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@-webkit-keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@-o-keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}
@-moz-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@-o-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@-moz-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@-o-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@-moz-keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@-webkit-keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@-o-keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@-moz-keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@-webkit-keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@-o-keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
@-moz-keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@-webkit-keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@-o-keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@-moz-keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@-webkit-keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@-o-keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
@-moz-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@-o-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@-moz-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@-o-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@-moz-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@-webkit-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@-o-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@-moz-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@-webkit-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@-o-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@-moz-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@-webkit-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@-o-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@-moz-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@-webkit-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@-o-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@-moz-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}
@-webkit-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}
@-o-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}
@keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}
@-moz-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}
@-webkit-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}
@-o-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}
@keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}
@-moz-keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-o-keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-o-keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes lightSpeedOut {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
@-webkit-keyframes lightSpeedOut {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
@-o-keyframes lightSpeedOut {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
@keyframes lightSpeedOut {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
@-moz-keyframes lightSpeedOut {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
@-webkit-keyframes lightSpeedOut {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
@-o-keyframes lightSpeedOut {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
@keyframes lightSpeedOut {
  from {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-o-keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-o-keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-o-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-o-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-o-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-o-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-o-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-o-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-o-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-o-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@-moz-keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
@-webkit-keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
@keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
@-webkit-keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
@keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}
@-webkit-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}
@-webkit-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@-webkit-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@-webkit-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@-webkit-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@-webkit-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}
@-webkit-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}
@-webkit-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}
@-moz-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@-o-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@-moz-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@-o-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@-moz-keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes rollOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}
@-webkit-keyframes rollOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}
@-o-keyframes rollOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}
@keyframes rollOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}
@-moz-keyframes rollOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}
@-webkit-keyframes rollOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}
@-o-keyframes rollOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}
@keyframes rollOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}
@-moz-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@-o-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@-moz-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@-o-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@-moz-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-webkit-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-o-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-moz-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-webkit-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-o-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-moz-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-webkit-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-o-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-moz-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-webkit-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-o-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-moz-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-webkit-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-o-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-moz-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-webkit-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-o-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-moz-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-webkit-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-o-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-moz-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-webkit-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-o-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-moz-keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@keyframes zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-o-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-moz-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-o-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-moz-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}
@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}
@-o-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}
@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}
@-moz-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}
@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}
@-o-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}
@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}
@-moz-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}
@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}
@-o-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}
@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}
@-moz-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}
@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}
@-o-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}
@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}
@-moz-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-o-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-moz-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-o-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}
@-moz-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@-o-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@-moz-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@-o-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@-moz-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@-o-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@-moz-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@-o-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@-moz-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@-o-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@-moz-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@-o-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@-moz-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@-o-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@-moz-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@-o-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
html {
  overflow-x: hidden;
}
.layout {
  overflow-x: hidden;
}
.fly {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #f00;
  z-index: 9999;
}
/* 下拉菜单 */
.sp-select {
  width: 220px;
  border-width: 1px;
  border-color: #dce1e6;
  border-style: solid;
  max-width: 100%;
  height: 45px;
  border-radius: 4px;
  position: relative;
  transition: 0.5s;
  background: #fff;
}
.sp-select .icon-arr {
  width: 19px;
  height: 40px;
  background-image: url("../images/select-arr.png");
}
.sp-select h3 {
  line-height: 45px;
  font-size: 14px;
  color: #242526;
  padding: 0 10px;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.sp-select h3 .select-title {
  display: flex;
}
.sp-select h3 .select-title .icon {
  width: 16px;
  margin-right: 5px;
}
.sp-select h3 .select-title .text {
  display: flex;
  align-items: center;
  height: 42px;
  flex: 1;
}
.sp-select h3 .icon-arr {
  position: absolute;
  right: 8px;
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
  line-height: normal;
}
.sp-select .select-list {
  opacity: 0;
  transition: 0.5s;
  visibility: hidden;
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  top: 65px;
  background: #fff;
  z-index: 193;
  border-radius: 5px;
  padding-top: 9px;
  padding-bottom: 9px;
  box-shadow: 0 0 30px rgba(31,107,184,0.25);
}
.sp-select .select-list .item {
  height: 36px;
  line-height: 36px;
  padding: 0 20px;
  cursor: pointer;
}
.sp-select .select-list .item .icon {
  width: 16px;
  margin-right: 5px;
}
.sp-select .select-list .item .text {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666;
}
.sp-select .select-list .item.is-active {
  background: #f0f7ff;
  color: #1d87f1;
}
.sp-select .select-list .select-search {
  margin-bottom: 10px;
  padding: 9px;
  position: relative;
}
.sp-select .select-list .select-search::before {
  content: '';
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url("../imgs/search.png") no-repeat;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.sp-select .select-list .select-search .text {
  width: 100%;
  border-radius: 3px;
  background-color: #f2f6fa;
  width: 330px;
  height: 40px;
  padding: 0 10px;
  font-size: 14px;
  padding-right: 40px;
}
.sp-select .select-list ul {
  max-height: 180px;
  overflow-y: auto;
}
.sp-select.is-active {
  border: 1px solid #1f6bb8;
  box-shadow: 0 0 30px rgba(31,107,184,0.25);
}
.sp-select.is-active .select-list {
  opacity: 1;
  top: 55px;
  visibility: visible;
}
img {
  max-width: 100%;
}
body,
html {
  min-width: 1280px;
  overflow-x: auto;
  background: #f9f6f7;
}
* {
  box-sizing: border-box;
  outline: none;
}
.index-more {
  text-align: right;
}
.index-more .more {
  background-repeat: no-repeat;
  background-image: url("../images/index-more.png");
  background-position: left center;
  height: 25px;
  display: inline-block;
  line-height: 25px;
  font-size: 26px;
  color: #414141;
  padding-left: 30px;
}
.index-more .more:hover {
  color: #1f6bb8;
  background-image: url("../images/index-more-hover.png");
}
.toe,
.aside-nav .nav li span,
.aside-nav .nav li .subnav a,
.index-home .con .body .left-input .type >span,
.index-home .con .body .left-input .select-list li,
.search-modal .con .body .left-input .type >span,
.search-modal .con .body .left-input .select-list li,
.index-list .body .item h3 a,
.gb-meun .right-sear .select-list li,
.md-reference .item h3 a,
.md-news li .text h3,
.md-dubai .body .item .text-right h3 a,
.md-dubai .body .item .text-right p,
.md-comparison .head .item h3 {
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
}
.icon,
.header .nav .meun-icon,
.header .site-nav .meun-close,
.share-links a,
.index-list .body .item:before,
.index-download .qrcode .btn,
.md-contact .social-platform a,
.md-reference .item:before,
.md-comparison .head .item:before {
  position: relative;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
}
.header {
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  z-index: 998;
  color: #fff;
  font-size: 16px;
  height: 85px;
  align-items: center;
  display: flex;
  -webkit-perspective: 15000px;
  -moz-perspective: 15000px;
  perspective: 15000px;
  transition: background 0.5s;
}
.header .cube {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  transform-style: preserve-3d;
  perspective: 15000px;
  transform: translateZ(-40px);
  transition: transform 0.5s, -webkit-transform 0.5s, -moz-transform 0.5s, -o-transform 0.5s;
}
.header.is-open .site-nav {
  background: rgba(255,255,255,0.6);
  opacity: 1;
}
.header.is-open .nav {
  opacity: 0;
}
.header.is-open .cube {
  webkit-transform: translateZ(-40px) rotateX(-90deg);
  -moz-transform: translateZ(-40px) rotateX(-90deg);
  transform: translateZ(-40px) rotateX(-90deg);
}
.header.is-active {
  background-image: url("../images/header-bg.png");
  background-repeat: repeat-x;
  background-position: top center;
}
.header.is-white {
  background: #fff;
}
.header.is-active .nav .meun-icon {
  opacity: 0;
}
.header.is-white {
  color: #000;
}
.header.is-white .site-nav {
  background: #fff;
  border-bottom: 1px solid #ccc;
}
.header.is-white .nav {
  background: #fff;
  border-bottom: 1px solid #ccc;
}
.header.is-white .nav .icon-call {
  background-image: url("../images/icon-call2.png");
}
.header.is-white .nav .icon-arr {
  background-image: url("../images/icon-head2-1.png");
}
.header.is-white .nav .icon-login {
  background-image: url("../images/icon-login-1.png");
}
.header.is-white .nav .icon-sear {
  background-image: url("../images/icon-header-sear-1.png");
}
.header.is-white .nav .icon-app {
  background-image: url("../images/icon-header-app-1.png");
}
.header.is-white .meun-icon {
  opacity: 0;
  visibility: hidden;
}
.header.is-white .nav a {
  color: #000;
}
.header.is-white .nav .meun-icon {
  color: #333;
  opacity: 1;
  visibility: visible;
  background-image: url("../images/meun-icon2.png");
}
.header.is-active.is-white .nav .meun-icon {
  visibility: hidden;
  opacity: 0;
}
.header.is-ucenter .nav .meun-icon {
  visibility: hidden;
  opacity: 0;
}
.header .nav {
  display: flex;
  justify-content: space-between;
  position: absolute;
  align-items: center;
  height: 100%;
  width: 100%;
  flex: 1;
  -webkit-transform: rotateY(0) translateZ(40px);
  -moz-transform: rotateY(0) translateZ(40px);
  transform: rotateY(0) translateZ(40px);
  padding: 0 24px;
}
.header .nav .meun-icon {
  background-image: url("../images/meun-icon.png");
  background-position: left center;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  padding-left: 45px;
  cursor: pointer;
}
.header .site-nav {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform: rotateX(90deg) translateZ(40px);
  -moz-transform: rotateX(90deg) translateZ(40px);
  transform: rotateX(90deg) translateZ(40px);
  padding: 0 24px;
  opacity: 0;
}
.header .site-nav .meun-close {
  background-image: url("../images/meun-close.png");
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: 0.3s;
}
.header .site-nav .meun-close:hover {
  transform: rotate(90deg);
}
.header .site-nav a {
  font-size: 18px;
  color: #231815;
  height: 45px;
  padding: 0 20px;
  display: inline-block;
  line-height: 45px;
  border-radius: 3px;
  transition: none;
}
.header .site-nav a:hover {
  color: #1f6bb8;
}
.header .site-nav a.active {
  color: #fff;
  background: #1f6bb8;
}
.header .nav .qrcode {
  position: relative;
}
.header .nav .qrcode:hover .code {
  visibility: visible;
  opacity: 1;
  top: 30px;
}
.header .nav .qrcode .code {
  position: absolute;
  visibility: hidden;
  transition: 0.3s;
  opacity: 0;
  top: 40px;
  left: -30px;
  width: 100px;
  height: 100px;
}
.header .nav .icon-sear {
  width: 30px;
  height: 20px;
  margin-right: 20px;
  top: 4px;
  background-image: url("../images/icon-header-sear.png");
}
.header .nav .icon-app {
  width: 30px;
  height: 20px;
  margin-right: 20px;
  top: 4px;
  background-image: url("../images/icon-header-app.png");
}
.header .nav .exchange {
  cursor: pointer;
}
.header .nav .en {
  margin-left: 20px;
  display: inline-block;
  text-align: center;
}
.header .nav .en .icon {
  width: 24px;
  height: 14px;
  margin-right: 5px;
  background-size: contain;
  top: 2px;
}
.header .nav .en .icon-arr {
  width: 8px;
}
.header .nav .en .icon-en {
  background-size: cover;
  background-image: url("../images/gb.png");
}
.header .nav .en .icon-cn {
  background-size: cover;
  background-image: url("../images/cn.png");
}
.header .nav .en .icon-alb {
  background-size: cover;
  background-image: url("../images/icon-head1.png");
}
.header .nav .phone {
  margin-left: 20px;
}
.header .nav .en {
  cursor: pointer;
  position: relative;
}
.header .nav .en:hover .subnav {
  visibility: visible;
  opacity: 1;
}
.header .nav .en .subnav {
  width: 80px;
  line-height: 27px;
  position: absolute;
  background: #fff;
  margin-top: 10px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  text-align: left;
  left: -10px;
}
.header .nav .en .subnav a {
  display: block;
  color: #000;
  padding-left: 10px;
}
.header .nav .en .subnav a:hover {
  background: #1f6bb8;
  color: #fff;
}
.header .nav .icon-login {
  background-image: url("../images/icon-login.png");
  width: 15px;
  height: 17px;
  margin-right: 5px;
  top: 3px;
}
.header .nav .icon-call {
  background-image: url("../images/icon-call.png");
  width: 12px;
  height: 16px;
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.header .nav .icon-arr {
  width: 8px;
  height: 5px;
  position: relative;
  top: -2px;
  margin-left: 5px;
  background-image: url("../images/icon-head2.png");
}
.header .nav .icon-rate {
  margin-right: 5px;
  background-image: url("../images/icon-head1.png");
  background-size: contain;
  width: 24px;
  height: 12px;
}
.header .nav a {
  color: #fff;
  transition: none;
}
.header .nav .login {
  margin-left: 26px;
  position: relative;
}
.header .nav .login.login.top_logintext {
  margin-left: 0;
}
.header .nav .login .icon {
  margin-right: 10px;
}
.header .nav .login em {
  font-size: 12px;
  position: absolute;
  left: 18px;
  top: -5px;
  width: 13px;
  height: 13px;
  display: inline-block;
  border-radius: 50%;
  color: #fff;
  background: #1f6bb8;
  text-align: center;
  line-height: 13px;
}
.layout {
  transition: 0.5s;
}
.layout.active {
  padding-left: 60px;
}
.layout.is-ucenter {
  padding-top: 85px;
  padding-left: 60px;
}
.aside-nav {
  width: 215px;
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  padding-bottom: 40px;
  transition: width 0.5s;
  border-right: 1px solid #e0e0e0;
  transform: translate(-100%);
}
.aside-nav .nav-btn {
  background-image: url("../images/aside-btn.png");
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.4s;
  position: fixed;
  width: 215px;
  height: 40px;
  bottom: 0;
  left: 0;
  cursor: pointer;
}
.aside-nav.is-ucenter.active {
  transform: translateX(0);
}
.aside-nav.is-ucenter.active .logo {
  display: block;
}
.aside-nav .logo {
  padding: 0 12px;
  border-bottom: 1px solid #bebebe;
  height: 85px;
  line-height: 85px;
}
.aside-nav .logo img {
  height: 55px;
  transition: 1s;
  max-width: none;
}
.aside-nav .logo a {
  display: block;
}
.aside-nav .nav {
  margin-top: 20px;
}
.aside-nav .nav li {
  display: flex;
  position: relative;
  overflow: hidden;
}
.aside-nav .nav li span {
  transition: 0.3s;
  flex: 0 0 140px;
}
.aside-nav .nav li.is-subnav:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  background-image: url("../images/arrow.png");
  background-repeat: no-repeat;
  background-position: center;
  content: '';
  display: inline-block;
  width: 10px;
  height: 15px;
}
.aside-nav .nav li.is-subnav:hover:before {
  background-image: url("../images/arrow-actice.png");
}
.aside-nav .nav li.is-subnav:hover .subnav {
  top: 0;
  opacity: 1;
  visibility: visible;
}
.aside-nav .nav li .subnav {
  width: 256px;
  padding: 10px 0;
  position: absolute;
  transform: translateX(218px);
  top: 20px;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  background: #fff;
  box-shadow: 1px -1px 14px 0px rgba(0,0,0,0.1);
}
.aside-nav .nav li .subnav a {
  font-size: 14px;
  padding: 0 15px;
}
.aside-nav .nav li .subnav a:hover {
  background: #1f6bb8;
  color: #fff;
}
.aside-nav .nav li .icon {
  flex: 0 0 40px;
  background-repeat: no-repeat;
  background-position: center;
}
.aside-nav .nav li .icon1 {
  background-image: url("../images/icon1.png");
}
.aside-nav .nav li .icon2 {
  background-image: url("../images/icon2.png");
}
.aside-nav .nav li .icon3 {
  background-image: url("../images/icon3.png");
}
.aside-nav .nav li .icon4 {
  background-image: url("../images/icon4.png");
}
.aside-nav .nav li .icon5 {
  background-image: url("../images/icon5.png");
}
.aside-nav .nav li .icon6 {
  background-image: url("../images/icon6.png");
}
.aside-nav .nav li .icon7 {
  background-image: url("../images/icon8.png");
}
.aside-nav .nav li .icon8 {
  background-image: url("../images/icon7.png");
}
.aside-nav .nav li .icon9 {
  background-image: url("../images/icon9.png");
}
.aside-nav .nav li .icon10 {
  background-image: url("../images/icon10.png");
}
.aside-nav .nav li .icon11 {
  background-image: url("../images/icon11.png");
}
.aside-nav .nav li a {
  padding-left: 10px;
  display: flex;
  flex: 1;
  height: 50px;
  line-height: 50px;
  color: #000;
  font-size: 14px;
}
.aside-nav .nav li a:hover {
  color: #1f6bb8;
}
.aside-nav .nav li a:hover .icon1 {
  background-image: url("../images/icon1-1.png");
}
.aside-nav .nav li a:hover .icon2 {
  background-image: url("../images/icon2-1.png");
}
.aside-nav .nav li a:hover .icon3 {
  background-image: url("../images/icon3-1.png");
}
.aside-nav .nav li a:hover .icon4 {
  background-image: url("../images/icon4-1.png");
}
.aside-nav .nav li a:hover .icon5 {
  background-image: url("../images/icon5-1.png");
}
.aside-nav .nav li a:hover .icon6 {
  background-image: url("../images/icon6-1.png");
}
.aside-nav .nav li a:hover .icon7 {
  background-image: url("../images/icon8-1.png");
}
.aside-nav .nav li a:hover .icon8 {
  background-image: url("../images/icon7-1.png");
}
.aside-nav .nav li a:hover .icon9 {
  background-image: url("../images/icon9-1.png");
}
.aside-nav .nav li a:hover .icon10 {
  background-image: url("../images/icon10-1.png");
}
.aside-nav .nav li a:hover .icon11 {
  background-image: url("../images/icon11-1.png");
}
.aside-nav.active {
  width: 60px;
}
.aside-nav.active .logo {
  display: none;
}
.aside-nav.active .nav {
  margin-top: 5px;
}
.aside-nav.active .nav li.is-subnav:before {
  display: none;
}
.aside-nav.active .nav li span {
  flex: 0 0 1px;
  opacity: 0;
  overflow: hidden;
  width: 1px;
  visibility: hidden;
}
.aside-nav.active .logo {
  overflow: hidden;
}
.aside-nav.active .logo img {
  height: 39px;
}
.aside-nav.active .nav-btn {
  width: 60px;
  transform: rotateY(-180deg);
  transform-origin: center;
}
.aside-nav.active .nav li .subnav {
  transform: translateX(62px);
}
.aside-nav.is-show {
  transform: translateX(0);
}
.aside-nav.is-show .nav li {
  overflow: visible;
}
.index-home {
  height: 100vh;
  position: relative;
}
.index-home .con .head dl,
.search-modal .con .head dl {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.index-home .con .head dd,
.search-modal .con .head dd {
  font-size: 28px;
  color: #fff;
  padding: 0 25px;
  position: relative;
  font-weight: bold;
  cursor: pointer;
}
.index-home .con .head dd.active,
.search-modal .con .head dd.active {
  color: #1f6bb8;
}
.index-home .con .head dd:after,
.search-modal .con .head dd:after {
  content: '';
  display: inline-block;
  width: 9px;
  height: 24px;
  background: url("../images/line.png");
  position: absolute;
  right: -8px;
  top: 5px;
}
.index-home .con .head dd:nth-child(5):after,
.search-modal .con .head dd:nth-child(5):after {
  display: none;
}
.index-home .con .head dt,
.search-modal .con .head dt {
  font-size: 22px;
  font-weight: bold;
}
.index-home .con .head dt a,
.search-modal .con .head dt a {
  color: #fff;
  width: 277px;
  height: 36px;
  line-height: 36px;
  background: #072933;
  border-radius: 18px;
  display: inline-block;
  transition: 0.3s;
}
.index-home .con .head dt a:hover,
.search-modal .con .head dt a:hover {
  background: #1f6bb8;
}
.index-home .con .body .icon,
.search-modal .con .body .icon {
  width: 30px;
  height: 20px;
  position: relative;
  top: 4px;
  margin-left: 5px;
  background-image: url("../images/icon-home.png");
}
.index-home .con .body .icon-type,
.search-modal .con .body .icon-type {
  background-image: url("../images/icon-type.png");
}
.index-home .con .body .icon-room,
.search-modal .con .body .icon-room {
  background-image: url("../images/icon-room.png");
}
.index-home .con .body .icon-price,
.search-modal .con .body .icon-price {
  background-image: url("../images/icon-price.png");
}
.index-home .con .body .item,
.search-modal .con .body .item {
  display: none;
}
.index-home .con .body .item:first-child,
.search-modal .con .body .item:first-child {
  display: block;
}
.index-home .con .body .search,
.search-modal .con .body .search {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.index-home .con .body .left-input,
.search-modal .con .body .left-input {
  background: #fff;
  height: 64px;
  width: 900px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 999;
  font-size: 18px;
  color: #5a5a5a;
  line-height: 64px;
  text-align: left;
}
.index-home .con .body .left-input .location,
.search-modal .con .body .left-input .location {
  align-items: center;
  display: flex;
  position: relative;
}
.index-home .con .body .left-input .location .icon-home,
.search-modal .con .body .left-input .location .icon-home {
  top: -1px;
}
.index-home .con .body .left-input .location::before,
.search-modal .con .body .left-input .location::before {
  display: none;
}
.index-home .con .body .left-input .location input,
.search-modal .con .body .left-input .location input {
  font-size: 18px;
}
.index-home .con .body .left-input .location .select-list,
.search-modal .con .body .left-input .location .select-list {
  position: absolute;
  left: 0;
  opacity: 1;
  visibility: visible;
  top: 100%;
  right: 0;
}
.index-home .con .body .left-input .type >span,
.search-modal .con .body .left-input .type >span {
  padding-left: 35px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.index-home .con .body .left-input .type .price-input,
.search-modal .con .body .left-input .type .price-input {
  right: auto;
  left: auto;
}
.index-home .con .body .left-input .text,
.search-modal .con .body .left-input .text {
  width: 100%;
  padding: 0 10px;
  font-size: 18px;
}
.index-home .con .body .left-input .price-input,
.search-modal .con .body .left-input .price-input {
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  background: #fff;
  height: 80px;
  width: 450px;
  margin-top: 1px;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.index-home .con .body .left-input .price-input span,
.search-modal .con .body .left-input .price-input span {
  display: inline-block;
  text-align: center;
  color: #000;
  width: 27px;
}
.index-home .con .body .left-input .price-input input,
.search-modal .con .body .left-input .price-input input {
  width: 186px;
  height: 40px;
  border: 1px solid #d1d1d1;
  color: #5a5a5a;
  padding: 0 10px;
  font-size: 14px;
  border-radius: 3px;
}
.index-home .con .body .left-input .select-list,
.search-modal .con .body .left-input .select-list {
  opacity: 0;
  visibility: hidden;
  margin-top: 1px;
  padding: 10px 0;
  background: #fff;
  overflow-y: auto;
  font-size: 14px;
  transition: 0.2s;
  z-index: 99;
  max-height: 200px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
.index-home .con .body .left-input .select-list li,
.search-modal .con .body .left-input .select-list li {
  line-height: 37px;
  color: #5a5a5a;
  padding: 0 23px;
  display: block;
}
.index-home .con .body .left-input .select-list li.active,
.search-modal .con .body .left-input .select-list li.active,
.index-home .con .body .left-input .select-list li:hover,
.search-modal .con .body .left-input .select-list li:hover {
  background: #1f6bb8;
  color: #fff;
}
.index-home .con .body .left-input >div,
.search-modal .con .body .left-input >div {
  flex: 1;
  border-right: 1px solid #ccc;
  position: relative;
  cursor: pointer;
}
.index-home .con .body .left-input >div:before,
.search-modal .con .body .left-input >div:before {
  position: absolute;
  right: 0;
  width: 13px;
  height: 7px;
  background-image: url("../images/arrow-down.png");
  content: '';
  display: inline-block;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.index-home .con .body .left-input >div:hover,
.search-modal .con .body .left-input >div:hover {
  color: #1f6bb8;
}
.index-home .con .body .left-input >div:hover .select-list,
.search-modal .con .body .left-input >div:hover .select-list,
.index-home .con .body .left-input >div:hover .price-input,
.search-modal .con .body .left-input >div:hover .price-input {
  opacity: 1;
  visibility: visible;
}
.index-home .con .body .submit,
.search-modal .con .body .submit {
  height: 64px;
  width: 142px;
  background: #1f6bb8;
  color: #fff;
  font-size: 22px;
  transition: 0.2s;
  cursor: pointer;
}
.index-home .con .body .submit:hover,
.search-modal .con .body .submit:hover {
  background: #237ad1;
}
.index-home .nav {
  position: absolute;
  bottom: 0;
  height: 102px;
  background: #fff;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
}
.index-home .nav .wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 28px 0;
}
.index-home .nav .item {
  flex: 1;
  border-right: 1px solid #e2e2e2;
  line-height: 32px;
}
.index-home .nav .item .icon {
  width: 42px;
  height: 32px;
  position: relative;
  top: 7px;
}
.index-home .nav .item .icon1 {
  background-image: url("../images/nav-icon1.png");
}
.index-home .nav .item .icon2 {
  background-image: url("../images/nav-icon2.png");
}
.index-home .nav .item .icon3 {
  background-image: url("../images/nav-icon3.png");
}
.index-home .nav .item .icon4 {
  background-image: url("../images/nav-icon4.png");
}
.index-home .nav .item:last-child {
  border-right: none;
}
.index-home .nav .item a {
  font-size: 22px;
  color: #414141;
}
.index-home .nav .item a:hover {
  color: #1f6bb8;
}
.index-home .index-banner {
  position: relative;
  overflow: hidden;
}
.index-home .index-banner .banner-con {
  z-index: 99;
  text-align: center;
  width: 1100px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -80%);
  position: absolute;
  color: #fff;
}
.index-home .index-banner .banner-con .icon-publish {
  background-image: url("../images/icon-home1.png");
  width: 17px;
  height: 16px;
  position: relative;
  top: -1px;
  margin-right: 10px;
}
.index-home .index-banner .banner-con .slogen {
  margin-bottom: 60px;
}
.index-home .index-banner .banner-con .slogen li {
  height: 173px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;
}
.index-home .index-banner .banner-con .slogen h3 {
  font-size: 40px;
  font-weight: bold;
}
.index-home .index-banner .banner-con .slogen h4 {
  font-size: 60px;
  font-weight: bold;
}
.index-home .index-banner .banner-con .slogen h5 {
  font-size: 16px;
}
.index-home .index-banner .swiper-slide {
  height: 100vh;
  background-size: cover;
  background-position: center;
}
.search-modal {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  position: fixed;
  display: none;
  background: rgba(0,0,0,0.9);
}
.search-modal .header-sear-close {
  width: 44px;
  height: 44px;
  display: inline-block;
  background-image: url("../images/header-close.png");
  position: absolute;
  right: 10%;
  top: 20px;
  cursor: pointer;
}
.search-modal .header-sear-close:hover {
  opacity: 0.9;
}
.search-modal .icon-publish {
  background-image: url("../images/icon-home1.png");
  width: 17px;
  height: 16px;
  position: relative;
  top: -1px;
  margin-right: 10px;
}
.search-modal .con {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.search-modal .con .head dt a {
  text-align: center;
  background-color: transparent;
}
.share-links {
  background: #faf6f7;
  height: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.share-links a {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #282828;
  margin: 0 11px;
  transition: 0.2s;
}
.share-links a:hover {
  background-color: #1f6bb8;
}
.share-links .share-link1 {
  background-image: url("../images/twitter.png");
}
.share-links .share-link2 {
  background-image: url("../images/linkedin.png");
}
.share-links .share-link3 {
  background-image: url("../images/facebook.png");
}
.share-links .share-link4 {
  background-image: url("../images/ins.png");
}
.share-links .share-link5 {
  background-image: url("../images/google-plus.png");
}
.index-wrap {
  background: url("../images/index-bg.jpg");
  background-repeat: no-repeat;
}
.wrap {
  width: 1458px;
  margin: 0 auto;
}
.index-list {
  padding-top: 73px;
  position: relative;
}
.index-list .wrap {
  overflow: visible;
  padding-bottom: 115px;
}
.index-list .head {
  margin-bottom: 10px;
}
.index-list .head ul {
  display: flex;
  justify-content: center;
  align-items: center;
}
.index-list .head li {
  font-size: 50px;
  padding: 0 54px;
  text-align: center;
  color: #000;
  border-right: 1px solid #d5d2d3;
  cursor: pointer;
}
.index-list .head li .icon {
  width: 45px;
  height: 40px;
  margin-right: 15px;
}
.index-list .head li .icon1 {
  background-image: url("../images/vr-icon1.png");
}
.index-list .head li .icon2 {
  background-image: url("../images/vr-icon2.png");
}
.index-list .head li .icon3 {
  background-image: url("../images/vr-icon3.png");
}
.index-list .head li.active,
.index-list .head li:hover {
  color: #1f6bb8;
}
.index-list .head li.active .icon1,
.index-list .head li:hover .icon1 {
  background-image: url("../images/vr-icon1-1.png");
}
.index-list .head li.active .icon2,
.index-list .head li:hover .icon2 {
  background-image: url("../images/vr-icon2-1.png");
}
.index-list .head li.active .icon3,
.index-list .head li:hover .icon3 {
  background-image: url("../images/vr-icon3-1.png");
}
.index-list .head li:last-child {
  border-right: none;
}
.index-list .body .tab-item {
  display: none;
  position: relative;
}
.index-list .body .tab-item .slide {
  overflow: hidden;
  padding: 15px 0;
}
.index-list .body .tab-item .page .swiper-button-prev {
  background-image: url("../images/left-arrow-3.png");
  left: -40px;
  transition: 0.3s;
}
.index-list .body .tab-item .page .swiper-button-prev:hover {
  background-image: url("../images/left-arrow-3-1.png");
  left: -45px;
}
.index-list .body .tab-item .page .swiper-button-next {
  background-image: url("../images/right-arrow-3.png");
  right: -40px;
  transition: 0.3s;
}
.index-list .body .tab-item .page .swiper-button-next:hover {
  background-image: url("../images/right-arrow-3-1.png");
  right: -45px;
}
.index-list .body .tab-item .pager {
  font-size: 28px;
  color: #414141;
  position: absolute;
  bottom: -80px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 420px;
  margin: 0 auto;
  line-height: 46px;
}
.index-list .body .tab-item .pager .line {
  width: 150px;
  background: #b3afb0;
  height: 1px;
  margin: 0 15px;
}
.index-list .body .tab-item .pager .swiper-pagination {
  font-size: 28px;
  color: #1f6bb8;
}
.index-list .body .tab-item .pager .swiper-pagination .swiper-pagination-total {
  color: #414141;
}
.index-list .body .tab-item .pager .swiper-pagination {
  position: static;
  width: auto;
}
.index-list .body .tab-item .pager .swiper-button-next,
.index-list .body .tab-item .pager .swiper-button-prev {
  margin-top: 0;
  position: static;
  width: 46px;
  height: 46px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../images/left-arrow.png");
}
.index-list .body .tab-item .pager .swiper-button-next:hover,
.index-list .body .tab-item .pager .swiper-button-prev:hover {
  background-image: url("../images/left-arrow-1.png");
}
.index-list .body .tab-item .pager .swiper-button-next {
  margin-left: 10px;
  background-image: url("../images/right-arrow.png");
}
.index-list .body .tab-item .pager .swiper-button-next:hover {
  background-image: url("../images/right-arrow-1.png");
}
.index-list .body .tab-item:first-child {
  display: block;
}
.index-list .body .slide-item .slick-arrow {
  cursor: pointer;
  position: absolute;
  width: 38px;
  height: 68px;
  top: 50%;
  transform: translateY(-50%);
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  color: transparent;
  background-image: url("../images/left-arrow.png");
}
.index-list .body .slide-item .slick-prev {
  left: -40px;
}
.index-list .body .slide-item .slick-next {
  right: -40px;
  background-image: url("../images/right-arrow.png");
}
.index-list .body .item,
.md-reference .item {
  height: 450px;
  position: relative;
  background: #fff;
  transition: 0.5s;
}
.index-list .body .item:hover,
.md-reference .item:hover {
  box-shadow: -2.179px 3.355px 9px 0px rgba(0,0,0,0.1);
}
.index-list .body .item .img,
.md-reference .item .img {
  overflow: hidden;
  position: relative;
}
.index-list .body .item .img .right-link,
.md-reference .item .img .right-link {
  position: absolute;
  top: 15px;
  right: 15px;
}
.index-list .body .item .img .right-link .icon,
.md-reference .item .img .right-link .icon {
  width: 20px;
  height: 18px;
}
.index-list .body .item .img .right-link .icon-heart,
.md-reference .item .img .right-link .icon-heart {
  background-image: url("../images/vr-icon5.png");
}
.index-list .body .item .img .right-link .icon-add,
.md-reference .item .img .right-link .icon-add {
  margin-left: 10px;
  background-image: url("../images/vr-icon6.png");
}
.index-list .body .item .img .left-text,
.md-reference .item .img .left-text {
  position: absolute;
  bottom: 0;
  left: 23px;
  color: #fff;
  bottom: 14px;
}
.index-list .body .item .img .left-text span,
.md-reference .item .img .left-text span {
  font-weight: bold;
  font-size: 16px;
  padding-right: 15px;
  background-image: url("../images/vr-icon7.png");
  background-repeat: no-repeat;
  background-position: right center;
}
.index-list .body .item .img .left-text .number,
.md-reference .item .img .left-text .number {
  font-size: 40px;
  color: #fff;
  font-weight: bold;
}
.index-list .body .item .img img,
.md-reference .item .img img {
  transition: 0.4s;
  width: 100%;
  display: block;
  height: 292px;
}
.index-list .body .item:before,
.md-reference .item:before {
  content: '';
  width: 49px;
  height: 49px;
  background-color: #2f2f2f;
  position: absolute;
  background-image: url("../images/vr-icon4.png");
  top: 0;
  left: 0;
  z-index: 9;
}
.index-list .body .item.no-vr:before,
.md-reference .item.no-vr:before {
  display: none;
}
.index-list .body .item h3,
.md-reference .item h3 {
  margin-bottom: 10px;
}
.index-list .body .item h3 a,
.md-reference .item h3 a {
  font-size: 30px;
  color: #414141;
}
.index-list .body .item h3 a:hover,
.md-reference .item h3 a:hover {
  color: #1f6bb8;
}
.index-list .body .item h4 a,
.md-reference .item h4 a {
  font-size: 16px;
  color: #828282;
}
.index-list .body .item .text,
.md-reference .item .text {
  border-bottom: 1px solid #f2f2f2;
  padding: 15px 20px;
  height: 102px;
}
.index-list .body .item .icon-date,
.md-reference .item .icon-date {
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
  margin-right: 5px;
  background-image: url("../images/icon-date.png");
}
.index-list .body .item .tags,
.md-reference .item .tags {
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  height: 52px;
  color: #828282;
}
.index-list .body .item .tags .tag:nth-child(2),
.md-reference .item .tags .tag:nth-child(2) {
  text-align: center;
}
.index-list .body .item .tags .tag:last-child,
.md-reference .item .tags .tag:last-child {
  text-align: right;
}
.index-list .body .item .tags .icon,
.md-reference .item .tags .icon {
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.index-list .body .item .tags .icon-home,
.md-reference .item .tags .icon-home {
  background-image: url("../images/vr-icon8.png");
}
.index-list .body .item .tags .icon-room,
.md-reference .item .tags .icon-room {
  background-image: url("../images/vr-icon9.png");
}
.index-list .body .item .tags .icon-bath,
.md-reference .item .tags .icon-bath {
  background-image: url("../images/vr-icon10.png");
}
.index-list .body .item .tags .tag,
.md-reference .item .tags .tag {
  flex: 1;
}
.index-list .body .slide3 .item:before {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-color: transparent;
  background-image: none;
}
.index-list .body .slide3 .item.item-luxury:before {
  background-image: url("../images/item-luxury.png");
}
.index-list .body .slide3 .item.item-new:before {
  background-image: url("../images/item-news.png");
}
.index-averages {
  padding-top: 65px;
}
.index-averages .wrap {
  padding-bottom: 20px;
}
.index-averages .head {
  text-align: center;
}
.index-averages .head h3 {
  font-size: 50px;
  margin-bottom: 30px;
  color: #000;
}
.index-averages .head p {
  font-size: 16px;
  color: #000;
  line-height: 26px;
}
.index-averages .body {
  margin-top: 40px;
}
.index-averages .tab-head {
  margin-bottom: 25px;
}
.index-averages .tab-head ul {
  display: flex;
}
.index-averages .tab-head li {
  flex: 0 0 150px;
  font-size: 20px;
  height: 50px;
  line-height: 48px;
  text-align: center;
  border: 1px solid #c4c4c4;
  border-right: none;
}
.index-averages .tab-head li:last-child {
  border-right: 1px solid #c4c4c4;
}
.index-averages .tab-head li.active,
.index-averages .tab-head li:hover {
  border: 1px solid #1f6bb8;
  background: #1f6bb8;
  border-right: none;
  color: #fff;
  cursor: pointer;
}
.index-averages .tab-body {
  display: flex;
}
.index-averages .right-list {
  flex: 1;
  display: flex;
}
.index-averages .right-list .item {
  flex: 1;
  background: #fff;
  box-shadow: -2.723px 4.193px 13px 0px rgba(0,0,0,0.1);
  margin-left: 25px;
  position: relative;
  padding: 52px 25px;
  text-align: center;
}
.index-averages .right-list .item:hover h3,
.index-averages .right-list .item:hover h4 {
  color: #1f6bb8;
}
.index-averages .right-list .item h3 {
  font-size: 50px;
  color: #000;
}
.index-averages .right-list .item h4 {
  font-size: 24px;
  color: #414141;
}
.index-averages .right-list .item .tit {
  height: 75px;
  font-size: 18px;
  color: #000;
  line-height: 1.3;
  margin-bottom: 40px;
}
.index-averages .right-list .item .icon {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  border: 10px solid #f9f3f5;
  background-color: #1f6bb8;
  border-radius: 50%;
}
.index-averages .right-list .item .icon1 {
  background-image: url("../images/icon-averages1.png");
}
.index-averages .right-list .item .icon2 {
  background-image: url("../images/icon-averages2.png");
}
.index-averages .right-list .item .icon3 {
  background-image: url("../images/icon-averages3.png");
}
.index-averages .left-chart {
  flex: 0 0 653px;
  border: 1px solid #ececec;
  border-top: 4px solid #1f6bb8;
  background-color: #fff;
  box-shadow: -2.723px 4.193px 13px 0px rgba(0,0,0,0.1);
  height: 343px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.index-averages .left-chart .tag {
  position: absolute;
  top: 20px;
  right: 3%;
  display: flex;
  height: 24px;
  font-size: 12px;
  color: #414141;
  border: 1px solid #c4c4c4;
  width: 162px;
  line-height: 22px;
}
.index-averages .left-chart .tag span {
  flex: 1;
  cursor: pointer;
  text-align: center;
}
.index-averages .left-chart .tag span.active,
.index-averages .left-chart .tag span:hover {
  background: #1f6bb8;
  color: #fff;
}
.index-averages .left-chart #index-chart {
  flex: 1;
}
.index-vr {
  padding-top: 74px;
}
.index-vr .head {
  text-align: center;
}
.index-vr .head h3 {
  font-size: 50px;
  color: #000;
  margin-bottom: 30px;
}
.index-vr .head p {
  font-size: 16px;
  color: #000;
  line-height: 26px;
}
.index-vr .body {
  margin-top: 45px;
}
.index-vr .body .img {
  position: relative;
}
.index-vr .body .img:before {
  width: 207px;
  height: 143px;
  display: inline-block;
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-image: url("../images/vr-img.png");
  position: absolute;
  left: -129px;
  bottom: -2px;
  content: '';
}
.index-vr .body .btns {
  text-align: center;
  margin-top: 30px;
}
.index-vr .body .btns .icon {
  height: 35px;
  width: 35px;
  position: relative;
  top: 10px;
}
.index-vr .body .btns .icon1 {
  background-image: url("../images/vr-icon11.png");
}
.index-vr .body .btns .icon2 {
  background-image: url("../images/vr-icon12.png");
}
.index-vr .body .btns a {
  width: 425px;
  height: 57px;
  line-height: 57px;
  border: 1px solid #a9a9a9;
  display: inline-block;
  font-size: 16px;
  color: #000;
}
.index-vr .body .btns a:first-child {
  border-right: none;
}
.index-vr .body .btns a:hover {
  background: #1f6bb8;
  color: #fff;
}
.index-vr .body .btns a:hover .icon1 {
  background-image: url("../images/vr-icon11-1.png");
}
.index-vr .body .btns a:hover .icon2 {
  background-image: url("../images/vr-icon12-1.png");
}
.index-download {
  border-bottom: 1px solid #e0e0e0;
  margin-top: 110px;
  height: 599px;
  display: flex;
}
.index-download .right-img {
  align-self: flex-end;
}
.index-download .left-con {
  padding-top: 30px;
  width: 645px;
}
.index-download .small-title {
  font-size: 14px;
  color: #666;
  margin-bottom: 26px;
}
.index-download .title {
  font-size: 50px;
  color: #000;
  line-height: 1.1;
  margin-bottom: 30px;
}
.index-download .txt {
  font-size: 16px;
  line-height: 1.3;
  color: #414141;
}
.index-download .qrcode {
  display: flex;
  margin-top: 55px;
  margin-bottom: 40px;
}
.index-download .qrcode .btns {
  margin-left: 33px;
}
.index-download .qrcode .btn {
  width: 180px;
  height: 48px;
  border: 1px solid #a9a9a9;
  display: block;
  border-radius: 3px;
  transition: 0.2s;
}
.index-download .qrcode .btn:hover {
  box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.1);
  border: 1px solid #000;
}
.index-download .qrcode .google {
  background-image: url("../images/google.png");
}
.index-download .qrcode .appStore {
  margin-bottom: 18px;
  background-image: url("../images/appStore.png");
}
.index-download .tip {
  font-size: 16px;
  color: #000;
}
.index-download .icon-scan {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  margin-right: 5px;
  background-image: url("../images/icon-scan.png");
}
.index-download .wrap {
  display: flex;
  justify-content: space-between;
  overflow: visible;
}
.index-news {
  padding-top: 74px;
  padding-bottom: 10px;
  background: #fff;
  position: relative;
}
.index-news .item {
  display: flex;
}
.index-news .item .swiper-slide {
  flex: 0 0 33.33%;
  height: 340px;
}
.index-news .slick-list {
  height: 420px;
}
.index-news .slick-dots {
  position: absolute;
  bottom: 45px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.index-news .slick-dots li {
  margin: 0 5px;
  width: 16px;
  height: 16px;
  background: #d9d9d9;
  border-radius: 8px;
  transition: 0.2s;
  cursor: pointer;
}
.index-news .slick-dots li button {
  display: none;
}
.index-news .slick-dots li.slick-active {
  background: #4c4c4c;
  width: 60px;
}
.index-news .title {
  text-align: center;
  margin-bottom: 22px;
}
.index-news .title h3 {
  font-size: 50px;
  color: #000;
}
.index-news .index-more {
  margin-bottom: 20px;
}
.index-news .tag {
  color: #666;
  font-size: 14px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.index-news .swiper-slide {
  padding: 0 54px;
  border-left: 1px solid #d8d8d8;
}
.index-news .swiper-slide:first-child {
  border-left: none;
}
.index-news .swiper-slide h3 {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.index-news .swiper-slide h3:before {
  width: 60px;
  height: 2px;
  content: "";
  display: inline-block;
  background: #1f6bb8;
  position: absolute;
  left: 0;
  bottom: 0;
}
.index-news .swiper-slide h3 a {
  font-size: 30px;
  color: #000;
  line-height: 32px;
}
.index-news .swiper-slide h3 a:hover {
  color: #1f6bb8;
}
.index-news .swiper-slide p {
  font-size: 14px;
  line-height: 24px;
  color: #414141;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.index-news .swiper-slide .btn {
  border: 1px solid #333;
  border-radius: 3px;
  box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.1);
  text-align: center;
  width: 149px;
  height: 48px;
  line-height: 48px;
  display: inline-block;
  color: #414141;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  left: 54px;
  bottom: 0;
}
.index-news .swiper-slide .btn:hover {
  border: 1px solid #1f6bb8;
  background: #1f6bb8;
  color: #fff;
}
.index-news .slick-current {
  border-left: none;
}
.index-about {
  padding: 80px 0;
  position: relative;
}
.index-about .video {
  width: 1041px;
  height: 532px;
  position: relative;
}
.index-about .video img {
  width: 100%;
  height: 100%;
}
.index-about .video-begin {
  width: 99px;
  height: 99px;
  cursor: pointer;
  background: url("../images/video-begin.png") no-repeat;
  background-size: contain;
  position: absolute;
  z-index: 9;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.index-about .content {
  width: 779px;
  height: 418px;
  position: absolute;
  top: 50%;
  left: 1000px;
  transform: translateY(-50%);
  background: #fff;
  padding: 53px 86px 0 86px;
  transition: 0.8s;
  opacity: 0;
}
.index-about .content.animated {
  left: 48%;
  opacity: 1;
}
.index-about .content .title {
  font-size: 16px;
  color: #236a86;
  text-transform: uppercase;
}
.index-about .content .title em {
  margin: 0 10px;
}
.index-about .content .title a {
  color: #1f6bb8;
}
.index-about .content .texts li {
  display: none;
}
.index-about .content .texts li.active {
  display: inline-block;
}
.index-about .content .texts li .tit {
  font-size: 50px;
  color: #000;
  margin-top: 30px;
}
.index-about .content .texts li .txt {
  font-size: 16px;
  line-height: 20px;
  height: 100px;
  color: #414141;
}
.index-about .content .texts li h3 {
  font-size: 50px;
  color: #000;
}
.index-about .content .text-tab ul {
  display: flex;
  font-size: 16;
  color: #414141;
}
.index-about .content .text-tab ul li {
  margin-right: 40px;
  text-align: center;
}
.index-about .content .text-tab ul li h3 {
  font-size: 16px;
  color: #414141;
}
.index-about .content .text-tab ul .icon {
  margin-bottom: 10px;
  width: 65px;
  height: 55px;
  display: inline-block;
}
.index-about .content .text-tab ul .icon1 {
  background-image: url("../images/indexabout-icon1.png");
}
.index-about .content .text-tab ul .icon2 {
  background-image: url("../images/indexabout-icon2.png");
}
.index-about .content .text-tab ul .icon3 {
  background-image: url("../images/indexabout-icon3.png");
}
.index-about .content .text-tab ul .icon4 {
  background-image: url("../images/indexabout-icon4.png");
}
.index-about .content .text-tab ul li:hover,
.index-about .content .text-tab ul li.active {
  cursor: pointer;
}
.index-about .content .text-tab ul li:hover h3,
.index-about .content .text-tab ul li.active h3 {
  color: #1f6bb8;
}
.index-about .content .text-tab ul li:hover .icon1,
.index-about .content .text-tab ul li.active .icon1 {
  background-image: url("../images/about-icon1-1.png");
}
.index-about .content .text-tab ul li:hover .icon2,
.index-about .content .text-tab ul li.active .icon2 {
  background-image: url("../images/about-icon2-1.png");
}
.index-about .content .text-tab ul li:hover .icon3,
.index-about .content .text-tab ul li.active .icon3 {
  background-image: url("../images/about-icon3-1.png");
}
.index-about .content .text-tab ul li:hover .icon4,
.index-about .content .text-tab ul li.active .icon4 {
  background-image: url("../images/about-icon4-1.png");
}
.footer .footer-links {
  padding: 45px 88px;
  background: url("../images/foot-bg.jpg") #fff no-repeat bottom center;
  display: flex;
}
.footer .item {
  flex: 1;
}
.footer .item .tit {
  font-size: 22px;
  font-weight: bold;
  color: #000;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.footer .item .tit:before {
  width: 60px;
  height: 2px;
  content: "";
  display: inline-block;
  background: #1f6bb8;
  position: absolute;
  left: 0;
  bottom: 0;
}
.footer .item li {
  line-height: 22px;
  margin-bottom: 8px;
}
.footer .item a,
.footer .item p {
  font-size: 16px;
  color: #3f3f3f;
}
.footer .item a:hover {
  color: #1f6bb8;
}
.footer .last {
  flex: 0 0 40%;
  display: flex;
  justify-content: space-between;
  border-left: 1px solid #999;
  padding-left: 2%;
}
.footer .last .item {
  flex: 0 0 218px;
}
.footer .last .qrcode ul {
  display: flex;
  text-align: center;
}
.footer .last .qrcode ul li {
  margin-left: 30px;
}
.footer .last .qrcode ul img {
  width: 160px;
  height: 160px;
}
.footer .last .qrcode ul h3 {
  font-size: 16px;
  color: #3f3f3f;
  margin-top: 20px;
}
.footer .foot-copyright {
  padding: 0 90px;
  background: #282828;
  height: 74px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #aaa;
}
.footer .foot-copyright a {
  color: #fff;
}
.footer .foot-copyright .btn {
  width: 150px;
  height: 33px;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
  border-radius: 5px;
  margin-left: 15px;
}
.footer .foot-copyright .right-btn {
  display: flex;
  align-items: center;
}
.footer .foot-copyright .google {
  background-image: url("../images/google.png");
}
.footer .foot-copyright .appStore {
  background-image: url("../images/appStore.png");
}
.aside-right {
  position: fixed;
  top: 45%;
  right: 0;
  width: 44px;
  z-index: 995;
  background: #000;
  transform: translateY(-50%);
}
.aside-right .sub {
  position: absolute;
  visibility: hidden;
  transform: translateX(-120%);
  box-shadow: 0 0 20px rgba(31,107,184,0.1);
  transition: 0.4s;
  left: 0;
  opacity: 0;
  top: 0;
}
.aside-right .subList {
  top: 0;
  padding-left: 27px;
  padding-bottom: 30px;
  transform: translateX(-110%);
}
.aside-right .subList .head {
  padding-top: 15px;
  line-height: 50px;
  font-size: 18px;
  color: #494949;
  border-bottom: 1px solid #dfdfdf;
}
.aside-right .subList .head span {
  color: #8a8a8a;
  margin-left: 10px;
}
.aside-right .subList .body .img {
  margin-right: 15px;
  flex: 0 0 94px;
}
.aside-right .subList .body .img img {
  width: 94px;
  height: 60px;
}
.aside-right .subList .body .text {
  flex: 0 0 110px;
}
.aside-right .subList .body .text h3 {
  font-size: 14px;
  line-height: 16px;
  height: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.aside-right .subList .body .text h3 a {
  color: #414141;
}
.aside-right .subList .body .text h3 a:hover {
  color: #1f6bb8;
}
.aside-right .subList .body .text h4 {
  font-size: 12px;
  color: #8a8a8a;
  position: relative;
}
.aside-right .subList .body .text h4 .icon {
  position: absolute;
  top: -3px;
  right: 0;
  width: 18px;
  height: 18px;
  cursor: pointer;
  background-image: url("../images/icon-del.png");
}
.aside-right .subList .body .text h4 .icon:hover {
  background-size: contain;
  background-image: url("../images/icon-del2.png");
}
.aside-right .subList ul {
  height: 275px;
  padding-top: 28px;
  overflow-y: auto;
}
.aside-right .subList li {
  line-height: normal;
  display: flex;
  margin-bottom: 23px;
}
.aside-right .subList li a {
  display: flex;
}
.aside-right .subList .btn {
  width: 204px;
  height: 40px;
  background: #1f6bb8;
  color: #fff;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  border-radius: 3px;
  font-size: 14px;
}
.aside-right >ul>li {
  height: 50px;
  line-height: 50px;
  position: relative;
}
.aside-right >ul>li >a {
  display: block;
  height: 50px;
}
.aside-right .icon1 {
  background-image: url("../images/aside-icon1.png");
}
.aside-right .icon2 {
  background-image: url("../images/aside-icon2.png");
}
.aside-right .icon3 {
  background-image: url("../images/aside-icon3.png");
  position: relative;
}
.aside-right .icon4 {
  background-image: url("../images/aside-icon4.png");
}
.aside-right .icon5 {
  background-image: url("../images/aside-icon5.png");
}
.aside-right .icon6 {
  background-image: url("../images/aside-icon7.png");
}
.aside-right .icon7 {
  background-image: url("../images/aside-icon6.png");
}
.aside-right .subList {
  position: absolute;
  right: 44px;
  width: 267px;
  background: #fff;
}
.aside-right li.is-new .icon3 {
  position: relative;
}
.aside-right li.is-new .icon3:before {
  content: '';
  right: 8px;
  top: 8px;
  border-radius: 100%;
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #f00;
  position: absolute;
}
.aside-right li:hover,
.aside-right li.active {
  background: #fff;
}
.aside-right li:hover .sub,
.aside-right li.active .sub {
  opacity: 1;
  visibility: visible;
  transform: translateX(-100%);
}
.aside-right li:hover .icon1,
.aside-right li.active .icon1 {
  background-image: url("../images/aside-icon1-1.png");
}
.aside-right li:hover .icon2,
.aside-right li.active .icon2 {
  background-image: url("../images/aside-icon2-1.png");
}
.aside-right li:hover .icon3,
.aside-right li.active .icon3 {
  background-image: url("../images/aside-icon3-1.png");
}
.aside-right li:hover .icon4,
.aside-right li.active .icon4 {
  background-image: url("../images/aside-icon4-1.png");
}
.aside-right li:hover .icon5,
.aside-right li.active .icon5 {
  background-image: url("../images/aside-icon5-1.png");
}
.aside-right li:hover .icon6,
.aside-right li.active .icon6 {
  background-image: url("../images/aside-icon7-1.png");
}
.aside-right li:hover .icon7,
.aside-right li.active .icon7 {
  background-image: url("../images/aside-icon6-1.png");
}
.aside-right .attention {
  width: 117px;
  height: 50px;
  background: #fff;
  line-height: 50px;
  display: inline-block;
  font-size: 16px;
  color: #414141;
  position: absolute;
  text-align: center;
}
.aside-right .wx {
  width: 105px;
  height: 105px;
  background: #fff;
  padding: 10px;
}
.aside-right .subscriptions {
  width: 267px;
  height: 202px;
  background: #fff;
  padding: 27px;
}
.aside-right .subscriptions .hd {
  font-size: 18px;
  color: #494949;
  padding-bottom: 10px;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 16px;
  line-height: 1.2;
}
.aside-right .subscriptions .bd input {
  width: 100%;
  line-height: 40px;
  height: 40px;
  display: block;
  font-size: 14px;
  border-radius: 3px;
}
.aside-right .subscriptions .bd input.text {
  border: 1px solid #dfdfdf;
  color: #999;
  padding: 0 10px;
}
.aside-right .subscriptions .bd .submit {
  background: #1f6bb8;
  color: #fff;
  margin-top: 10px;
  cursor: pointer;
}
.aside-right .subscriptions .bd .submit:hover {
  opacity: 0.9;
}
.md-content {
  background: #fff;
  padding: 80px 0;
  min-height: 600px;
}
.md-content .gb-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
}
.md-content .gb-title .icon-home {
  width: 16px;
  height: 13px;
  background-image: url("../images/home-icon1.png");
  margin-right: 5px;
  top: 2px;
}
.md-content .gb-title a {
  color: #5a5a5a;
  margin: 0 5px;
  font-size: 14px;
}
.md-content .gb-title a:first-child {
  margin-left: 0;
}
.md-content .gb-title a:hover {
  color: #1f6bb8;
}
.md-content .gb-title.line {
  border-bottom: 1px solid #919191;
  padding-bottom: 15px;
}
.md-content .gb-title h3 {
  font-size: 30px;
  color: #000;
}
.md-content .gb-title .num {
  font-size: 16px;
  color: #666;
}
.md-contact {
  background: #fff;
  padding-top: 85px;
}
.md-contact .wrap {
  padding-top: 70px;
}
.md-contact .map-box {
  height: 630px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.md-contact .text {
  font-size: 16px;
  line-height: 1.8;
  color: #666;
  margin-bottom: 65px;
}
.md-contact .list .icon {
  width: 55px;
  height: 55px;
  border: 1px solid #ccc;
  border-radius: 50%;
  position: absolute;
  left: 0;
}
.md-contact .list .item {
  width: 25%;
  float: left;
  position: relative;
  padding-left: 70px;
  min-height: 55px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.md-contact .list .item h3 {
  font-size: 20px;
  color: #333;
}
.md-contact .list .item:last-child {
  width: auto;
}
.md-contact .social-platform {
  margin-top: 20px;
}
.md-contact .social-platform dl {
  display: flex;
  font-size: 16px;
  color: #666;
  align-items: center;
}
.md-contact .social-platform dl dt {
  margin-right: 5px;
}
.md-contact .social-platform a {
  width: 55px;
  height: 55px;
  display: inline-block;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #a7b4c0;
  transition: background-color 0.3s;
}
.md-contact .social-platform a.icon-youtb {
  background-image: url("../images/ic-youtube.png");
}
.md-contact .social-platform a.icon-in {
  background-image: url("../images/icon-in.png");
}
.md-contact .social-platform a.icon-facebook {
  background-image: url("../images/icon-facebook.png");
}
.md-contact .social-platform a.icon-twitter {
  background-image: url("../images/icon-twitter.png");
}
.md-contact .social-platform a:hover {
  background-color: #1f6bb8;
}
.md-contact .service-form {
  margin-top: 40px;
}
.md-careers ul {
  display: flex;
  flex-wrap: wrap;
}
.md-careers li {
  height: 410px;
  flex: 0 0 33.333%;
  border: 1px solid #e0e0e0;
  margin-bottom: 49px;
  padding: 35px;
  margin-left: -1px;
  transition: box-shadow 0.2s;
}
.md-careers li:hover {
  background: #1f6bb8;
  box-shadow: 0px 0px 20px 0px rgba(31,107,184,0.3);
}
.md-careers li:hover .title,
.md-careers li:hover .text,
.md-careers li:hover .tags,
.md-careers li:hover .btn {
  color: #fff;
}
.md-careers li:hover .tags .icon1 {
  background-image: url("../images/careers-icon1-1.png");
}
.md-careers li:hover .tags .icon2 {
  background-image: url("../images/careers-icon2-1.png");
}
.md-careers li a {
  display: block;
}
.md-careers .title,
.md-careers-post .head .title {
  font-size: 30px;
  color: #000;
  margin-bottom: 15px;
}
.md-careers .tags,
.md-careers-post .head .tags {
  font-size: 16px;
  color: #999;
  padding-bottom: 30px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 25px;
}
.md-careers .tags .icon,
.md-careers-post .head .tags .icon {
  width: 25px;
  height: 22px;
  top: 4px;
  margin-right: 3px;
}
.md-careers .tags .icon1,
.md-careers-post .head .tags .icon1 {
  background-image: url("../images/careers-icon1.png");
}
.md-careers .tags .icon2,
.md-careers-post .head .tags .icon2 {
  margin-left: 20px;
  background-image: url("../images/careers-icon2.png");
}
.md-careers .text {
  font-size: 16px;
  line-height: 1.8;
  color: #666;
  height: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.md-careers .btn {
  font-size: 18px;
  color: #1f6bb8;
}
.md-careers-post .head {
  display: flex;
  justify-content: space-between;
}
.md-careers-post .head .btn {
  width: 300px;
  height: 60px;
  line-height: 60px;
  background: #1f6bb8;
  transition: 0.2s;
  text-align: center;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}
.md-careers-post .head .btn:hover {
  background: #237ad1;
}
.md-careers-post .head .tags {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}
.md-careers-post .body {
  padding-top: 30px;
  padding-bottom: 105px;
  font-size: 16px;
  line-height: 1.8;
  color: #828282;
}
.gb-head-menu {
  border-bottom: 1px solid #b3b3b3;
}
.gb-head-menu .line {
  width: 11px;
  height: 26px;
  background: url("../images/line-icon.png") no-repeat center;
  display: inline-block;
  position: relative;
  top: 2px;
  margin: 0 25px;
}
.gb-head-menu a {
  color: #000;
  font-size: 30px;
  position: relative;
  padding-bottom: 14px;
  display: inline-block;
}
.gb-head-menu a:first-child {
  padding-left: 0;
}
.gb-head-menu a.active,
.gb-head-menu a:hover {
  color: #1f6bb8;
}
.gb-head-menu a.active:after,
.gb-head-menu a:hover:after {
  content: '';
  display: inline-block;
  height: 1px;
  background: #1f6bb8;
  left: 0;
  right: 0;
  bottom: -1px;
  position: absolute;
}
.fixed-wx {
  background: #fff;
  width: 150px;
  height: 150px;
  padding: 15px;
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  margin-top: -75px;
  margin-left: -75px;
}
.fixed-wx img {
  width: 100%;
  height: 100%;
}
.md-team .body {
  padding: 40px 0;
}
.md-team .body ul {
  width: 108%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.md-team .body ul .img,
.md-team-details .item .img {
  position: relative;
}
.md-team .body ul .img .qrcode,
.md-team-details .item .img .qrcode {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0,0,0,0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}
.md-team .body ul .img .qrcode .close,
.md-team-details .item .img .qrcode .close {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 10px;
  right: 10px;
  background: url("../images/login-close.png");
  z-index: 999;
  background-repeat: no-repeat;
}
.md-team .body ul .img .qrcode img,
.md-team-details .item .img .qrcode img {
  max-width: 80%;
  height: auto;
}
.md-team .body ul .img .icon,
.md-team-details .item .img .icon {
  padding-right: 0;
}
.md-team .body ul .img .icon-phone,
.md-team-details .item .img .icon-phone,
.md-team .body ul .img .icon-wx,
.md-team-details .item .img .icon-wx {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 24px;
  height: 24px;
  display: inline-block;
  background-size: contain;
  background-image: url("../images/team-img1.png");
}
.md-team .body ul .img .icon-wx,
.md-team-details .item .img .icon-wx {
  width: 30px;
  height: 30px;
  left: 45px;
  bottom: 7px;
  background-image: url("../images/team-img2.png");
}
.md-team .body ul li,
.md-team-details .item {
  flex: 0 0 252px;
  margin-right: 50px;
  margin-bottom: 50px;
  position: relative;
}
.md-team .body ul li.is-best:before,
.md-team-details .item.is-best:before {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-image: url("../images/best.png");
  content: '';
  right: 0;
  top: 0;
  position: absolute;
  z-index: 9;
}
.md-team .body ul li h3,
.md-team-details .item h3 {
  font-size: 28px;
  margin-top: 25px;
  margin-bottom: 10px;
}
.md-team .body ul li h3 a,
.md-team-details .item h3 a {
  color: #222;
}
.md-team .body ul li h3 a:hover,
.md-team-details .item h3 a:hover {
  color: #1f6bb8;
}
.md-team .body ul li h4,
.md-team-details .item h4 {
  font-size: 16px;
  color: #838383;
}
.md-team .body ul li .tags,
.md-team-details .item .tags {
  margin-top: 5px;
}
.md-team .body ul li .icon,
.md-team-details .item .icon {
  font-size: 16px;
  color: #838383;
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 20px;
}
.md-team .body ul li .icon-cn,
.md-team-details .item .icon-cn {
  background-size: 16px auto;
  background-image: url("../images/cn.png");
}
.md-team .body ul li .icon-usa,
.md-team-details .item .icon-usa {
  margin-left: 10px;
  background-size: 16px auto;
  background-image: url("../images/us.png");
}
.md-team .body .img {
  width: 100%;
  height: 338px;
  overflow: hidden;
}
.md-team .gb-pager {
  padding-top: 40px;
}
.md-team-details {
  height: auto;
  overflow: hidden;
}
.md-team-details .item {
  width: 252px;
  position: relative;
}
.md-team-details .item .contact {
  margin-top: 12px;
  font-size: 16px;
  color: #606060;
  line-height: 32px;
}
.md-team-details .right-con {
  margin-left: 358px;
}
.md-team-details .right-con .title {
  font-size: 28px;
  margin-bottom: 15px;
  color: #222;
}
.md-team-details .right-con .text {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
  color: #838383;
}
.md-team-details .form .submit,
.service-form .form-wrap .submit {
  margin-top: 20px;
  border-radius: 3px;
  width: 300px;
  height: 60px;
  line-height: 60px;
  background: #1f6bb8;
  transition: 0.2s;
  text-align: center;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}
.md-team-details .form .submit:hover,
.service-form .form-wrap .submit:hover {
  background: #237ad1;
}
.md-team-details .form li {
  margin-bottom: 15px;
  display: flex;
}
.md-team-details .form li .input {
  flex: 1;
  padding: 0 15px;
  font-size: 16px;
  transition: 0.3s;
}
.md-team-details .form li .input:focus {
  box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.1);
  border-color: #000;
}
.md-team-details .form li textarea.input {
  height: 250px;
}
.md-team-details .form li select.input {
  flex: 0 0 120px;
  border-radius: 3px 0 0 3px !important;
}
.md-team-details .form li.select {
  height: 50px;
  line-height: 48px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.md-team-details .form li.select .input {
  border: none;
  line-height: 48px;
  height: 48px;
}
.md-team-details .form li.select .input:focus {
  box-shadow: none;
}
.md-team-details .form li.select input.input {
  border-left: 1px solid #ccc;
  border-radius: 0;
}
.md-team-details .form .row {
  justify-content: space-between;
}
.md-team-details .form .row .input {
  flex: 0 0 49%;
}
.md-team-details .form .input {
  height: 50px;
  line-height: 48px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.md-team-details .left-con {
  float: left;
}
.md-team-details .left-con .contact .icon {
  padding-left: 0;
  width: 25px;
  height: 20px;
  top: 5px;
}
.md-team-details .left-con .contact .icon-phone {
  background-image: url("../images/team-phone.png");
}
.md-team-details .left-con .contact .icon-tel {
  background-image: url("../images/team-tell.png");
}
.md-team-details .left-con .contact .icon-mail {
  background-image: url("../images/team-mail.png");
}
.gb-meun {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}
.gb-meun .right-sear {
  width: 515px;
  border-width: 1px;
  border-color: #a9a9a9;
  border-style: solid;
  border-radius: 3px;
  box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.1);
  height: 46px;
  border: 1px solid #000;
  position: relative;
  top: -5px;
}
.gb-meun .right-sear .select-list {
  position: absolute;
  left: 120px;
  right: 0;
  top: 100%;
  margin-top: 1px;
  padding: 10px 0;
  background: #fff;
  overflow-y: auto;
  font-size: 14px;
  transition: 0.2s;
  z-index: 99;
  max-height: 200px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
.gb-meun .right-sear .select-list li {
  line-height: 37px;
  color: #5a5a5a;
  padding: 0 23px;
  display: block;
  cursor: pointer;
}
.gb-meun .right-sear .select-list li.active,
.gb-meun .right-sear .select-list li:hover {
  background: #1f6bb8;
  color: #fff;
}
.gb-meun .right-sear form {
  display: flex;
  align-items: center;
  height: 46px;
}
.gb-meun .right-sear .submit {
  flex: 0 0 60px;
  cursor: pointer;
  background-image: url("../images/sear-btn.png");
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  top: -2px;
}
.gb-meun .right-sear .select {
  border-right: 1px solid #000;
  border-radius: 0;
  margin-left: 10px;
}
.gb-meun .right-sear .select select {
  width: 114px;
  background: #fff;
  font-size: 16px;
  color: #494949;
  height: 35px;
}
.gb-meun .right-sear input {
  height: 35px;
  line-height: 35px;
  flex: 1;
  font-size: 14px;
  color: #494949;
  padding: 0 20px;
}
.gb-meun .right-con {
  display: flex;
  align-content: center;
  justify-content: center;
}
.gb-meun .right-con .right-sear {
  width: 465px;
  overflow: hidden;
}
.gb-meun .right-con .select-yaer {
  height: 44px;
  display: flex;
  margin-left: 10px;
  align-content: center;
  position: relative;
}
.gb-meun .right-con .select-yaer .select-arr {
  position: absolute;
  width: 15px;
  height: 9px;
  top: 17px;
  right: 10px;
  background-repeat: no-repeat;
  background-image: url("../images/select-arr.png");
}
.gb-meun .right-con .select-yaer select {
  flex: 0 0 120%;
  font-size: 20px;
  color: #000;
}
.gb-meun .right-con .goToYears {
  margin-left: 20px;
  width: 180px;
  height: 48px;
  font-size: 18px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #1f6bb8;
  position: relative;
  top: -5px;
}
.gb-meun .right-con .goToYears:hover {
  background: #237ad1;
  cursor: pointer;
}
.clearfix,
.md-reference .body,
.md-reference-list .sort ul,
.md-dubai .body,
.md-about .our,
.md-ucenter .right-con-browse .body a,
.md-ucenter .bottom-con,
.md-ucenter .top-con {
  display: block;
  zoom: 1;
}
.clearfix:after {
  content: "";
  display: block;
  font-size: 0;
  height: 0;
  clear: both;
  visibility: hidden;
}
.md-reference-list .sort {
  border-bottom: 1px solid #eee;
  margin-top: 25px;
}
.md-reference-list .sort ul li {
  float: left;
  line-height: 50px;
  font-size: 14px;
  margin-right: 32px;
  position: relative;
}
.md-reference-list .sort ul li a {
  color: #5a5a5a;
  display: block;
}
.md-reference-list .sort .icon-vr {
  width: 24px;
  height: 15px;
  display: inline-block;
  top: 3px;
  margin-left: 6px;
  background-image: url("../images/icon-vr2.png");
}
.md-reference-list .sort .icon-arr {
  width: 7px;
  height: 10px;
  display: inline-block;
  top: 1px;
  margin-left: 6px;
  background-image: url("../images/icon-sort-arr.png");
}
.md-reference-list .sort li.active a,
.md-reference-list .sort li:hover a {
  color: #1f6bb8;
}
.md-reference-list .sort li.active a:before,
.md-reference-list .sort li:hover a:before {
  content: '';
  display: inline-block;
  height: 1px;
  background: #1f6bb8;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
}
.md-reference-list .sort li.active .icon-arr,
.md-reference-list .sort li:hover .icon-arr {
  background-image: url("../images/icon-sort-arr-1.png");
}
.md-reference-list .sort li.active .icon-vr,
.md-reference-list .sort li:hover .icon-vr {
  background-image: url("../images/icon-vr2-1.png");
}
.md-reference-list .select-filter ul {
  display: flex;
  padding: 30px;
}
.md-reference-list .select-filter li {
  flex: 1;
  margin: 0 15px;
}
.md-reference-list .select-filter li select {
  padding: 0 10px;
  font-size: 18px;
  width: 100%;
  height: 50px;
  border: 1px solid #eee;
  border-radius: 3px;
  color: #000;
}
.md-reference-list .select-filter .search-btn {
  width: 180px;
  display: block;
  background: #1f6bb8;
  color: #fff;
  line-height: 50px;
  font-size: 18px;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
}
.md-reference-list .select-filter .search-btn:hover {
  background: #237ad1;
}
.md-reference-list .filter .filter-items {
  position: relative;
  padding-left: 140px;
  min-height: 45px;
  max-height: 76px;
  overflow: hidden;
  border-bottom: 1px solid #e4e4e4;
}
.md-reference-list .filter .filter-type {
  line-height: 45px;
  background: #f8f6f7;
  width: 140px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  font-size: 14px;
  color: #000;
  padding: 0 15px;
  font-weight: bold;
}
.md-reference-list .filter .filter-item {
  position: relative;
  padding: 10px 25px;
  font-size: 14px;
  padding-right: 100px;
}
.md-reference-list .filter .filter-item .input {
  display: inline-block;
}
.md-reference-list .filter .filter-item .btn {
  color: #5a5a5a;
  font-size: 14px;
  width: 75px;
  height: 26px;
  line-height: 24px;
  padding: 0;
  border: 1px solid #d1d1d1;
  text-align: center;
  display: inline-block;
  position: relative;
  top: 2px;
  margin-left: 10px;
  background-repeat: no-repeat;
  background-image: url("../images/icon-confirm.png");
  background-position: 95% 55%;
}
.md-reference-list .filter .filter-item .btn:hover {
  background-image: url("../images/icon-confirm2.png");
  border: 1px solid #1f6bb8;
}
.md-reference-list .filter .filter-item .more {
  width: 67px;
  height: 26px;
  border: 1px solid #5a5a5a;
  font-size: 14px;
  color: #5a5a5a;
  line-height: 24px;
  padding-left: 12px;
  position: absolute;
  padding: 0;
  right: 0;
  top: 5px;
}
.md-reference-list .filter .filter-item .more .icon-arrdown {
  top: -1px;
  margin-left: 5px;
  width: 7px;
  height: 4px;
  background-image: url("../images/arr-down4.png");
}
.md-reference-list .filter .filter-item .to {
  font-size: 16px;
  color: #000;
  padding: 0 5px;
}
.md-reference-list .filter .filter-item .text {
  width: 144px;
  height: 26px;
  border: 1px solid #d1d1d1;
  border-radius: 3px;
  padding: 0 5px;
  font-size: 12px;
}
.md-reference-list .filter .filter-item a {
  color: #5a5a5a;
  margin-right: 20px;
  padding: 5px;
  line-height: 1.4;
  display: inline-block;
}
.md-reference-list .filter .filter-item a:hover {
  color: #1f6bb8;
}
.md-reference-list .filter .filter-item a.active {
  background: #1f6bb8;
  border-radius: 3px;
  color: #fff;
}
.md-reference-list .filter-selected {
  margin-top: 20px;
}
.md-reference-list .filter-selected a {
  height: 25px;
  border: 1px solid #dfdfdf;
  padding: 0 10px;
  line-height: 23px;
  font-size: 12px;
  color: #5a5a5a;
  display: inline-block;
  margin-right: 5px;
}
.md-reference-list .filter-selected a .icon-del {
  width: 7px;
  height: 7px;
  background-image: url("../images/icon-del4.png");
  margin-left: 10px;
}
.md-reference-list .body {
  margin-top: 30px;
}
.md-reference .item {
  width: 32%;
  float: left;
  margin-right: 2%;
  margin-bottom: 2%;
  border: 1px solid #f2f2f2;
}
.md-reference .item:nth-child(3n) {
  margin-right: 0;
}
.md-reference .sear-box,
.md-dubai .sear-box {
  display: flex;
  border: 1px solid #000;
  margin: 30px 0;
  border-width: 1px;
  border-color: #dfdfdf;
  border-style: solid;
  border-radius: 3px;
  background-color: #fff;
  height: 46px;
}
.md-reference .sear-box input.text,
.md-dubai .sear-box input.text {
  flex: 1;
  font-size: 16px;
  color: #999;
  padding: 0 15px;
}
.md-reference .sear-box input.submit,
.md-dubai .sear-box input.submit {
  flex: 0 0 60px;
  cursor: pointer;
  background-image: url("../images/sear-btn.png");
  background-repeat: no-repeat;
  background-position: center;
}
.error-modal,
.success-modal {
  width: 360px;
  height: 79px;
  background: #eef6f9;
  align-items: center;
  overflow: hidden;
  display: none;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
}
.error-modal .close,
.success-modal .close {
  width: 22px;
  height: 22px;
  display: inline-block;
  background-image: url("../images/icon-close-5.png");
  background-repeat: no-repeat;
  background-color: #000;
  background-position: center;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
}
.error-modal .icon-err,
.success-modal .icon-err {
  width: 80px;
  height: 79px;
  float: left;
  background-image: url("../images/icon-error2.png");
  background-position: center;
  background-repeat: no-repeat;
}
.error-modal .icon-success,
.success-modal .icon-success {
  width: 80px;
  height: 79px;
  float: left;
  background-image: url("../images/icon-success2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}
.error-modal .text,
.success-modal .text {
  float: left;
  padding-top: 15px;
  width: 250px;
  font-size: 14px;
  line-height: 1.6;
  color: #000;
}
.error-modal .text a,
.success-modal .text a {
  color: #d72638;
}
.success-modal .text a {
  color: #008000;
}
.gb-banner {
  height: 310px;
  position: relative;
  background-size: cover;
  background-position: center;
}
.gb-banner .publish {
  display: inline-block;
  position: absolute;
  right: 10px;
  bottom: -40px;
  width: 241px;
  height: 30px;
  background: #424447;
  border-radius: 15px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
.gb-banner .publish .icon-publish {
  background-image: url("../images/icon-home1.png");
  width: 17px;
  height: 16px;
  position: relative;
  top: -1px;
  margin-right: 10px;
}
.gb-banner .publish:hover {
  background: #1f6bb8;
}
.gb-banner .icon-home {
  width: 16px;
  height: 13px;
  background-image: url("../images/home-icon1.png");
  margin-right: 5px;
  top: 2px;
}
.gb-banner .nav {
  background: #fff;
  height: 72px;
  width: 1620px;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  position: absolute;
  font-size: 14px;
  align-items: center;
  display: flex;
  padding: 0 80px;
  color: #5a5a5a;
  justify-content: space-between;
}
.gb-banner .nav .right-btn .icon {
  width: 18px;
  height: 18px;
  top: 4px;
  margin-right: 5px;
}
.gb-banner .nav .right-btn .icon-heart {
  background-image: url("../images/house-heart.png");
  background-repeat: no-repeat;
  background-position: center;
}
.gb-banner .nav .right-btn .icon-add {
  background-image: url("../images/house-add.png");
  background-repeat: no-repeat;
  background-position: center;
}
.gb-banner .nav .right-btn a {
  width: 140px;
  height: 32px;
  border: 1px solid #cacaca;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  color: #000;
  border-radius: 3px;
}
.gb-banner .nav .right-btn a.active,
.gb-banner .nav .right-btn a:hover {
  border: 1px solid transparent;
  background: #1f6bb8;
  color: #fff;
}
.gb-banner .nav .right-btn a.active .icon-heart,
.gb-banner .nav .right-btn a:hover .icon-heart {
  background-image: url("../images/house-heart-1.png");
  background-repeat: no-repeat;
  background-position: center;
}
.gb-banner .nav .right-btn a.active .icon-add,
.gb-banner .nav .right-btn a:hover .icon-add {
  background-image: url("../images/house-add-1.png");
  background-repeat: no-repeat;
  background-position: center;
}
.gb-banner .nav .right-con .checkbox {
  color: #000;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
}
.gb-banner .nav .right-con .checkbox .icon {
  width: 60px;
  height: 30px;
  top: 12px;
  margin-left: 10px;
  display: inline-block;
  background-image: url("../images/icon-open.png");
}
.gb-banner .nav .right-con .checkbox.active .icon {
  background-image: url("../images/icon-open1.png");
}
.gb-banner .nav a {
  color: #5a5a5a;
  margin: 0 10px;
}
.gb-banner .nav a:first-child {
  margin-left: 0;
}
.gb-banner .nav a:hover {
  color: #1f6bb8;
}
.gb-banner .nav .back {
  font-size: 20px;
  color: #333;
  position: absolute;
  left: 0;
  top: 0;
  width: 73px;
  height: 73px;
  display: inline-block;
  transition: 0.2s;
  background-image: url("../images/icon-big-back.png");
}
.gb-banner .nav .back:hover {
  background-image: url("../images/icon-big-back2.png");
}
.gb-banner .title {
  font-size: 50px;
  color: #fff;
  position: absolute;
  width: 1460px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 124px;
  z-index: 99;
}
.gb-banner .title span {
  position: relative;
  padding-bottom: 30px;
  font-weight: bold;
  text-transform: uppercase;
}
.gb-banner .title span:before {
  content: '';
  height: 5px;
  background: #fff;
  display: inline-block;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100px;
  position: absolute;
}
.md-service .title {
  font-size: 30px;
  color: #000;
  margin-bottom: 100px;
}
.md-service li {
  height: auto;
  margin-bottom: 128px;
  overflow: hidden;
  position: relative;
}
.md-service li >div {
  position: relative;
  z-index: 9;
}
.md-service li .texts {
  width: 634px;
  float: left;
  padding-top: 140px;
  padding-right: 20px;
}
.md-service li .texts .tit {
  font-size: 68px;
  color: #000;
  line-height: 1.1;
  margin-bottom: 10px;
}
.md-service li .texts .txt {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  height: 90px;
}
.md-service li:nth-child(2n) .img {
  float: left;
  margin-left: 0;
}
.md-service li:nth-child(2n) .img img {
  width: 734px;
}
.md-service li:nth-child(2n) .texts {
  width: auto;
  float: none;
  padding-left: 90px;
  margin-left: 734px;
  padding-right: 0;
  padding-top: 60px;
}
.md-service li:nth-child(2n):before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 0;
  height: 550px;
  background: #fafafa;
  right: -1000px;
}
.md-service li .img {
  margin-left: 634px;
}
.md-service li .imgs {
  display: flex;
}
.md-service li .imgs .item {
  flex: 1;
  margin-right: 20px;
}
.md-service li .imgs .item img {
  width: 100%;
  height: 240px;
}
.md-service li .imgs .item:last-child {
  margin-right: 0;
}
.service-form {
  margin-top: 80px;
}
.service-form .form-title {
  font-size: 50px;
  color: #000;
  text-align: center;
}
.service-form .form-wrap ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.service-form .form-wrap ul li {
  display: flex;
  flex: 0 0 32%;
  height: 50px;
  border: 1px solid #ccc;
  line-height: 48px;
  margin-top: 20px;
}
.service-form .form-wrap ul li .tit {
  flex: 0 0 110px;
  border-right: 1px solid #ccc;
  font-size: 16px;
  color: #999;
  padding: 0 10px;
}
.service-form .form-wrap ul li textarea.input {
  padding: 10px;
  font-size: 16px;
}
.service-form .form-wrap ul li .input {
  flex: 1;
  display: flex;
}
.service-form .form-wrap ul li .input input {
  padding: 0 10px;
  flex: 1;
  font-size: 16px;
}
.service-form .form-wrap ul select {
  font-size: 16px;
  color: #999;
}
.service-form .form-wrap ul li.row {
  flex: 1;
  height: 250px;
}
.service-form .form-wrap .submit {
  display: block;
  margin: 0 auto;
  margin-top: 60px;
}
.md-developers ul {
  display: flex;
  flex-wrap: wrap;
}
.md-developers li {
  height: 235px;
  flex: 0 0 33.3333%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.4s;
}
.md-developers li:hover {
  box-shadow: 0 3px 15px rgba(0,0,0,0.5);
  z-index: 9;
}
.md-developers li:hover .hide {
  opacity: 1;
  visibility: visible;
}
.md-developers li:nth-child(2n) {
  background: #f9f9f9;
}
.md-developers li .black-pic img {
  width: 100px;
  height: 100px;
}
.md-developers li .hide {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  padding: 30px;
  padding-bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
.md-developers li .hide .color-pic {
  flex: 0 0 100px;
  height: 100px;
  margin-right: 16px;
  position: relative;
  top: 6px;
}
.md-developers li .hide .color-pic img {
  width: 100%;
  height: 100%;
}
.md-developers li .hide .more {
  font-size: 16px;
  text-align: center;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
}
.md-developers li .hide .more a {
  color: #000;
}
.md-developers li .hide .more a:hover {
  color: #1f6bb8;
  text-decoration: underline;
}
.md-developers li .hide .text {
  display: flex;
}
.md-developers li .hide .txt {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
}
.gb-pager {
  display: flex;
  justify-content: center;
  padding: 80px 0;
}
.gb-pager a {
  display: inline-block;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 18px;
  color: #000;
  border: 1px solid #ccc;
  margin: 0 3px;
}
.gb-pager a:hover,
.gb-pager a.active {
  border: 1px solid #1f6bb8;
  background: #1f6bb8;
  color: #fff;
}
.gb-pager span {
  display: inline-block;
  width: 48px;
  text-align: center;
  line-height: 48px;
  font-size: 18px;
  color: #000;
}
.gb-pager .page-btn {
  width: 126px;
}
.md-news-post .head {
  padding-bottom: 40px;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
}
.md-news-post .head .title {
  font-size: 50px;
  color: #000;
  line-height: 1.2;
}
.md-news-post .head .tags {
  color: #666;
  font-size: 14px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
}
.md-news-post .head .tags .line {
  margin: 0 20px;
  color: #e5e5e5;
}
.md-news-post .head .tags .icon {
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
}
.md-news-post .head .tags .icon1 {
  background-image: url("../images/news-icon1.png");
}
.md-news-post .head .tags .icon2 {
  background-image: url("../images/news-icon2.png");
}
.md-news-post .head .tags .icon3 {
  background-image: url("../images/news-icon3.png");
}
.md-news-post .head .tags .icon4 {
  background-image: url("../images/news-icon4.png");
}
.md-news-post .head .tags .icon5 {
  background-image: url("../images/news-icon5.png");
}
.md-news-post .body {
  padding: 70px 0;
  font-size: 16px;
  line-height: 1.8;
  color: #414141;
  height: auto;
  overflow: hidden;
}
.md-news-post .body .left-text {
  width: 1050px;
  float: left;
}
.md-news-post .body .right-list {
  margin-left: 1150px;
}
.md-news-post .body .right-list li {
  padding: 37px 0;
  border-bottom: 1px solid #ccc;
}
.md-news-post .body .right-list li:first-child {
  padding-top: 0;
}
.md-news-post .body .right-list li .date {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}
.md-news-post .body .right-list li .title a {
  font-size: 24px;
  line-height: 1.4;
  color: #000;
}
.md-news-post .body .right-list li .title a:hover {
  color: #1f6bb8;
}
.md-news li {
  height: auto;
  padding: 50px 0;
  border-bottom: 1px solid #e4e4ea;
}
.md-news li .img {
  width: 400px;
  height: 225px;
  float: left;
}
.md-news li .img a {
  display: block;
}
.md-news li .img img {
  width: 100%;
  height: 100%;
}
.md-news li .text {
  margin-left: 437px;
}
.md-news li .date {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}
.md-news li .title {
  font-size: 30px;
  color: #000;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 10px;
}
.md-news li .title:before {
  width: 60px;
  height: 2px;
  background: #1f6bb8;
  content: '';
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
}
.md-news li .txt {
  font-size: 14px;
  color: #414141;
  line-height: 1.6;
  height: 65px;
  margin-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.md-news li .btn {
  border-width: 1px;
  border-color: #333;
  border-style: solid;
  border-radius: 3px;
  box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.1);
  width: 147px;
  height: 46px;
  text-align: center;
  line-height: 44px;
  font-size: 14px;
  color: #414141;
  transition: 0.3s;
}
.md-news li .title:hover {
  color: #1f6bb8;
}
.md-news li .btn:hover {
  color: #fff;
  background: #1f6bb8;
  border: 1px solid #1f6bb8;
}
.md-honer .item {
  display: flex;
}
.md-honer .item >div {
  flex: 0 0 50%;
  height: 540px;
}
.md-honer .item .img {
  overflow: hidden;
}
.md-honer .item .img img {
  width: 100%;
  height: 100%;
}
.md-honer .item .text {
  background: #f9f9f9;
  padding: 100px 60px;
}
.md-honer .item .text .title {
  font-size: 50px;
  color: #333;
  line-height: 1.2;
  margin-bottom: 40px;
}
.md-honer .item .text .txt {
  font-size: 16px;
  font-weight: 400;
  color: #666;
  line-height: 30px;
}
.mt30 {
  margin-top: 30px;
}
.md-dubai .sear-box {
  align-items: center;
  border: 1px solid #000;
  display: flex;
  width: 465px;
  margin: 0;
  box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.1);
}
.md-dubai .sear-box input.submit {
  background-image: url("../images/icon-sear-btn.png");
}
.md-dubai .sear-box input.submit,
.md-dubai .sear-box input.text {
  height: 40px;
  color: #000;
}
.md-dubai .body .item {
  background-color: #fff;
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.08);
  height: 413px;
  width: 31.3%;
  margin-right: 2%;
  float: left;
  margin-bottom: 2%;
}
.md-dubai .body .item:nth-child(3n) {
  margin-right: 0;
}
.md-dubai .body .item .img {
  height: 290px;
}
.md-dubai .body .item .img img {
  height: 290px;
  width: 100%;
}
.md-dubai .body .item .text {
  height: 123px;
}
.md-dubai .body .item .text>div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 123px;
}
.md-dubai .body .item .text-left {
  width: 120px;
  align-items: center;
  float: left;
  border-right: 1px solid #f2f2f2;
}
.md-dubai .body .item .text-left h3 {
  font-size: 16px;
  color: #828282;
}
.md-dubai .body .item .text-left h4 {
  font-weight: bold;
  font-size: 40px;
  color: #000;
  font-weight: bold;
}
.md-dubai .body .item .text-right {
  margin-left: 144px;
}
.md-dubai .body .item .text-right h3 {
  font-size: 30px;
}
.md-dubai .body .item .text-right h3 a {
  color: #414141;
}
.md-dubai .body .item .text-right h3 a:hover {
  color: #1f6bb8;
}
.md-dubai .body .item .text-right p {
  font-size: 16px;
  color: #828282;
  background: url("../images/location.png") left center no-repeat;
  padding-left: 18px;
}
.md-project .item {
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.08);
  height: 536px;
}
.md-project .item .text {
  overflow: hidden;
}
.md-project .item .text .img {
  width: 63px;
  height: 63px;
  float: left;
}
.md-project .item .text .img img {
  width: 100%;
  height: 100%;
}
.md-project .item .text .title {
  margin-left: 80px;
}
.md-project .item .date {
  font-size: 16px;
  color: #828282;
  padding: 0 25px;
  margin-bottom: 15px;
}
.md-project .item .btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
}
.md-project .item .btns a {
  width: 195px;
  height: 34px;
  line-height: 32px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #ababab;
  color: #171727;
  cursor: pointer;
  display: inline-block;
  transition: background 0.3s;
}
.md-project .item .btns a:hover {
  background: #1f6bb8;
  border: 1px solid #1f6bb8;
  color: #fff;
}
.md-project .item:before {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-color: transparent;
}
.md-project .item.item-luxury:before {
  background-image: url("../images/item-luxury.png");
}
.md-project .item.item-new:before {
  background-image: url("../images/item-news.png");
}
.md-project .post-text {
  font-size: 14px;
  line-height: 1.6;
  color: #525252;
  margin-bottom: 40px;
}
.md-project .map-box {
  margin-bottom: 50px;
}
.md-project .map-box img {
  width: 100%;
  height: 430px;
}
.md-project .back-nav {
  margin-bottom: 20px;
}
.md-project .back-nav .back {
  font-size: 20px;
  color: #333;
}
.md-project .back-nav .back .icon-back {
  width: 13px;
  height: 24px;
  display: inline-block;
  top: 5px;
  margin-right: 10px;
  background-image: url("../images/icon-back.png");
}
.md-project .back-nav .back:hover {
  color: #1f6bb8;
}
.md-project .back-nav .back:hover .icon-back {
  background-image: url("../images/icon-back2.png");
}
.md-padding {
  padding-top: 20px;
}
.md-emaar .gb-title.line {
  margin-bottom: 0;
}
.md-emaar .emaar-text {
  background: #f7f7f7;
  padding: 40px 60px;
  font-size: 16px;
  line-height: 26px;
  color: #666;
  margin-bottom: 60px;
  border-bottom: 1px solid #eee;
}
.md-emaar .emaar-text .img {
  width: 95px;
  height: 130px;
  margin-left: 38px;
  float: left;
  padding-top: 17px;
  padding-bottom: 17px;
}
.md-emaar .emaar-text .img img {
  width: 100%;
  height: 100%;
}
.md-emaar .emaar-text .txt {
  padding-top: 17px;
  padding-bottom: 17px;
  margin-left: 230px;
  border-left: 1px solid #eee;
  padding-left: 58px;
}
.detail-banner {
  height: 675px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.detail-banner .nav {
  z-index: 9;
}
.detail-banner .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.detail-banner .text {
  text-align: center;
  color: #fff;
  width: 950px;
  margin: 0 auto;
}
.detail-banner .text h3 {
  font-size: 50px;
}
.detail-banner .text p {
  font-size: 16px;
  line-height: 26px;
}
.detail-banner .text .btn {
  width: 150px;
  height: 46px;
  background: #fff;
  color: #000;
  text-align: centr;
  line-height: 46px;
  display: inline-block;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 30px;
}
.detail-banner .text .btn:hover {
  background: #1f6bb8;
  color: #fff;
}
.detail-banner.gb-banner .swiper-button-prev,
.detail-banner.gb-banner .swiper-button-next {
  bottom: 80px;
}
.detail-banner .swiper-button-prev,
.detail-banner .swiper-button-next {
  width: 46px;
  height: 46px;
  background-size: contain;
  position: absolute;
  bottom: 20px;
  left: 50%;
  right: auto;
  top: auto;
  opacity: 1;
}
.detail-banner .swiper-button-prev {
  background-image: url("../images/left-arrow-4.png");
  transition: 0.3s;
  transform: translateX(-60px);
}
.detail-banner .swiper-button-prev:hover {
  background-image: url("../images/left-arrow-1.png");
}
.detail-banner .swiper-button-next {
  background-image: url("../images/right-arrow-4.png");
  transition: 0.3s;
  transform: translateX(0px);
}
.detail-banner .swiper-button-next:hover {
  background-image: url("../images/right-arrow-1.png");
}
.detail-banner .wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.detail-banner .data {
  width: 680px;
  background: rgba(0,0,0,0.2);
}
.detail-banner .data ul {
  display: flex;
}
.detail-banner .data ul li {
  height: 200px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.detail-banner .data ul li:last-child {
  border-left: 1px solid rgba(255,255,255,0.1);
}
.detail-banner .data ul h3 {
  font-size: 80px;
}
.detail-banner .data ul h4 {
  font-size: 20px;
}
.detail-banner .txt {
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin-top: 30px;
}
.md-guide .title {
  font-size: 34px;
  color: #333;
  margin: 90px 0;
}
.md-guide .list ul {
  position: relative;
}
.md-guide .list ul:before {
  position: absolute;
  content: '';
  display: inline-block;
  width: 1px;
  top: 0;
  bottom: 0;
  left: 55px;
  background: #ccc;
}
.md-guide .list li {
  padding-left: 142px;
  position: relative;
  min-height: 115px;
  padding-top: 26px;
}
.md-guide .list li .icon {
  width: 113px;
  height: 113px;
  border-radius: 50%;
  border: 1px solid #ccc;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 26px;
}
.md-guide .list li .icon1 {
  background-image: url("../images/guide-icon1.png");
}
.md-guide .list li .icon2 {
  background-image: url("../images/guide-icon2.png");
}
.md-guide .list li .icon3 {
  background-image: url("../images/guide-icon3.png");
}
.md-guide .list li .icon4 {
  background-image: url("../images/guide-icon4.png");
}
.md-guide .list li .icon5 {
  background-image: url("../images/guide-icon5.png");
}
.md-guide .list li .icon6 {
  background-image: url("../images/guide-icon6.png");
}
.md-guide .list li .icon7 {
  background-image: url("../images/guide-icon7.png");
}
.md-guide .list li:last-child .text {
  border-bottom: none;
  padding-bottom: 0;
}
.md-guide .list li:first-child {
  padding-top: 0;
}
.md-guide .list li:first-child .icon {
  top: 0;
}
.md-guide .list .text {
  padding-top: 32px;
  padding-bottom: 60px;
  border-bottom: 1px solid #f0f0f0;
}
.md-guide .list .text h3 {
  font-size: 30px;
  color: #000;
  margin-bottom: 10px;
}
.md-guide .list .text p {
  font-size: 16px;
  line-height: 1.8;
  color: #999;
}
.md-about .texts {
  padding-top: 100px;
}
.md-about .title {
  font-size: 50px;
  color: #333;
  margin-bottom: 20px;
}
.md-about .txt {
  font-size: 16px;
  color: #666;
  line-height: 1.8;
}
.md-about .ceo-msg {
  display: flex;
  background: #f9f9f9;
  overflow: hidden;
  margin-top: 80px;
}
.md-about .ceo-msg >div {
  height: 500px;
  flex: 0 0 50%;
}
.md-about .ceo-msg .img {
  position: relative;
}
.md-about .ceo-msg .img:hover .logo:after {
  transform: scale(1.02);
}
.md-about .ceo-msg .img img {
  width: 100%;
  height: 100%;
}
.md-about .ceo-msg .logo {
  width: 650px;
  height: 340px;
  position: absolute;
  right: 78px;
  top: 50%;
  transform: translateY(-50%);
}
.md-about .ceo-msg .logo:after,
.md-about .ceo-msg .logo:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: inline-block;
}
.md-about .ceo-msg .logo:after {
  border: 1px solid #fff;
  transition: 0.3s;
}
.md-about .ceo-msg .logo:before {
  background: url("../images/about-logo.png") no-repeat center;
}
.md-about .ceo-msg .text {
  padding: 80px;
}
.md-about .our {
  padding: 80px 0;
}
.md-about .our .icon {
  width: 85px;
  height: 85px;
  margin-bottom: 15px;
}
.md-about .our .icon1 {
  background-image: url("../images/about-icon1.png");
}
.md-about .our .icon2 {
  background-image: url("../images/about-icon2.png");
}
.md-about .our .icon3 {
  background-image: url("../images/about-icon3.png");
}
.md-about .our .icon4 {
  background-image: url("../images/about-icon4.png");
}
.md-about .our .item {
  width: 48%;
  float: left;
  margin-bottom: 60px;
}
.md-about .our .item:nth-child(2n+1) {
  margin-right: 4%;
}
.md-about .talent {
  background: #f9f9f9;
  position: relative;
}
.md-about .talent .odd .img {
  left: 0;
  right: auto;
}
.md-about .talent .odd .text {
  margin-left: 50%;
  padding-left: 78px;
}
.md-about .talent .item {
  display: flex;
}
.md-about .talent .item .img {
  position: absolute;
  right: 0;
}
.md-about .talent .item>div {
  width: 50%;
  height: 540px;
}
.md-about .talent .item>div img {
  width: 100%;
  height: 100%;
}
.md-about .talent .item .text {
  padding-top: 75px;
  padding-right: 50px;
}
.md-about .choose {
  padding-top: 100px;
}
.md-about .choose .items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}
.md-about .choose .item {
  flex: 0 0 33.333%;
  display: flex;
  align-items: center;
  height: 115px;
}
.md-about .choose .item .txt {
  margin-left: 20px;
  font-size: 16px;
  color: #666;
  width: 305px;
}
.md-about .icon {
  width: 55px;
  height: 55px;
}
.md-ucenter {
  margin-bottom: 40px;
}
.md-ucenter .bread-nav {
  color: #000;
  line-height: 40px;
}
.md-ucenter .bread-nav span {
  margin: 0 5px;
}
.md-ucenter .bread-nav a {
  font-size: 14px;
  color: #000;
}
.md-ucenter .bread-nav a:hover {
  color: #1f6bb8;
}
.md-ucenter .left-con {
  width: 267px;
  background: #4a4c4e;
  float: left;
}
.md-ucenter .right-con {
  margin-left: 267px;
  padding: 0 60px;
}
.md-ucenter .right-con .pager {
  text-align: center;
  margin-top: 50px;
}
.md-ucenter .right-con .pager a {
  width: 32px;
  height: 32px;
  border: 1px solid #cbcbcb;
  display: inline-block;
  line-height: 32px;
  text-align: center;
  color: #000;
  margin: 0 3px;
}
.md-ucenter .right-con .pager a:hover,
.md-ucenter .right-con .pager a.active {
  border: 1px solid #4a4c4e;
  background: #4a4c4e;
  color: #fff;
}
.md-ucenter .right-con .pager .page-btn {
  width: 55px;
}
.md-ucenter .right-con-publishlist li {
  height: 102px;
  border-bottom: 1px solid #f2f2f2;
  padding-top: 26px;
  position: relative;
  padding-left: 10px;
}
.md-ucenter .right-con-publishlist li .btns {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transition: 0.2s;
  right: 26px;
  top: 50%;
  margin-top: -17px;
  z-index: 9;
}
.md-ucenter .right-con-publishlist li .btns .icon {
  width: 40px;
  height: 35px;
  cursor: pointer;
}
.md-ucenter .right-con-publishlist li .btns .icon-edit {
  background-image: url("../images/icon-edit3.png");
}
.md-ucenter .right-con-publishlist li .btns .icon-del {
  background-image: url("../images/icon-del3.png");
}
.md-ucenter .right-con-publishlist li h3 {
  margin-bottom: 5px;
}
.md-ucenter .right-con-publishlist li h3 a {
  font-size: 18px;
  color: #424447;
}
.md-ucenter .right-con-publishlist li h3 a:hover {
  color: #1f6bb8;
}
.md-ucenter .right-con-publishlist li .date {
  font-size: 16px;
  color: #686868;
}
.md-ucenter .right-con-publishlist li:hover {
  background: #faf6f7;
}
.md-ucenter .right-con-publishlist li:hover .btns {
  visibility: visible;
  opacity: 1;
}
.md-ucenter .right-con-submit2 .body {
  padding-top: 40px;
}
.md-ucenter .right-con-submit2 .body .submit {
  margin-left: 155px;
  margin-top: 50px;
}
.md-ucenter .right-con-submit2 .body .submit input {
  width: 275px;
  height: 44px;
  background: #1f6bb8;
  font-size: 22px;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}
.md-ucenter .right-con-submit2 .body .submit input:hover {
  opacity: 0.9;
}
.md-ucenter .right-con-submit2 .item {
  display: flex;
  margin-bottom: 18px;
  align-items: center;
}
.md-ucenter .right-con-submit2 .item .tit {
  font-size: 18px;
  color: #333;
  width: 155px;
}
.md-ucenter .right-con-submit2 .item .text {
  width: 362px;
  height: 48px;
  line-height: 46px;
  border-radius: 3px;
  border: 1px solid #dbdbdb;
  padding: 0 10px;
  transition: 0.3s;
  font-size: 14px;
  color: #333;
}
.md-ucenter .right-con-submit2 .item .text:focus {
  border-width: 1px;
  border-color: #2b2b2b;
  border-style: solid;
  box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.1);
}
.md-ucenter .right-con-submit2 .item .line {
  flex: 1;
}
.md-ucenter .right-con-submit2 .item textarea.text {
  height: 155px;
}
.md-ucenter .right-con-submit .body {
  padding-top: 40px;
}
.md-ucenter .right-con-submit .body .submit {
  margin-top: 50px;
}
.md-ucenter .right-con-submit .body .submit input {
  width: 275px;
  height: 44px;
  background: #1f6bb8;
  font-size: 22px;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}
.md-ucenter .right-con-submit .body .submit input:hover {
  opacity: 0.9;
}
.md-ucenter .right-con-submit .item {
  margin-bottom: 32px;
}
.md-ucenter .right-con-submit .item .input {
  border-radius: 3px;
  border: 1px solid #dbdbdb;
  height: 48px;
  line-height: 46px;
  font-size: 16px;
  color: #333;
  position: relative;
}
.md-ucenter .right-con-submit .item .btn {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  width: 90px;
  border-radius: 3px;
  background: #424447;
  color: #fff;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}
.md-ucenter .right-con-submit .item .btn input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  opacity: 0;
  cursor: pointer;
}
.md-ucenter .right-con-submit .item span {
  padding: 0 10px;
}
.md-ucenter .right-con-submit .item h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}
.md-ucenter .right-con-reservation .body .title {
  margin-top: 30px;
  font-size: 22px;
  color: #000;
  margin-bottom: 10px;
}
.md-ucenter .right-con-reservation .body .title-small {
  font-size: 14px;
  color: #919191;
}
.md-ucenter .right-con-reservation .body .row {
  margin-bottom: 24px;
  display: flex;
}
.md-ucenter .right-con-reservation .body .row .input {
  flex: 0 0 320px;
  margin-right: 25px;
}
.md-ucenter .right-con-reservation .body .row .text {
  padding: 0 10px;
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid #dbdbdb;
  height: 48px;
  width: 100%;
  line-height: 46px;
}
.md-ucenter .right-con-reservation .body .row-text .text {
  height: 200px;
}
.md-ucenter .right-con-reservation .body .row-submit {
  text-align: center;
  margin-top: 30px;
}
.md-ucenter .right-con-reservation .body .row-submit .submit {
  width: 275px;
  height: 44px;
  background: #1f6bb8;
  border-radius: 4px;
  font-size: 22px;
  color: #fff;
  cursor: pointer;
}
.md-ucenter .right-con-reservation .body .row-submit .submit:hover {
  opacity: 0.9;
}
.md-ucenter .right-con-reservation .body .date {
  margin-top: 22px;
  display: flex;
  border-radius: 3px;
  width: 615px;
  border: 1px solid #dbdbdb;
}
.md-ucenter .right-con-reservation .body .date li {
  border-right: 1px solid #dbdbdb;
  height: 45px;
  line-height: 45px;
  flex: 1;
  padding-left: 23px;
  padding-right: 23px;
  background-image: url("../images/icon-arrdwon3.png");
  background-position: 90% center;
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;
}
.md-ucenter .right-con-reservation .body .date li:last-child {
  border-right: none;
}
.md-ucenter .right-con-reservation .body .date li span {
  font-size: 20px;
  margin-right: 8px;
  color: #000;
}
.md-ucenter .right-con-reservation .body .date li em {
  font-size: 14px;
  color: #919191;
}
.md-ucenter .right-con-reservation .body .date .subnav {
  position: absolute;
  left: -1px;
  right: -1px;
  max-height: 200px;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #dbdbdb;
  top: 45px;
  display: none;
}
.md-ucenter .right-con-reservation .body .date .subnav a {
  font-size: 20px;
  color: #000;
  padding-left: 23px;
  display: block;
  line-height: 35px;
}
.md-ucenter .right-con-reservation .body .date .subnav a:hover,
.md-ucenter .right-con-reservation .body .date .subnav a.active {
  background: #1f6ab7;
  color: #fff;
}
.md-ucenter .right-con-browse .body .noData {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  font-size: 24px;
}
.md-ucenter .right-con-browse .body li {
  padding: 20px 0;
  border-bottom: 1px solid #dbdbdb;
  position: relative;
}
.md-ucenter .right-con-browse .body li .reserved {
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 22px;
  font-size: 16px;
  color: #4a4c4e;
}
.md-ucenter .right-con-browse .body li .icon-reserved {
  width: 21px;
  height: 21px;
  top: 5px;
  margin-right: 5px;
  background-image: url("../images/icon-reserved.png");
}
.md-ucenter .right-con-browse .body li .btn-del {
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
}
.md-ucenter .right-con-browse .body li .icon-del {
  width: 22px;
  height: 24px;
  background-image: url("../images/icon-del5.png");
}
.md-ucenter .right-con-browse .body li .btn {
  position: absolute;
  width: 142px;
  height: 30px;
  background: #424447;
  text-align: center;
  color: #fff;
  line-height: 30px;
  display: inline-block;
  right: 35px;
  top: 50%;
  margin-top: -15px;
  border-radius: 3px;
  transition: 0.2s;
  visibility: hidden;
  opacity: 0;
}
.md-ucenter .right-con-browse .body li .btn2 {
  right: 75px;
}
.md-ucenter .right-con-browse .body li:hover {
  background: #faf6f7;
}
.md-ucenter .right-con-browse .body li:hover .btn,
.md-ucenter .right-con-browse .body li:hover .btn-del {
  cursor: pointer;
  opacity: 1;
  visibility: visible;
}
.md-ucenter .right-con-browse .body li .text h3 {
  color: #1f6bb8;
}
.md-ucenter .right-con-browse .body li .icon-del:hover {
  background-image: url("../images/icon-del5-1.png");
}
.md-ucenter .right-con-browse .body .img {
  float: left;
}
.md-ucenter .right-con-browse .body .img img {
  width: 207px;
  height: 130px;
}
.md-ucenter .right-con-browse .body .text {
  margin-left: 235px;
}
.md-ucenter .right-con-browse .body .text h3 {
  font-size: 20px;
  color: #000;
  margin-bottom: 10px;
}
.md-ucenter .right-con-browse .body .text h4 {
  font-size: 14px;
  margin-bottom: 20px;
  color: #828282;
}
.md-ucenter .right-con-browse .body .text .price {
  margin-bottom: 15px;
  font-size: 22px;
  color: #000;
}
.md-ucenter .right-con-browse .body .text .price span {
  background-image: url("../images/icon-arr-down.png");
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 10px;
  font-size: 14px;
  color: #000;
  display: inline-block;
  margin-right: 10px;
}
.md-ucenter .right-con-browse .body .text .tags {
  display: flex;
}
.md-ucenter .right-con-browse .body .text .tag {
  flex: 0 0 158px;
  font-size: 16px;
  color: #828282;
}
.md-ucenter .right-con-browse .body .text .icon {
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.md-ucenter .right-con-browse .body .text .icon-home {
  background-image: url("../images/vr-icon8.png");
}
.md-ucenter .right-con-browse .body .text .icon-room {
  background-image: url("../images/vr-icon9.png");
}
.md-ucenter .right-con-browse .body .text .icon-bath {
  background-image: url("../images/vr-icon10.png");
}
.md-ucenter .right-con-message .texts .texts-date {
  font-size: 16px;
  color: #505050;
  line-height: 40px;
}
.md-ucenter .right-con-message .texts .texts-title {
  font-size: 30px;
  line-height: 1.2;
  color: #000;
}
.md-ucenter .right-con-message .texts .text-body {
  font-size: 14px;
  line-height: 1.8;
  color: #505050;
}
.md-ucenter .right-con-message .body {
  padding-top: 20px;
}
.md-ucenter .right-con-message .body li a {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 45px;
  color: #333;
  padding-right: 10px;
  padding-left: 10px;
}
.md-ucenter .right-con-message .body li a:hover {
  background: #faf6f7;
  color: #1f6ab7;
}
.md-ucenter .right-con-message .body li a .new {
  height: 17px;
  background: #1f6bb8;
  color: #fff;
  line-height: 17px;
  padding: 0 10px;
  text-align: center;
  line-height: 17px;
  border-radius: 3px;
  display: inline-block;
  margin-left: 10px;
}
.md-ucenter .right-con-password .password-form {
  width: 500px;
  margin: 0 auto;
  padding-top: 120px;
}
.md-ucenter .right-con-password .password-form li {
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
}
.md-ucenter .right-con-password .password-form .text {
  flex: 1;
  height: 50px;
  line-height: 48px;
  border-radius: 3px;
  border: 1px solid #dbdbdb;
  padding: 0 10px;
  transition: 0.3s;
  font-size: 14px;
  color: #333;
}
.md-ucenter .right-con-password .password-form .text:focus {
  border-width: 1px;
  border-color: #2b2b2b;
  border-style: solid;
  box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.1);
}
.md-ucenter .right-con-password .password-form .btn {
  width: 242px;
  height: 44px;
  border-radius: 3px;
  background: #2b2b2b;
  color: #fff;
  font-size: 22px;
  text-align: center;
}
.md-ucenter .right-con-password .password-form .btn:hover {
  opacity: 0.9;
}
.md-ucenter .right-con-password .password-form .btn2 {
  background: #1f6bb8;
}
.md-ucenter .right-con-publish .foot {
  display: flex;
  justify-content: space-between;
}
.md-ucenter .right-con-publish .foot a {
  width: 100px;
  height: 44px;
  display: inline-block;
  line-height: 44px;
  color: #fff;
  font-size: 22px;
  text-align: center;
  border-radius: 3px;
}
.md-ucenter .right-con-publish .foot a:hover {
  opacity: 0.9;
}
.md-ucenter .right-con-publish .foot .left-btn a {
  background: #424447;
}
.md-ucenter .right-con-publish .foot .right-btn a {
  background: #1f6bb8;
}
.md-ucenter .right-con-publish ul {
  margin-top: 35px;
  margin-bottom: 35px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.md-ucenter .right-con-publish li {
  margin-bottom: 32px;
  flex: 0 0 340px;
  display: flex;
  flex-direction: column;
  margin-right: 25px;
}
.md-ucenter .right-con-publish li:nth-child(3n) {
  margin-right: 0;
}
.md-ucenter .right-con-publish .tit {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}
.md-ucenter .right-con-publish .text {
  flex: 1;
  height: 47px;
  line-height: 47px;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
  padding: 0 10px;
  transition: 0.3s;
  font-size: 14px;
  color: #333;
}
.md-ucenter .right-con-publish .text:focus {
  border-width: 1px;
  border-color: #2b2b2b;
  border-style: solid;
  box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.1);
}
.md-ucenter .bottom-con {
  background: #fff;
  margin-top: 20px;
  min-height: 680px;
  position: relative;
}
.md-ucenter .bottom-con .right-con {
  padding: 20px 60px;
  padding-bottom: 100px;
}
.md-ucenter .bottom-con .right-con .head {
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  justify-content: space-between;
}
.md-ucenter .bottom-con .right-con .head .left-nav {
  display: flex;
}
.md-ucenter .bottom-con .right-con .head .left-nav .title span:before,
.md-ucenter .bottom-con .right-con .head .left-nav .title a:before {
  background: transparent;
}
.md-ucenter .bottom-con .right-con .head .title {
  margin-right: 45px;
}
.md-ucenter .bottom-con .right-con .head .title .icon-backup {
  width: 15px;
  height: 13px;
  background-image: url("../images/icon-backup.png");
  margin-right: 10px;
}
.md-ucenter .bottom-con .right-con .head .title span,
.md-ucenter .bottom-con .right-con .head .title a {
  position: relative;
  padding-bottom: 15px;
  display: inline-block;
  font-size: 20px;
  color: #333;
}
.md-ucenter .bottom-con .right-con .head .title span:before,
.md-ucenter .bottom-con .right-con .head .title a:before {
  content: '';
  display: inline-block;
  height: 2px;
  background: #333;
  left: 0;
  right: 0;
  bottom: -1px;
  position: absolute;
}
.md-ucenter .bottom-con .right-con .head .title.active .icon-backup,
.md-ucenter .bottom-con .right-con .head .title:hover .icon-backup {
  background-image: url("../images/icon-backup2.png");
}
.md-ucenter .bottom-con .right-con .head .title.active a,
.md-ucenter .bottom-con .right-con .head .title:hover a {
  color: #1f6bb8;
}
.md-ucenter .bottom-con .right-con .head .title.active a:before,
.md-ucenter .bottom-con .right-con .head .title:hover a:before {
  background: #1f6bb8;
}
.md-ucenter .bottom-con .right-con .head .icon-publish {
  background-image: url("../images/icon-home1.png");
  width: 17px;
  height: 16px;
  position: relative;
  top: -1px;
  margin-right: 10px;
}
.md-ucenter .bottom-con .right-con .head .right-link a {
  display: inline-block;
  width: 241px;
  height: 30px;
  background: #424447;
  border-radius: 15px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
.md-ucenter .bottom-con .right-con .head .right-link a:hover {
  background: #1f6bb8;
}
.md-ucenter .bottom-con .left-con {
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
}
.md-ucenter .bottom-con .left-con li em {
  height: 20px;
  display: inline-block;
  left: 150px;
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: #1f6bb8;
  padding: 0 6px;
  line-height: 20px;
  margin-left: 10px;
}
.md-ucenter .bottom-con .left-con li a {
  color: #fff;
  font-size: 18px;
  padding-left: 62px;
  line-height: 60px;
  display: flex;
  border: 3px solid transparent;
  align-items: center;
}
.md-ucenter .bottom-con .left-con li a:hover {
  border-left-color: #1f6ab7;
  background: #fff;
  color: #000;
}
.md-ucenter .bottom-con .left-con li.active a {
  border-left-color: #1f6ab7;
  background: #fff;
  color: #000;
}
.md-ucenter .top-con {
  height: 170px;
  background: #fff;
}
.md-ucenter .top-con >div {
  height: 170px;
}
.md-ucenter .top-con .user-infos {
  padding-top: 30px;
  position: relative;
}
.md-ucenter .top-con .user-infos .icon-close {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 18px;
  top: 18px;
  transition: 0.3s;
  background-image: url("../images/icon-close.png");
}
.md-ucenter .top-con .user-infos .icon-close:hover {
  transform: rotate(180deg);
}
.md-ucenter .top-con .user-infos .submit {
  width: 85px;
  height: 37px;
  border-radius: 3px;
  font-size: 16px;
  background: #424447;
  color: #fff;
  position: absolute;
  right: 18px;
  bottom: 18px;
  cursor: pointer;
}
.md-ucenter .top-con .user-infos .submit:hover {
  opacity: 0.9;
}
.md-ucenter .top-con .user-infos ul {
  display: flex;
  flex-wrap: wrap;
}
.md-ucenter .top-con .user-infos .edit {
  background-image: url("../images/icon-edit2.png");
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 29px;
  position: absolute;
  right: 60px;
  top: 20px;
  line-height: 24px;
  display: inline-block;
  font-size: 14px;
  color: #333;
}
.md-ucenter .top-con .user-infos .edit:hover {
  color: #333;
}
.md-ucenter .top-con .user-infos .texts .tit {
  width: auto;
  margin-right: 10px;
}
.md-ucenter .top-con .user-infos .texts li {
  position: relative;
  padding-left: 15px;
}
.md-ucenter .top-con .user-infos .texts li:before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #c7c7c7;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -3px;
}
.md-ucenter .top-con .user-infos li {
  display: flex;
  flex: 0 0 430px;
  margin-bottom: 8px;
  margin-right: 43px;
}
.md-ucenter .top-con .user-infos .tit {
  font-size: 16px;
  color: #333;
  font-weight: bold;
  width: 131px;
  line-height: 34px;
}
.md-ucenter .top-con .user-infos .txt {
  font-size: 16px;
  line-height: 34px;
  color: #333;
}
.md-ucenter .top-con .user-infos .text {
  flex: 1;
  height: 34px;
  line-height: 32px;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
  padding: 0 10px;
  transition: 0.3s;
  font-size: 14px;
  color: #333;
}
.md-ucenter .top-con .user-infos .text:focus {
  border-width: 1px;
  border-color: #2b2b2b;
  border-style: solid;
  box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.1);
}
.md-ucenter .top-con .user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.md-ucenter .top-con .user-info .img {
  width: 97px;
  height: 97px;
  border: 1px solid #5d6164;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 10px;
  position: relative;
}
.md-ucenter .top-con .user-info .img input[type='file'] {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  z-index: 99;
  cursor: pointer;
}
.md-ucenter .top-con .user-info .img:before {
  content: '';
  position: absolute;
  display: inline-block;
  background: rgba(255,255,255,0.95) url("../images/icon-upload.png") center no-repeat;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}
.md-ucenter .top-con .user-info .img:hover:before {
  visibility: visible;
  opacity: 1;
}
.md-ucenter .top-con .user-info .img.no-img:before {
  visibility: visible;
  opacity: 1;
}
.md-ucenter .top-con .user-info h3 {
  font-size: 16px;
  color: #fff;
}
.message-wrap {
  width: 200px;
  height: 60px;
  position: fixed;
  top: 80px;
  left: 50%;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%) scale(0.9);
  transition: 0.1s;
  z-index: 99999;
}
.message-wrap .error,
.message-wrap .success {
  width: 220px;
  height: 45px;
  border-radius: 45px;
  background: #ea644a;
  color: #fff;
  text-align: center;
  line-height: 45px;
  font-size: 16px;
}
.message-wrap .success {
  background: #1f6bb8;
}
.message-wrap.fadeIn {
  top: 120px;
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) scale(1);
}
.modal-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.6);
  z-index: 999;
  display: none;
}
.modal-wrapper {
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 9999;
}
.floor-modal {
  width: 1460px;
  height: 810px;
  line-height: 810px;
  text-align: center;
  margin-top: -405px;
  margin-left: -730px;
  background: #fff;
  padding: 0 80px;
}
.floor-modal .swiper-con {
  overflow: hidden;
}
.floor-modal .swiper-button-prev {
  background-image: url("../images/left-arrow-3.png");
  left: -40px;
  transition: 0.3s;
}
.floor-modal .swiper-button-prev:hover {
  background-image: url("../images/left-arrow-3-1.png");
  left: -45px;
}
.floor-modal .swiper-button-next {
  background-image: url("../images/right-arrow-3.png");
  right: -40px;
  transition: 0.3s;
}
.floor-modal .swiper-button-next:hover {
  background-image: url("../images/right-arrow-3-1.png");
  right: -45px;
}
.lose-password-modal .icon-success {
  background-image: url("../images/icon-susses.png");
  width: 104px;
  height: 91px;
}
.lose-password-modal .success-tip {
  text-align: center;
  margin-bottom: 55px;
}
.lose-password-modal .success-tip .tip {
  font-size: 18px;
  line-height: 1.5;
  color: #292929;
}
.login-modal {
  width: 706px;
  margin-left: -353px;
  margin-top: 15vh;
  top: 0;
  background: #fff;
  transition: 0.3s;
  display: block;
}
.login-modal .close {
  width: 38px;
  height: 38px;
  background: #000 url("../images/login-close.png") no-repeat center;
  border-radius: 50%;
  position: absolute;
  right: -19px;
  top: -19px;
  transition: 0.3s;
  cursor: pointer;
}
.login-modal .close:hover {
  transform: rotate(180deg);
}
.login-modal .item {
  display: flex;
  margin-bottom: 15px;
  position: relative;
}
.login-modal .item .code {
  position: absolute;
  right: 0;
  top: 2px;
  right: 2px;
  height: 44px;
  line-height: 44px;
  width: 110px;
  text-align: center;
}
.login-modal .item .yzm-code {
  background: #e8e8e8;
  color: #4a4a4a;
  font-size: 12px;
  top: 1px;
  bottom: 1px;
  right: 1px;
  border-left: 1px solid #dbdbdb;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-modal .item .yzm-code:hover {
  background: #eaeaea;
}
.login-modal .remember {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #787878;
}
.login-modal .remember input {
  margin-right: 10px;
}
.login-modal .submit {
  background: #2b2b2b;
  color: #fff;
  font-size: 22px;
  flex: 1;
  border-radius: 3px;
  height: 44px;
  transition: 0.3s;
  cursor: pointer;
}
.login-modal .submit:hover {
  background: #1f6bb8;
}
.login-modal .link-btn {
  background: transparent;
  color: #000;
  font-size: 20px;
  text-align: center;
  line-height: 42px;
  border: 1px solid #c6c6c6;
}
.login-modal .link-btn:hover {
  border: 1px solid #1f6bb8;
  color: #fff;
}
.login-modal .link {
  margin-top: 30px;
}
.login-modal .link a {
  color: #787878;
}
.login-modal .link a:hover {
  color: #1f6bb8;
}
.login-modal .text {
  flex: 1;
  height: 50px;
  line-height: 48px;
  border-radius: 3px;
  border: 1px solid #dbdbdb;
  padding: 0 10px;
  transition: 0.3s;
  font-size: 14px;
  color: #333;
}
.login-modal .text:focus {
  border-width: 1px;
  border-color: #2b2b2b;
  border-style: solid;
  box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.1);
}
.login-modal .head {
  background: #e1e1e1;
}
.login-modal .head ul {
  display: flex;
}
.login-modal .head ul li {
  height: 70px;
  width: 178.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
}
.login-modal .head ul li.active {
  background: #fff;
}
.login-modal .head ul li:hover:not(.active) {
  background: #e4e4e4;
}
.login-modal .head ul .sms-title {
  flex: 1;
  background: #e1e1e1 !important;
}
.login-modal .body {
  display: flex;
  min-height: 346px;
}
.login-modal .body .sms-reg {
  padding: 25px 35px;
  flex: 1;
  background: #f8f6f7;
}
.login-modal .body .sms-reg .txt {
  font-size: 12px;
  color: #323232;
  margin-top: 30px;
}
.login-modal .body .texts {
  padding: 38px;
  width: 354px;
  background: #f8f6f7;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.login-modal .body .texts .txt {
  margin-top: 55px;
  color: #8d8d8d;
  font-size: 14px;
  line-height: 1.8;
}
.login-modal .body .texts .txt a {
  color: #1f6ab7;
}
.login-modal .body .texts .txt a:hover {
  text-decoration: underline;
}
.login-modal .body .texts .tit {
  font-size: 14px;
  line-height: 1.8;
  color: #8d8d8d;
}
.login-modal .body .texts .btn {
  margin-top: 20px;
  background-color: #3b5998;
  width: 275px;
  height: 54px;
  border-radius: 3px;
  display: inline-block;
  font-size: 16px;
  color: #fff;
  padding-left: 65px;
  line-height: 54px;
  background-image: url("../images/fb-icon.png");
  background-position: 20px 50%;
  background-repeat: no-repeat;
}
.login-modal .body .texts .btn:hover {
  opacity: 0.9;
}
.login-modal .body .texts .google {
  background-color: #d72638;
  background-image: url("../images/goo-icon.png");
}
.login-modal .body .tab {
  flex: 1;
  padding: 34px 39px;
}
.login-modal .body .tab li {
  display: none;
}
.login-modal .body .tab li.active {
  display: block;
}
.md-error {
  background: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.md-error >div {
  width: 100%;
}
.md-error .top {
  border-bottom: 1px solid #303030;
  font-size: 140px;
  color: #303030;
  font-weight: bold;
  text-align: center;
}
.md-error .bottom {
  text-align: center;
  margin-top: 25px;
}
.md-error .bottom h3 {
  font-size: 26px;
  color: #303030;
}
.md-error .bottom .btns {
  width: 270px;
  border: 1px solid #a9a9a9;
  margin: 0 auto;
  margin-top: 100px;
  display: flex;
}
.md-error .bottom .btns a {
  flex: 1;
  text-align: center;
  font-size: 22px;
  color: #303030;
  display: inline-block;
  line-height: 46px;
  height: 46px;
}
.md-error .bottom .btns a:last-child {
  background: #1f6ab7;
  color: #fff;
}
.submit-success-modal {
  width: 760px;
  height: 380px;
  background: #fff;
  border-radius: 5px;
  transform: none;
  margin-left: -380px;
  margin-top: -190px;
  text-align: center;
  padding: 90px;
  padding-bottom: 0;
}
.submit-success-modal .close {
  width: 45px;
  height: 45px;
  cursor: pointer;
  background-image: url("../images/icon-submit-close.png");
  position: absolute;
  right: 0;
  top: 0;
}
.submit-success-modal .title {
  font-size: 30px;
  color: #1f6bb8;
}
.submit-success-modal .txt {
  font-size: 16px;
  line-height: 1.8;
  color: #666;
  margin-top: 20px;
}
.submit-success-modal .icon-success {
  background-image: url("../images/icon-susses.png");
  width: 104px;
  height: 91px;
}
.careers-modal {
  width: 1460px;
  padding: 80px;
  background: #fff;
  margin-top: 8vh;
  top: 0;
  transform: none;
  left: 50%;
  margin-left: -730px;
}
.careers-modal form {
  height: 70vh;
  display: flex;
  flex-direction: column;
}
.careers-modal-head {
  padding-bottom: 40px;
  border-bottom: 1px solid #ccc;
  flex: 0 0 100px;
}
.careers-modal-head h3 {
  font-size: 26px;
  color: #000;
  margin-bottom: 10px;
}
.careers-modal-head p {
  font-size: 16px;
  color: #999;
}
.careers-modal-head .close {
  cursor: pointer;
  position: absolute;
  width: 33px;
  height: 33px;
  top: 80px;
  right: 80px;
  background: #1f6bb8;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../images/icon-close2.png");
}
.careers-modal-body {
  padding: 30px 0;
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}
.careers-modal-body ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.careers-modal-body ul li {
  width: 48%;
  margin-bottom: 50px;
}
.careers-modal-body ul li .tit {
  font-size: 20px;
  color: #666;
  margin-bottom: 10px;
}
.careers-modal-body ul li input,
.careers-modal-body ul li .text {
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 48px;
  width: 100%;
  padding: 0 10px;
  font-size: 16px;
}
.careers-modal-body ul li.row {
  flex: 1;
  position: relative;
}
.careers-modal-body ul li.row .input {
  position: relative;
}
.careers-modal-body ul li.row input {
  border-radius: 3px 0 0 3px;
}
.careers-modal-body ul li.row .btn {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 3px 3px 0;
  width: 100px;
  height: 48px;
  background: #000;
  color: #fff;
  font-size: 16px;
  line-height: 48px;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
}
.careers-modal-body ul li.row .btn input {
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
}
.careers-modal-foot {
  flex: 0 0 80px;
  padding-top: 30px;
  text-align: center;
}
.careers-modal-foot .submit {
  width: 300px;
  height: 60px;
  line-height: 60px;
  background: #1f6bb8;
  transition: 0.2s;
  text-align: center;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}
.careers-modal-foot .submit:hover {
  background: #237ad1;
}
.preferences-modal {
  width: 620px;
  height: 375px;
  background: #fff;
  transform: none;
  margin-left: -310px;
  margin-top: -187px;
}
.preferences-modal .apply-btn {
  margin: 30px 0;
}
.preferences-modal .apply-btn input {
  height: 50px;
  line-height: 50px;
  background: #1f6bb8;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  width: 100%;
  font-size: 20px;
  cursor: pointer;
}
.preferences-modal .apply-btn input:hover {
  background: #4e97e1;
}
.preferences-modal .info {
  font-size: 18px;
  color: #666;
}
.preferences-modal .info ul {
  display: flex;
  text-align: center;
}
.preferences-modal .info ul li {
  flex: 1;
}
.preferences-modal .info ul li .icon {
  height: 16px;
  width: 18px;
  background-image: url("../images/img-tel.png");
}
.preferences-modal .info ul li .icon2 {
  background-image: url("../images/img-tel2.png");
}
.preferences-modal .head {
  background: #f5f7fa;
  height: 90px;
  line-height: 90px;
  position: relative;
  border-bottom: 1px solid #e9eaed;
}
.preferences-modal .head h3 {
  text-align: center;
  font-size: 28px;
  color: #333;
}
.preferences-modal .head .close {
  background-color: #000;
  background-image: url("../images/close-icon.png");
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.3s;
  width: 45px;
  height: 45px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.preferences-modal .head .close:hover {
  background-color: #1f6bb8;
}
.preferences-modal .body {
  padding: 50px;
}
.preferences-modal .body .con {
  display: flex;
  align-items: center;
}
.preferences-modal .body .con .tit {
  width: 88px;
  display: inline-block;
  text-align: right;
  padding-right: 10px;
  font-size: 18px;
}
.preferences-modal .body .con .btns {
  width: 125px;
  border: 1px solid #ccc;
  height: 50px;
  display: flex;
  border-radius: 3px;
}
.preferences-modal .body .con .btns button {
  flex: 1;
  cursor: pointer;
}
.preferences-modal .body .con .btns button.active {
  background: #000;
  color: #fff;
}
.video-modal {
  position: static;
  transform: none;
}
.video-modal .video-box {
  width: 1000px;
  height: 600px;
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-modal .close {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../images/icon-big-close.png");
  position: fixed;
  z-index: 1000;
  top: 30px;
  right: 30px;
}
#fly {
  width: 8px;
  height: 8px;
  background: #f00;
  border-radius: 50%;
}
.customInfobox {
  min-width: 322px;
  height: 140px;
  max-width: 400px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}
.customInfobox .img {
  flex: 0 0 140px;
  height: 80px;
  overflow: hidden;
}
.customInfobox .img img {
  width: 100%;
}
.customInfobox .customInfobox-info {
  padding: 15px;
  display: flex;
}
.customInfobox .customInfobox-info .img {
  width: 128px;
  margin-right: 15px;
}
.customInfobox .customInfobox-texts {
  padding: 0 20px;
}
.customInfobox .customInfobox-texts .title {
  margin-bottom: 10px;
  font-size: 16px;
  color: #000;
}
.customInfobox .customInfobox-texts .desc {
  font-size: 12px;
  color: #333;
  line-height: 1.5;
}
.customInfobox .desc {
  font-size: 16px;
  line-height: 24px;
  color: #414141;
}
.customInfobox .icon-close {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url("../images/close.png");
}
body .MicrosoftMap .Infobox {
  border: none !important;
  width: 222px;
  height: 82px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}
.md-comparison .head dl {
  display: flex;
}
.md-comparison .head dt {
  width: 250px;
}
.md-comparison .head dt h3 {
  font-size: 30px;
  color: #000;
  line-height: 1.6;
  min-height: 117px;
}
.md-comparison .head dd {
  width: 289px;
  margin-right: 20px;
  text-align: center;
}
.md-comparison .head dd:last-child {
  margin-right: 0;
}
.md-comparison .head .item {
  display: block;
  height: 254px;
  position: relative;
  background: #fff;
  transition: 0.5s;
  box-shadow: -2.179px 3.355px 9px 0px rgba(0,0,0,0.1);
}
.md-comparison .head .item .img {
  overflow: hidden;
  position: relative;
}
.md-comparison .head .item .img .left-text {
  position: absolute;
  bottom: 0;
  left: 23px;
  color: #fff;
  bottom: 14px;
}
.md-comparison .head .item .img .left-text span {
  font-weight: bold;
  font-size: 12px;
  padding-right: 15px;
  background-image: url("../images/vr-icon7.png");
  background-repeat: no-repeat;
  background-position: right center;
}
.md-comparison .head .item .img .left-text .number {
  font-size: 24px;
  color: #fff;
  font-weight: bold;
}
.md-comparison .head .item .img img {
  transition: 0.4s;
  width: 100%;
  height: 178px;
}
.md-comparison .head .item:before {
  content: '';
  width: 31px;
  height: 31px;
  background-color: #2f2f2f;
  position: absolute;
  background-image: url("../images/vr-icon4.png");
  background-size: 16px 9px;
  top: 0;
  left: 0;
  z-index: 9;
}
.md-comparison .head .item h3 {
  margin-bottom: 10px;
}
.md-comparison .head .item h3 a {
  font-size: 18px;
  color: #414141;
}
.md-comparison .head .item h3 a:hover {
  color: #1f6bb8;
}
.md-comparison .head .item h4 a {
  font-size: 12px;
  color: #828282;
}
.md-comparison .head .item .text {
  padding: 15px 20px;
}
.md-comparison .head .item .icon-date {
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
  margin-right: 5px;
  background-image: url("../images/icon-date.png");
}
.md-comparison .head .item.add {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.md-comparison .head .item.add h3 {
  font-size: 16px;
  color: #999;
  margin-top: 10px;
}
.md-comparison .head .item.add:before {
  display: none;
}
.md-comparison .head .icon-add {
  width: 30px;
  height: 30px;
  background-image: url("../images/icon-add2.png");
}
.md-comparison .head .share .icon {
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
}
.md-comparison .head .share .icon1 {
  background-image: url("../images/news-icon1.png");
}
.md-comparison .head .share .icon2 {
  background-image: url("../images/news-icon2.png");
}
.md-comparison .head .share .icon3 {
  background-image: url("../images/news-icon3.png");
}
.md-comparison .head .share .icon4 {
  background-image: url("../images/news-icon4.png");
}
.md-comparison .head .share .icon5 {
  background-image: url("../images/news-icon5.png");
}
.md-comparison .body {
  margin-top: 20px;
}
.md-comparison .body table {
  width: 100%;
  border: 1px solid #f5f5f5;
}
.md-comparison .body tr:nth-child(2n) td {
  background: #fff;
}
.md-comparison .body tr:nth-child(2n+1) td {
  background: #f5f5f5;
}
.md-comparison .body tr td {
  padding: 0 20px;
  height: 50px;
  font-size: 16px;
  color: #2e2e2e;
  transition: 0.2s;
}
.md-comparison .body tr .icon-nosupport {
  width: 12px;
  height: 12px;
  background-image: url("../images/icon-nosupport.png");
}
.md-comparison .body tr .icon-support {
  width: 12px;
  height: 12px;
  background-image: url("../images/support.png");
}
.md-comparison .body tr:hover td {
  background: #d4d3d3;
}
.house-list {
  background: #fff;
}
.house-list .head {
  font-size: 30px;
  color: #000;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.house-list .swiper-slide {
  box-shadow: -2.179px 3.355px 9px 0px rgba(0,0,0,0.1);
}
.house-list .wrap {
  padding-bottom: 70px;
}
.md-house-details {
  background: #fff;
  padding: 30px 0;
}
.md-house-details .wrap {
  display: flex;
  justify-content: space-between;
}
.md-house-details .right-con {
  width: 252px;
}
.md-house-details .right-con .img {
img
  width: 100%;
}
.md-house-details .link {
  display: flex;
  margin-top: 20px;
}
.md-house-details .link a {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  line-height: 38px;
  border: 1px solid #bdbdbd;
  margin-left: -1px;
  border-radius: 3px;
}
.md-house-details .link a:first-child {
  border-radius: 3px 0 0 3px;
}
.md-house-details .link a:last-child {
  border-radius: 0 3px 3px 0;
}
.md-house-details .link a:nth-child(2) {
  border-radius: 0;
}
.md-house-details .link a:hover {
  background: #3ac34c;
  border: 1px solid #3ac34c;
}
.md-house-details .link a:hover .icon-phone {
  background-image: url("../images/house-phone-1.png");
  background-repeat: no-repeat;
  background-position: center;
}
.md-house-details .link a:hover .icon-user {
  background-image: url("../images/house-user-1.png");
  background-repeat: no-repeat;
  background-position: center;
}
.md-house-details .link a:hover .icon-email {
  background-image: url("../images/house-email-1.png");
  background-repeat: no-repeat;
  background-position: center;
}
.md-house-details .link .icon {
  width: 25px;
  height: 25px;
}
.md-house-details .link .icon-phone {
  background-image: url("../images/house-phone.png");
  background-repeat: no-repeat;
  background-position: center;
}
.md-house-details .link .icon-user {
  background-image: url("../images/house-user.png");
  background-repeat: no-repeat;
  background-position: center;
}
.md-house-details .link .icon-email {
  background-image: url("../images/house-email.png");
  background-repeat: no-repeat;
  background-position: center;
}
.md-house-details .btn {
  text-align: center;
  margin-top: 20px;
}
.md-house-details .btn a {
  font-size: 18px;
  color: #fff;
  display: block;
  background: #282828;
  height: 48px;
  line-height: 48px;
  border-radius: 3px;
}
.md-house-details .btn a:hover {
  opacity: 0.9;
}
.md-house-details .share {
  text-align: right;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;
}
.md-house-details .share .icon {
  width: 25px;
  height: 25px;
}
.md-house-details .share .icon1 {
  background-image: url("../images/news-icon1.png");
}
.md-house-details .share .icon2 {
  background-image: url("../images/news-icon2.png");
}
.md-house-details .share .icon3 {
  background-image: url("../images/news-icon3.png");
}
.md-house-details .share .icon4 {
  background-image: url("../images/news-icon4.png");
}
.md-house-details .share .icon5 {
  background-image: url("../images/news-icon5.png");
}
.md-house-details .text h3 {
  font-size: 28px;
  margin-top: 25px;
  margin-bottom: 10px;
}
.md-house-details .text h3 a {
  color: #222;
}
.md-house-details .text h3 a:hover {
  color: #1f6bb8;
}
.md-house-details .text h3 h4 {
  font-size: 16px;
  color: #838383;
}
.md-house-details .tags {
  margin-top: 5px;
}
.md-house-details .contact {
  margin-top: 12px;
  font-size: 16px;
  color: #606060;
  line-height: 32px;
}
.md-house-details .contact .icon {
  padding-left: 0;
  width: 25px;
  height: 20px;
  top: 5px;
  background-position: center;
}
.md-house-details .contact .icon-phone {
  background-image: url("../images/team-phone.png");
}
.md-house-details .contact .icon-tel {
  background-image: url("../images/team-tell.png");
}
.md-house-details .contact .icon-mail {
  background-image: url("../images/team-mail.png");
}
.md-house-details .icon {
  font-size: 16px;
  color: #838383;
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 20px;
}
.md-house-details .icon-cn {
  background-size: 16px auto;
  background-image: url("../images/cn.png");
}
.md-house-details .icon-usa {
  margin-left: 10px;
  background-size: 16px auto;
  background-image: url("../images/us.png");
}
.md-house-details .left-con {
  width: 1113px;
}
.md-house-details .house-title {
  min-height: 54px;
  line-height: 54px;
  font-size: 46px;
  color: #000;
  margin-bottom: 15px;
}
.md-house-details .pc-slide {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.md-house-details .pc-slide .show-vrbox {
  display: inline-block;
  width: 82px;
  height: 82px;
  background-image: url("../images/house-vr.png");
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
}
.md-house-details .pc-slide .vr-box {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  background: #fff;
  z-index: 99;
}
.md-house-details .pc-slide.active {
  border: 1px solid #f0f0f0;
  box-shadow: -1.294px 4.83px 5px 0px rgba(0,0,0,0.1);
}
.md-house-details .pc-slide.active .vr-box {
  display: block;
  opacity: 1;
  visibility: visible;
}
.md-house-details .pc-slide.active .show-vrbox {
  left: auto;
  right: 0;
  z-index: 100;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../images/vr-close.png");
  background-color: rgba(0,0,0,0.5);
}
.md-house-details .gb-head {
  font-size: 30px;
  color: #000;
  border-bottom: 1px solid #919191;
  padding-bottom: 15px;
  margin-top: 47px;
  margin-bottom: 20px;
}
.md-house-details .view {
  width: 950px;
  height: 597px;
  position: relative;
  overflow: hidden;
}
.md-house-details .swiper-slide {
  cursor: pointer;
  overflow: hidden;
}
.md-house-details .swiper-slide img {
  width: 100%;
}
.md-house-details .preview {
  position: relative;
  width: 148px;
}
.md-house-details .preview .swiper-container {
  height: 540px;
}
.md-house-details .preview .arrow {
  height: 28px;
  background-color: #8d8d8d;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
}
.md-house-details .preview .arrow.arrow-right {
  background-image: url("../images/house-arrow2.png");
}
.md-house-details .preview .arrow.arrow-left {
  background-image: url("../images/house-arrow1.png");
}
.md-house-details .preview .arrow:hover {
  background-color: #1f6bb8;
}
.md-house-details .preview .swiper-slide {
  margin-top: 13px;
  height: 92px;
  overflow: hidden;
  opacity: 0.25;
}
.md-house-details .preview .swiper-slide.active-nav {
  opacity: 1;
  border: 3px solid #1f6bb8;
}
.house-info .head {
  margin-top: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.house-info .btn {
  width: 286px;
  height: 38px;
  border: 1px solid #ababab;
  font-size: 14px;
  color: #171727;
  display: inline-block;
  border-radius: 3px;
  text-align: center;
  line-height: 36px;
}
.house-info .btn .icon-col {
  width: 14px;
  height: 15px;
  top: 3px;
  display: inline-block;
  margin-right: 5px;
  background-image: url("../images/icon-col.png");
}
.house-info .btn:hover {
  background: #1f6bb8;
  border: 1px solid #1f6bb8;
  color: #fff;
}
.house-info .btn:hover .icon-col {
  background-image: url("../images/icon-col-1.png");
}
.house-info .price {
  display: flex;
  align-items: center;
}
.house-info .price .left-price {
  width: 30px;
}
.house-info .price .left-price span {
  font-size: 16px;
  color: #000;
}
.house-info .price .icon-rate {
  margin-top: 3px;
  margin-left: 3px;
  width: 24px;
  height: 12px;
  background-image: url("../images/icon-head1.png");
}
.house-info .right-price {
  font-size: 46px;
  color: #000;
}
.house-info .addr {
  font-size: 16px;
  color: #525252;
}
.house-info .icon-addr {
  width: 15px;
  height: 18px;
  top: 3px;
  margin-right: 5px;
  background-image: url("../images/house-aadr.png");
}
.house-info .body {
  margin-top: 30px;
  height: 220px;
  background: #f8f6f7;
  border-top: 1px solid #e5e5e5;
}
.house-info .body ul {
  display: flex;
  flex-wrap: wrap;
}
.house-info .body li {
  height: 110px;
  max-width: 23%;
  flex: 0 0 23%;
  display: flex;
  align-items: center;
}
.house-info .body li:nth-child(3),
.house-info .body li:nth-child(7) {
  flex: 0 0 30%;
  max-width: 30%;
}
.house-info .body .icon {
  height: 110px;
  flex: 0 0 58px;
  background-position: right center;
  margin-right: 15px;
}
.house-info .body .icon1 {
  background-image: url("../images/house-icon1.png");
}
.house-info .body .icon2 {
  background-image: url("../images/house-icon2.png");
}
.house-info .body .icon3 {
  background-image: url("../images/house-icon3.png");
}
.house-info .body .icon4 {
  background-image: url("../images/house-icon4.png");
}
.house-info .body .icon5 {
  background-image: url("../images/house-icon5.png");
}
.house-info .body .icon6 {
  background-image: url("../images/house-icon6.png");
}
.house-info .body .icon7 {
  background-image: url("../images/house-icon7.png");
}
.house-info .body .icon8 {
  background-image: url("../images/house-icon8.png");
}
.house-info .body .icon9 {
  background-image: url("../images/house-icon9.png");
}
.house-info .body .icon10 {
  background-image: url("../images/house-icon10.png");
}
.house-info .body .icon11 {
  background-image: url("../images/house-icon11.png");
}
.house-info .body .text h3 {
  font-size: 24px;
  color: #000;
  margin-bottom: 5px;
}
.house-info .body .text h4 {
  font-size: 16px;
  color: #000;
}
.house-terms table {
  width: 100%;
  border: 1px solid #ddd;
}
.house-terms table .align-right {
  text-align: right;
  padding-right: 15px;
  padding-left: 0;
}
.house-terms table .align-center {
  text-align: center;
}
.house-terms table thead th {
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  height: 73px;
  font-weight: normal;
  font-size: 18px;
  color: #000;
}
.house-terms table thead th:nth-child(1) {
  width: 99px;
}
.house-terms table tbody tr:nth-child(odd) td {
  background: #f8f6f7;
}
.house-terms table tbody tr:hover td {
  background: #f9f7f8;
}
.house-terms table tbody td {
  padding-left: 15px;
  height: 42px;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  font-size: 16px;
  color: #4d4d4f;
}
.tab-con {
  display: none;
}
.tab-con:first-child {
  display: block;
}
.head .tab-title:hover {
  cursor: pointer;
  color: #1f6bb8;
}
.head .tab-title:after {
  content: '';
  width: 20px;
  margin: 0 25px;
  display: inline-block;
  height: 40px;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-image: url("../images/house-line.png");
}
.head .tab-title:last-child:after {
  display: none;
}
.head .active {
  color: #1f6bb8;
}
.house-description .body {
  font-size: 14px;
  color: #525252;
  line-height: 25px;
}
.house-floor .body {
  position: relative;
  cursor: pointer;
}
.house-floor .body:before {
  content: '';
  display: inline-block;
  width: 54px;
  height: 54px;
  position: absolute;
  right: 0;
  bottom: 0;
  background-image: url("../images/zoom.png");
}
.house-project ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.house-project ul li {
  height: 65px;
  flex: 0 0 25%;
  font-size: 18px;
}
.house-project .icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.house-project .icon-park {
  background-image: url("../images/icon-park.png");
  background-repeat: no-repeat;
  background-position: center;
  top: 5px;
}
.house-project .icon-security {
  background-image: url("../images/icon-security.png");
  background-repeat: no-repeat;
  background-position: center;
  top: 5px;
}
.house-project .icon-pool {
  background-image: url("../images/icon-pool.png");
  background-repeat: no-repeat;
  background-position: center;
  top: 5px;
}
.house-project .icon-restaurant {
  background-image: url("../images/icon-restaurant.png");
  background-repeat: no-repeat;
  background-position: center;
  top: 5px;
}
.house-project .icon-supermarket {
  background-image: url("../images/icon-supermarket.png");
  background-repeat: no-repeat;
  background-position: center;
  top: 5px;
}
.house-project .icon-school {
  background-image: url("../images/icon-school.png");
  background-repeat: no-repeat;
  background-position: center;
  top: 5px;
}
.house-project .icon-access {
  background-image: url("../images/icon-access.png");
  background-repeat: no-repeat;
  background-position: center;
  top: 5px;
}
.house-project .icon-playroom {
  background-image: url("../images/icon-playroom.png");
  background-repeat: no-repeat;
  background-position: center;
  top: 5px;
}
.house-project .icon-balcony {
  background-image: url("../images/icon-balcony.png");
  background-repeat: no-repeat;
  background-position: center;
  top: 5px;
}
.house-project .icon-internet {
  background-image: url("../images/icon-internet.png");
  background-repeat: no-repeat;
  background-position: center;
  top: 5px;
}
.house-project .icon-facilitie {
  background-image: url("../images/icon-facilitie.png");
  background-repeat: no-repeat;
  background-position: center;
  top: 5px;
}
.house-project .icon-view {
  background-image: url("../images/icon-view.png");
  background-repeat: no-repeat;
  background-position: center;
  top: 5px;
}
.house-project .icon-allowed {
  background-image: url("../images/icon-allowed.png");
  background-repeat: no-repeat;
  background-position: center;
  top: 5px;
}
.house-project .icon-speed {
  background-image: url("../images/icon-speed.png");
  background-repeat: no-repeat;
  background-position: center;
  top: 5px;
}
.house-map .body {
  height: 495px;
}
.house-say .head {
  margin-bottom: 0;
}
.house-say .text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.house-say .text span {
  font-size: 30px;
  color: #000;
  cursor: pointer;
  margin: 0 2px;
}
.house-say .text,
.house-say textarea {
  background: #f8f6f7;
  height: 250px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  border: 1px solid #f8f6f7;
}
.house-say .say-submit {
  text-align: center;
  margin-top: 20px;
}
.house-say .say-submit input {
  font-size: 22px;
  width: 170px;
  height: 54px;
  background: #1f6bb8;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.house-say .say-submit input:hover {
  opacity: 0.9;
}
.house-comments .head {
  margin-bottom: 0;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}
.house-comments .head .page {
  width: 45px;
  height: 37px;
  border: 1px solid #828282;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 10px;
}
.house-comments .head .page:hover {
  background-color: #828282;
}
.house-comments .head .prev {
  background-image: url("../images/comment-prev-1.png");
  background-repeat: no-repeat;
  background-position: center;
}
.house-comments .head .prev:hover {
  background-image: url("../images/comment-prev.png");
  background-repeat: no-repeat;
  background-position: center;
}
.house-comments .head .next {
  background-image: url("../images/comment-next1.png");
  background-repeat: no-repeat;
  background-position: center;
}
.house-comments .head .next:hover {
  background-image: url("../images/comment-next.png");
  background-repeat: no-repeat;
  background-position: center;
}
.house-comments .head .right-info {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #aaa;
}
.house-comments .head .right-info i {
  color: #000;
}
.house-comments .body li {
  padding: 20px 0;
  border-bottom: 1px solid #e5e5e5;
}
.house-comments .body li:last-child {
  border-bottom: none;
}
.house-comments .body li .hd {
  display: flex;
  align-items: center;
}
.house-comments .body li .hd .user-img {
  margin-right: 15px;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  overflow: hidden;
}
.house-comments .body li .hd .date {
  margin-left: 120px;
}
.house-comments .body li .hd .user-name {
  font-size: 16px;
  color: #000;
}
.house-comments .body li .hd .date {
  font-size: 14px;
  color: #909090;
}
.house-comments .body li .bd {
  font-size: 14px;
  line-height: 25px;
  margin-top: 15px;
  color: #525252;
}
.house-comments .body li .ft {
  font-size: 14px;
  color: #1f6bb8;
  margin-top: 25px;
}
.house-project-slide {
  background: #fff;
  padding-top: 20px;
}
.house-project-slide .head {
  font-size: 30px;
  color: #000;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.house-project-slide .body {
  background: #fff;
}
.house-project-slide .body .item {
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.08);
  height: 536px;
}
.house-project-slide .body .item .text {
  overflow: hidden;
}
.house-project-slide .body .item .text .img {
  width: 63px;
  height: 63px;
  float: left;
}
.house-project-slide .body .item .text .img img {
  width: 100%;
  height: 100%;
}
.house-project-slide .body .item .text .title {
  margin-left: 80px;
}
.house-project-slide .body .item .date {
  font-size: 16px;
  color: #828282;
  padding: 0 25px;
  margin-bottom: 15px;
}
.house-project-slide .body .item .btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
}
.house-project-slide .body .item .btns a {
  width: 195px;
  height: 34px;
  line-height: 32px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #ababab;
  color: #171727;
  cursor: pointer;
  display: inline-block;
  transition: background 0.3s;
}
.house-project-slide .body .item .btns a:hover {
  background: #1f6bb8;
  border: 1px solid #1f6bb8;
  color: #fff;
}
.house-project-slide .body .item:before {
  width: 90px;
  height: 90px;
  display: inline-block;
  background-color: transparent;
  background-image: none;
  background-size: contain !important;
}
.house-project-slide .body .item.item-luxury:before {
  background-image: url("../images/item-luxury.png");
}
.house-project-slide .body .item.item-new:before {
  background-image: url("../images/item-news.png");
}
.md-house-map {
  min-height: 800px;
}
.md-wellcom {
  height: 100vh;
  position: relative;
}
.md-wellcom .swiper-container-horizontal>.swiper-pagination-bullets,
.md-wellcom .swiper-pagination-custom,
.md-wellcom .swiper-pagination-fraction {
  z-index: 999;
  bottom: 80px;
}
.md-wellcom .top-link a {
  letter-spacing: 4px;
  font-family: optimariman;
}
.md-wellcom .swiper-pagination-bullet {
  width: 16px;
  height: 16px;
}
.md-wellcom .swiper-slide {
  background-repeat: no-repeat;
  background-size: cover;
}
.md-wellcom .swiper-con {
  position: absolute;
  left: 0;
  right: 0;
  top: 54px;
  bottom: 70px;
  z-index: 99;
  font-size: 24px;
  color: #fff;
  text-align: center;
}
.md-wellcom .swiper-con a {
  color: #fff;
}
.md-wellcom .swiper-con span {
  display: inline-block;
  margin: 0 10px;
}
.md-wellcom .swiper-con .logo {
  margin: 0 auto;
  margin-top: 3%;
  text-align: center;
  width: 520px;
  margin-bottom: 3%;
}
.md-wellcom .swiper-con .slogn {
  color: #fff;
}
.md-wellcom .swiper-con .slogn h3 {
  font-size: 28px;
  margin-bottom: 20px;
  letter-spacing: 4px;
  font-family: optimariman;
}
.md-wellcom .swiper-con .slogn h4 {
  font-size: 26px;
}
.md-wellcom .swiper-con .link {
  text-align: center;
  line-height: 48px;
  margin-top: 4%;
}
.md-wellcom .swiper-con .link a {
  display: inline-block;
  width: 130px;
  height: 48px;
  background: #3b393a;
  font-size: 18px;
  color: #fff;
}
.md-wellcom .swiper-con .link a:hover {
  background: #1f6bb8;
}
.md-wellcom .swiper-foot {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 90px;
  z-index: 99;
  height: 74px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #fff;
}
.md-wellcom .swiper-foot a {
  color: #fff;
}
.md-wellcom .swiper-foot .btn {
  width: 150px;
  height: 33px;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
  border-radius: 5px;
  margin-left: 15px;
}
.md-wellcom .swiper-foot .right-btn {
  display: flex;
  align-items: center;
}
.md-wellcom .swiper-foot .google {
  background-image: url("../images/google.png");
}
.md-wellcom .swiper-foot .appStore {
  background-image: url("../images/appStore.png");
}
@media (max-width: 1600px) {
  .md-wellcom .swiper-con .logo {
    width: 420px;
  }
  .md-wellcom .swiper-con a {
    font-size: 20px;
  }
  .md-wellcom .swiper-con .slogn h3 {
    font-size: 22px;
  }
  .md-wellcom .swiper-con .slogn h4 {
    font-size: 20px;
  }
  .md-wellcom .swiper-con .link a {
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    margin-right: 1px;
  }
  .md-wellcom .swiper-foot {
    padding: 0 25px;
    height: 60px;
    line-height: 60px;
    font-size: 14px;
  }
  .md-wellcom .swiper-foot .btn {
    width: 107px;
    height: 23px;
    background-size: 77px 19px;
  }
  .md-wellcom .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
  }
}
@media (max-width: 1366px) {
  .md-wellcom .swiper-con .logo {
    width: 320px;
  }
}
.md-chartdata .chart-copyright {
  text-align: right;
  margin-top: 5px;
  margin-right: 10px;
  color: #333;
}
.md-chartdata .chart-row {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}
.md-chartdata .chart-row .head {
  height: 53px;
  background: #f1f1f1;
  border-bottom: 1px solid #dedede;
  font-size: 24px;
  color: #000;
  padding-left: 20px;
  line-height: 52px;
  position: relative;
}
.md-chartdata .chart-row .head .change-btns {
  color: #000;
  font-size: 14px;
  text-align: center;
  display: flex;
  position: absolute;
  top: 68px;
  right: 20px;
  z-index: 99;
}
.md-chartdata .chart-row .head .change-btns .btn {
  padding: 0 10px;
  height: 29px;
  line-height: 29px;
  border: 1px solid #c0c0c0;
  min-width: 88px;
  cursor: pointer;
}
.md-chartdata .chart-row .head .change-btns .btn:first-child {
  border-right: none;
}
.md-chartdata .chart-row .head .change-btns .btn.active {
  border: none;
  background: #1f6bb8;
  color: #fff;
}
.md-chartdata .chart-row .flex-50 {
  flex: 0 0 48%;
  border: 1px solid #dedede;
}
.md-chartdata .chart-row .flex1 {
  flex: 1;
  border: 1px solid #dedede;
}
.layout.active {
  padding-left: 0 !important;
}
@media (max-width: 1600px) {
  .index-list .body .tab-item .pager .swiper-pagination {
    font-size: 20px;
  }
  .index-about .video-begin {
    width: 50px;
    height: 50px;
  }
  .aside-nav.active .nav li .subnav {
    transform: translateX(45px);
  }
  .aside-nav .logo img {
    height: 45px;
  }
  .index-home .nav {
    height: 73px;
    font-size: 16px;
    padding: 0;
  }
  .index-vr .head p {
    font-size: 12px;
    line-height: 14px;
  }
  .index-vr .head h3 {
    margin-bottom: 20px;
  }
  .index-home .nav .item a {
    font-size: 16px;
  }
  .index-home .con .body .left-input .location input {
    font-size: 14px;
  }
  .index-home .nav .item .icon {
    width: 19px;
    top: 10px;
    margin-right: 10px;
    background-size: contain;
  }
  .index-list .wrap {
    padding-bottom: 80px;
  }
  .index-list .body .tab-item .pager {
    bottom: -58px;
  }
  .index-list .head li {
    padding: 0 30px;
  }
  .index-averages .left-chart {
    height: 275px;
  }
  .index-averages .right-list .item .tit {
    height: 65px;
    margin-bottom: 25px;
  }
  .index-download .right-img {
    flex: 0 0 500px;
  }
  .header {
    font-size: 12px;
    height: 59px;
  }
  .header .site-nav a {
    font-size: 12px;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
  }
  .index-list .body .item h3 a,
  .md-reference .item h3 a {
    font-size: 18px;
  }
  .index-list .body .item h4 a,
  .md-reference .item h4 a {
    font-size: 12px;
  }
  .md-house-details .icon {
    font-size: 12px;
  }
  .md-house-details .text h3 {
    margin-top: 10px;
  }
  .house-description .body {
    font-size: 12px;
    line-height: 1.5;
  }
  .house-terms .body {
    padding: 20px 0;
  }
  .house-project-slide .body .tab-item .item .date {
    font-size: 12px;
    padding: 0 15px;
  }
  .house-project-slide .body .tab-item .item .btns {
    padding: 0 15px;
  }
  .house-project-slide .body .tab-item .item:before {
    width: 66px;
    height: 66px;
  }
  .house-floor .body:before {
    width: 32px;
    height: 32px;
    background-size: contain;
  }
  .house-info .body li,
  .house-info .body .icon {
    height: 80px;
  }
  .house-info .body {
    height: 160px;
  }
  .index-more .more {
    transform: scale(0.8);
  }
  .header .meun-close,
  .header .meun-icon {
    transform: scale(0.8);
  }
  .header .en .subnav {
    font-size: 12px;
  }
  .index-home .index-banner .banner-con .slogen li {
    height: 100px;
  }
  .index-home .index-banner .banner-con .slogen img {
    max-height: 100%;
  }
  .index-home .index-banner .banner-con .slogen li .logo {
    height: 100px;
  }
  .index-home .index-banner .banner-con .slogen h3 {
    font-size: 28px;
  }
  .index-home .index-banner .banner-con .slogen h4 {
    font-size: 42px;
  }
  .index-home .index-banner .banner-con .slogen h5 {
    font-size: 12px;
  }
  .aside-nav .nav li a {
    height: 40px;
    line-height: 40px;
  }
  .aside-nav .logo {
    height: 59px;
    line-height: 59px;
    padding: 0 5px;
  }
  .aside-nav.active .logo img {
    height: 33px;
  }
  .aside-nav .nav-btn {
    background-size: 30px auto;
  }
  .aside-nav.active {
    width: 43px;
  }
  .layout.active {
    padding-left: 43px;
  }
  .aside-nav .nav li a {
    padding-left: 0;
  }
  .aside-nav .nav li .icon {
    flex: 0 0 43px;
    background-size: 38%;
  }
  .aside-nav.active .nav-btn {
    width: 43px;
  }
  .index-download .txt {
    font-size: 12px;
    line-height: 14px;
  }
  .index-download .title,
  .index-averages .head h3,
  .index-list .head li,
  .index-vr .head h3,
  .index-news .title h3,
  .index-about .content .texts li .tit {
    font-size: 36px;
    margin-top: 15px;
  }
  .index-download .qrcode {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .index-download {
    height: 460px;
  }
  .index-averages {
    padding-top: 35px;
  }
  .index-download .title {
    margin-top: 0;
    margin-bottom: 10px;
  }
  .index-list .head li .icon {
    transform: scale(0.8);
    top: 8px;
  }
  .index-about .content {
    padding: 30px;
  }
  .index-about .content .text-tab ul .icon {
    background-size: contain;
    width: 45px;
    height: 45px;
  }
  .footer .last .qrcode ul img {
    width: 100px;
    height: 100px;
  }
  .index-list .head li .icon {
    position: relative;
  }
  .index-averages .right-list .item h3 {
    font-size: 35px;
  }
  .index-news .item .swiper-slide {
    padding: 0 25px;
    height: 260px;
  }
  .index-list .body .item .tags,
  .md-reference .item .tags {
    font-size: 12px;
    padding: 0 15px;
  }
  .index-news {
    padding-top: 45px;
  }
  .index-news .title {
    margin-bottom: 20px;
  }
  .share-links {
    height: 110px;
  }
  .index-about .content {
    transition: none;
  }
  .index-about .content.animated {
    width: 680px;
    left: 540px;
    height: 358px;
  }
  .index-home .nav .item {
    line-height: 44px;
  }
  .footer .foot-copyright {
    height: 54px;
    font-size: 12px;
  }
  .footer .foot-copyright .btn {
    width: 107px;
    height: 23px;
    background-size: 77px 19px;
  }
  .footer .item a,
  .footer .item p {
    font-size: 12px;
  }
  .footer .item li {
    line-height: 12px;
  }
  .footer .item .tit {
    font-size: 16px;
  }
  .index-about .video {
    height: 415px;
  }
  .index-list .body .tab-item .pager .swiper-button-next,
  .index-list .body .tab-item .pager .swiper-button-prev {
    width: 33px;
    height: 33px;
    background-size: contain;
  }
  .index-about {
    padding: 60px 0;
  }
  .index-list .body .item .img img {
    height: 208px;
  }
  .index-list .body .tags {
    height: 50px;
  }
  .index-list .body h3 {
    margin-bottom: 2px;
  }
  .index-list .body .item {
    height: 345px;
  }
  .index-list .body .item:before {
    width: 36px;
    height: 36px;
    background-size: 18px 10px;
  }
  .index-list .body .item .img .right-link {
    transform: scale(0.8);
    top: 10px;
    right: 10px;
  }
  .index-news .swiper-slide h3 a {
    font-size: 25px;
    line-height: 1.1;
  }
  .index-list .body h3 a {
    font-size: 22px;
  }
  .index-about .video {
    width: 800px;
  }
  .index-news .swiper-slide h3 {
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .index-news .slick-list {
    height: 360px;
  }
  .index-news .swiper-slide p {
    font-size: 12px;
    line-height: 17px;
  }
  .index-news .swiper-slide h3 {
    font-size: 22px;
    line-height: 22px;
  }
  .index-list .body h4 a {
    font-size: 12px;
  }
  .index-home .con .head dd {
    font-size: 20px;
  }
  .index-home .con .body .search {
    margin-top: 12px;
  }
  .index-home .con .head dt {
    font-size: 16px;
  }
  .index-home .index-banner .banner-con .icon-publish {
    top: 2px;
  }
  .index-home .con .body .submit {
    height: 48px;
    font-size: 16px;
  }
  .index-home .con .body .left-input {
    height: 48px;
    line-height: 48px;
    font-size: 14px;
  }
  .index-home .con .body .icon {
    background-size: contain;
    height: 15px;
    width: 25px;
  }
  .index-home .con .body .icon-room {
    width: 12px;
    top: 2px;
  }
  .index-news .slick-dots li {
    height: 12px;
  }
  .index-news .slick-dots li.slick-active {
    width: 44px;
  }
  .index-home .con .body .icon-price {
    width: 10px;
    top: 3px;
  }
  .index-home .con .body .icon-type {
    height: 12px;
    top: 2px;
  }
  .index-news .swiper-slide .btn {
    width: 106px;
    height: 34px;
    line-height: 32px;
    font-size: 12px;
    left: 25px;
  }
  .index-news .slick-list {
    height: 340px;
  }
  .footer .footer-links {
    padding: 25px 40px;
    padding-left: 55px;
    padding-right: 25px;
  }
  .footer .last .qrcode ul h3 {
    margin-top: 5px;
  }
  .footer .last .qrcode ul li {
    margin-bottom: 15px;
    margin-left: 15px;
  }
  .index-list {
    padding-top: 40px;
  }
  .index-list .body {
    margin-top: 20px;
  }
  .index-list .body .item .img .left-text .number {
    font-size: 30px;
  }
  .share-links a {
    width: 36px;
    height: 36px;
    background-size: auto 15px;
  }
  .footer .foot-copyright {
    padding: 0 25px;
  }
  .index-download {
    margin-top: 35px;
  }
  .wrap {
    width: 1200px;
  }
  .index-averages .left-chart {
    flex: 0 0 500px;
  }
  .index-averages .right-list .item {
    margin-left: 15px;
    padding: 52px 15px;
  }
  .index-download .left-con {
    flex: 0 0 550px;
  }
  .index-vr {
    padding-top: 50px;
  }
  .index-list .body .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .md-reference .item .img .right-link {
    transform: scale(0.8);
  }
  .index-home .con .body .left-input {
    width: 740px;
  }
  .index-averages .tab-head li {
    font-size: 16px;
  }
  .index-averages .tab-head li {
    height: 38px;
    line-height: 36px;
    flex: 0 0 108px;
  }
  .md-comparison .head dd {
    width: 250px;
  }
  .md-comparison .head dt {
    width: 140px;
  }
  .md-comparison .head dt h3 {
    font-size: 22px;
    height: 90px;
  }
  .md-comparison .head .item .img img {
    height: 157px;
  }
  .md-comparison .head .item {
    height: 235px;
  }
  .md-comparison .body tr td {
    font-size: 14px;
  }
  .md-careers .title,
  .md-careers-post .head .title {
    font-size: 25px;
  }
  .md-careers-post .head .btn {
    height: 45px;
    line-height: 45px;
    width: 230px;
    font-size: 16px;
  }
  .md-careers-post .body {
    font-size: 14px;
  }
  .index-wrap {
    background-size: 100% auto;
  }
  .index-vr .body .img:before {
    display: none;
  }
  .md-careers .title,
  .md-careers-post .head .title {
    font-size: 20px;
  }
  .md-careers .text {
    font-size: 12px;
  }
  .md-careers .tags,
  .md-careers-post .head .tags {
    font-size: 12px;
  }
  .md-careers .tags .icon,
  .md-careers-post .head .tags .icon {
    width: 14px;
    background-size: contain;
  }
  .md-careers .title,
  .md-careers-post .head .title {
    margin-bottom: 5px;
  }
  .md-careers .tags,
  .md-careers-post .head .tags {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  .md-careers li {
    height: 290px;
    padding: 25px;
  }
  .md-careers .text {
    height: 130px;
  }
  .md-careers .btn {
    font-size: 14px;
  }
  .md-news-post .head .title {
    font-size: 26px;
  }
  .md-news-post .head .tags {
    bottom: 10px;
  }
  .md-news-post .body {
    padding: 35px 0;
  }
  .md-news-post .body .left-text {
    width: 880px;
  }
  .md-news-post .body .right-list {
    margin-left: 930px;
  }
  .md-news-post .body .right-list li .title a {
    font-size: 16px;
  }
  .md-news-post .body .right-list li .date {
    font-size: 12px;
  }
  .md-news-post .body .right-list li {
    padding: 15px 0;
  }
  .md-news-post .body {
    font-size: 14px;
  }
  .gb-banner {
    height: 270px;
  }
  .md-service li .texts {
    width: 450px;
  }
  .md-service li {
    margin-bottom: 45px;
  }
  .service-form {
    margin-top: 45px;
  }
  .service-form .form-wrap ul li .tit {
    font-size: 12px;
  }
  .service-form .form-wrap ul li .input input {
    font-size: 12px;
  }
  .service-form .form-wrap ul li textarea.input {
    font-size: 12px;
  }
  .md-contact .text {
    font-size: 12px;
  }
  .md-contact .list .item h3 {
    font-size: 16px;
  }
  .md-contact .list .icon {
    transform: scale(0.6);
  }
  .md-contact .list .item {
    padding-left: 55px;
    min-height: 40px;
    margin-bottom: 10px;
  }
  .md-contact .social-platform a {
    transform: scale(0.6);
    margin-left: -20px;
  }
  .md-contact .social-platform dl {
    font-size: 12px;
  }
  .md-honer .item .text .title {
    font-size: 32px;
    margin-bottom: 25px;
  }
  .md-honer .item .text .txt {
    font-size: 14px;
    line-height: 1.6;
  }
  .md-guide .title {
    font-size: 26px;
    margin: 30px 0;
  }
  .md-guide .list li .icon {
    transform: scale(0.6);
  }
  .md-guide .list li {
    padding-left: 125px;
  }
  .md-guide .list .text h3 {
    font-size: 18px;
  }
  .md-guide .list .text p {
    font-size: 12px;
  }
  .md-guide .list li .icon {
    top: 18px;
  }
  .detail-banner .data ul h3 {
    font-size: 50px;
  }
  .detail-banner .data ul li {
    height: 150px;
  }
  .detail-banner .data {
    width: 550px;
  }
  .detail-banner .data ul h4 {
    font-size: 16px;
  }
  .detail-banner {
    height: 550px;
  }
  .md-project .back-nav .back {
    font-size: 16px;
  }
  .md-project .back-nav .back .icon-back {
    height: 14px;
    background-size: contain;
    top: 2px;
  }
  .md-content .gb-title h3 {
    font-size: 22px;
  }
  .md-project .post-text {
    font-size: 12px;
  }
  .md-guide .list .text {
    padding-top: 22px;
    padding-bottom: 20px;
  }
  .md-honer .item >div {
    height: 445px;
  }
  .md-honer .item .text {
    padding: 60px 40px;
  }
  .service-form .form-wrap .submit {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .md-service .title {
    font-size: 22px;
    margin-bottom: 30px;
  }
  .md-contact .map-box {
    height: 500px;
  }
  .service-form .form-title {
    font-size: 36px;
  }
  .service-form .form-wrap ul li {
    height: 40px;
    line-height: 38px;
  }
  .md-service li .img {
    margin-left: 500px;
  }
  .md-service li:nth-child(2n) .img img {
    width: 560px;
  }
  .md-service li:nth-child(2n) .texts {
    margin-left: 600px;
  }
  .md-service li .texts .txt {
    height: 85px;
  }
  .md-service li .imgs .item img {
    height: 220px;
    width: 185px;
  }
  .md-service li:nth-child(2n) .texts {
    padding-left: 5px;
  }
  .md-service li .texts .txt {
    font-size: 14px;
  }
  .md-service li .texts .tit {
    font-size: 38px;
  }
  .md-news-post .body .right-list li .date {
    margin-bottom: 5px;
  }
  .index-vr .body .btns a {
    width: 303px;
    height: 40px;
    line-height: 38px;
  }
  .index-vr .body .btns .icon {
    width: 17px;
    height: 17px;
    background-size: cover;
    top: 3px;
  }
  .md-about .texts,
  .gb-banner .title {
    width: 1200px;
  }
  .gb-banner .title {
    font-size: 35px;
  }
  .gb-banner .title span {
    padding-bottom: 20px;
  }
  .gb-banner .title span:before {
    height: 3px;
  }
  .gb-banner .nav {
    width: 1280px;
  }
  .gb-head-menu a {
    font-size: 22px;
  }
  .gb-banner .nav {
    padding: 0 50px;
  }
  .md-about .texts {
    padding-top: 50px;
  }
  .md-about .title {
    font-size: 32px;
  }
  .md-about .ceo-msg .logo {
    width: 480px;
    height: 250px;
  }
  .md-about .ceo-msg >div {
    height: 400px;
  }
  .md-about .ceo-msg .text {
    padding: 50px;
  }
  .md-about .our .icon {
    width: 45px;
    height: 45px;
    background-size: contain;
  }
  .md-about .our .item {
    margin: 20px 0;
  }
  .md-about .our {
    padding: 50px 0;
  }
  .md-about .talent .item>div {
    height: 400px;
  }
  .md-about .talent .odd .text {
    padding: 45px;
  }
  .md-about .talent .item .text {
    padding: 45px;
  }
  .md-about .choose {
    padding-top: 60px;
  }
  .md-about .icon {
    height: 30px;
    width: 30px;
    background-size: contain;
  }
  .md-content {
    padding: 45px 0;
  }
  .md-padding {
    padding-top: 20px;
  }
  .md-error .top {
    font-size: 120px;
  }
  .md-error .bottom h3 {
    font-size: 22px;
  }
  .md-ucenter .top-con >div {
    height: 150px;
  }
  .md-ucenter .top-con {
    height: 150px;
  }
  .md-ucenter .top-con .user-info .img {
    width: 64px;
    height: 64px;
  }
  .md-ucenter .top-con .user-infos .tit {
    line-height: 30px;
    font-size: 12px;
  }
  .md-ucenter .top-con .user-infos .txt {
    line-height: 30px;
    font-size: 12px;
  }
  .md-ucenter .top-con .user-infos li {
    flex: 0 0 320px;
    margin-bottom: 8px;
  }
  .md-ucenter .top-con .user-infos {
    padding-top: 20px;
  }
  .md-ucenter .bottom-con .left-con li a {
    font-size: 14px;
    line-height: 45px;
    padding-left: 40px;
  }
  .md-ucenter .left-con {
    width: 225px;
  }
  .md-ucenter .right-con {
    margin-left: 225px;
  }
  .md-ucenter .right-con {
    padding: 0 35px;
  }
  .md-ucenter .bottom-con .right-con {
    padding: 20px 35px;
  }
  .md-ucenter {
    margin-bottom: 20px;
  }
  .layout.is-ucenter {
    padding-top: 65px;
  }
  .md-ucenter .right-con-browse .body .text .price {
    font-size: 18px;
  }
  .md-ucenter .top-con .user-infos .edit {
    font-size: 14px;
    background-size: 16px auto;
    padding-left: 25px;
  }
  .md-ucenter .right-con-message .texts .texts-title {
    font-size: 24px;
  }
  .md-ucenter .bottom-con .right-con .head .title span,
  .md-ucenter .bottom-con .right-con .head .title a {
    font-size: 16px;
  }
  .md-ucenter .bottom-con .right-con .head .title .icon-backup {
    background-size: 12px auto;
  }
  .md-ucenter .right-con-message .body li a .new {
    font-size: 12px;
  }
  .md-ucenter .right-con-password .password-form .btn {
    font-size: 16px;
  }
  .md-ucenter .right-con-password .password-form .text {
    height: 40px;
    line-height: 38px;
  }
  .md-ucenter .right-con-publish .tit {
    font-size: 14px;
  }
  .md-ucenter .right-con-publish li {
    flex: 0 0 285px;
  }
  .md-ucenter .bottom-con .right-con .head .right-link a {
    height: 24px;
    font-size: 14px;
    width: 200px;
  }
  .md-ucenter .bottom-con .right-con .head .icon-publish {
    background-size: 14px auto;
  }
  .md-ucenter .right-con-publish .foot a {
    font-size: 16px;
    width: 80px;
    height: 35px;
    line-height: 35px;
  }
  .md-ucenter .right-con-publish .text,
  .md-ucenter .right-con-submit .item .input {
    height: 35px;
    line-height: 33px;
  }
  .md-ucenter .right-con-submit .body .submit input {
    width: 200px;
    font-size: 18px;
    height: 40px;
  }
  .md-ucenter .right-con-browse .body .img img {
    width: 187px;
    height: 118px;
  }
  .md-ucenter .right-con-browse .body .text {
    margin-left: 215px;
  }
  .md-ucenter .right-con-browse .body .text h4 {
    margin-bottom: 10px;
  }
  .md-ucenter .right-con-browse .body .text .price {
    margin-bottom: 10px;
  }
  .md-ucenter .top-con .user-infos .text {
    height: 30px;
    line-height: 28px;
  }
  .md-team .body ul li,
  .md-team-details .item {
    flex: 0 0 224px;
    margin-right: 20px;
  }
  .md-team .body .img {
    height: 300px;
  }
  .md-team .body ul li h3,
  .md-team-details .item h3 {
    margin-top: 15px;
    font-size: 22px;
  }
  .md-team .body ul li h4,
  .md-team-details .item h4 {
    font-size: 14px;
  }
  .gb-pager a,
  .gb-pager span {
    font-size: 14px;
    height: 35px;
    line-height: 33px;
    width: 35px;
  }
  .gb-pager .page-btn {
    width: 95px;
  }
  .gb-pager {
    padding: 25px 0;
  }
  .md-team .body ul li,
  .md-team-details .item {
    margin-bottom: 25px;
  }
  .md-team .body {
    padding-bottom: 0;
  }
  .md-content {
    padding: 20px 0;
  }
  .md-team .body ul li.is-best:before,
  .md-team-details .item.is-best:before {
    width: 60px;
    height: 60px;
    background-size: contain;
  }
  .md-team .body ul .img .icon-phone,
  .md-team-details .item .img .icon-phone {
    background-size: contain;
    width: 20px;
  }
  .gb-banner .nav {
    height: 60px;
  }
  .gb-banner .nav .back {
    height: 60px;
    width: 60px;
    background-size: contain;
  }
  .md-team-details .right-con .title {
    font-size: 22px;
  }
  .md-team-details .right-con {
    margin-left: 300px;
  }
  .md-team-details .form li .input,
  .md-team-details .form li.select .input {
    font-size: 14px;
    height: 40px;
    line-height: 38px;
  }
  .md-team-details .form li.select {
    height: 41px;
  }
  .md-team-details .form li.select input.input {
    height: 38px;
  }
  .md-team-details .form li textarea.input {
    height: 180px;
  }
  .md-team-details .form .submit,
  .service-form .form-wrap .submit {
    height: 45px;
    width: 220px;
    line-height: 45px;
  }
  .index-list .body .item .img img,
  .md-reference .item .img img {
    height: 240px;
  }
  .md-reference .item .text {
    padding: 10px 20px;
  }
  .index-list .body .item,
  .md-reference .item {
    height: 375px;
  }
  .index-list .body .item .tags,
  .md-reference .item .tags {
    height: 45px;
  }
  .index-list .body .item .text,
  .md-reference .item .text {
    height: 85px;
  }
  .index-list .body .item:before,
  .md-reference .item:before {
    width: 36px;
    height: 36px;
    background-size: 18px 10px;
  }
  .md-project .item {
    height: 420px;
  }
  .md-project .item:before {
    width: 70px;
    height: 70px;
    background-size: contain;
  }
  .md-project .item .text .img {
    width: 50px;
    height: 50px;
  }
  .md-project .item .text .title {
    margin-left: 65px;
  }
  .md-project .item h3 a {
    font-size: 20px;
  }
  .md-project .item h4 a {
    font-size: 14px;
  }
  .md-project .item .date {
    padding: 0 16px;
    font-size: 14px;
  }
  .md-project .item .btns a {
    width: 160px;
  }
  .md-project .item {
    height: 450px;
  }
  .md-project .item .btn {
    padding: 0 20px;
  }
  .detail-banner .text h3 {
    font-size: 32px;
  }
  .detail-banner .text p {
    font-size: 14px;
    margin-top: 10px;
  }
  .md-reference .sear-box,
  .md-dubai .sear-box {
    height: 40px;
    line-height: 38px;
  }
  .md-dubai .sear-box input.submit,
  .md-dubai .sear-box input.text {
    height: 35px;
    font-size: 12px;
  }
  .md-dubai .sear-box input.submit {
    transform: scale(0.8);
  }
  .md-dubai .body .item {
    height: auto;
  }
  .md-dubai .body .item .text-left h4 {
    font-size: 30px;
  }
  .md-dubai .body .item .text>div,
  .md-dubai .body .item .text {
    height: 90px;
  }
  .md-dubai .body .item .text-left {
    width: 90px;
  }
  .md-dubai .body .item .text-right {
    margin-left: 105px;
  }
  .md-dubai .body .item .text-right h3 {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .md-dubai .body .item .text-right p {
    font-size: 12px;
    background-size: auto 12px;
  }
  .md-dubai .body .item .text-left h3 {
    font-size: 14px;
  }
  .md-dubai .body .item .img,
  .md-dubai .body .item .img img {
    height: 236px;
  }
  .md-emaar .emaar-text {
    padding: 20px 35px;
  }
  .md-emaar .emaar-text .img {
    width: 65px;
    height: 99px;
  }
  .md-emaar .emaar-text .txt {
    font-size: 12px;
    margin-left: 180px;
  }
  .md-developers li {
    height: 145px;
  }
  .md-developers li .black-pic img {
    width: 45px;
    height: 45px;
  }
  .md-developers li .hide {
    padding: 25px;
  }
  .md-developers li .hide .txt {
    font-size: 12px;
  }
  .md-developers li .hide .more {
    font-size: 12px;
  }
  .md-developers li .hide .color-pic {
    flex: 0 0 45px;
    height: 45px;
  }
  .md-ucenter .right-con-reservation .body .date li,
  .md-ucenter .right-con-reservation .body .row .text {
    height: 38px;
    line-height: 36px;
    font-size: 12px;
  }
  .md-ucenter .right-con-reservation .body .row-text .text {
    height: 150px;
  }
  .md-ucenter .right-con-reservation .body .row-submit .submit {
    width: 220px;
    font-size: 14px;
  }
  .md-ucenter .right-con-reservation .body .title {
    font-size: 16px;
  }
  .md-ucenter .right-con-reservation .body .date li span {
    font-size: 16px;
  }
  .md-ucenter .right-con-reservation .body .date li em {
    font-size: 12px;
  }
  .md-house-details .left-con {
    width: 920px;
  }
  .md-house-details .view {
    width: 785px;
  }
  .md-house-details .preview .swiper-container {
    height: 438px;
  }
  .md-house-details .preview .swiper-slide {
    height: 78px;
  }
  .md-house-details .preview .swiper-slide {
    margin-top: 8px;
  }
  .md-house-details .view:before {
    width: 45px;
    height: 45px;
    background-size: contain;
  }
  .md-house-details .preview {
    width: 120px;
  }
  .md-house-details .house-project ul li {
    font-size: 14px;
  }
  .md-house-details .house-project .icon {
    transform: scale(0.8);
  }
  .md-house-details .gb-head {
    font-size: 24px;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .md-house-details .house-project ul li {
    height: 45px;
  }
  .md-house-details .house-say .text span {
    font-size: 24px;
  }
  .md-house-details .house-say .say-submit input {
    font-size: 16px;
    height: 40px;
  }
  .md-reference-list .filter .filter-item .to {
    font-size: 12px;
  }
  .md-house-details .house-comments .head .page {
    height: 30px;
    width: 40px;
  }
  .md-house-details .house-comments .head .right-info {
    font-size: 12px;
  }
  .house-list .wrap {
    padding-bottom: 20px;
  }
  .md-house-details .house-title {
    font-size: 32px;
  }
  .md-house-details .house-title {
    margin-bottom: 0;
  }
  .md-house-details .right-con .share {
    margin-bottom: 0;
  }
  .gb-banner .title {
    bottom: 115px;
  }
  .md-house-details .right-con {
    width: 210px;
  }
  .md-house-details .right-con .link a .icon {
    transform: scale(0.8);
  }
  .md-house-details .right-con .btn a {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
  }
  .md-house-details .view {
    height: 495px;
  }
  .md-house-details .house-info .body .text h3 {
    font-size: 16px;
  }
  .md-house-details .house-info .body .text h4 {
    font-size: 12px;
  }
  .md-house-details .house-info .body .icon {
    transform: scale(0.8);
    margin-right: 5px;
  }
  .gb-banner .publish {
    font-size: 14px;
    width: 200px;
  }
  .gb-banner .publish .icon-publish {
    transform: scale(0.8);
  }
  .md-reference-list .filter .filter-item a {
    font-size: 12px;
  }
  .md-reference-list .filter .filter-item a {
    padding: 6px 5px;
  }
  .index-list .body .item h3,
  .md-reference .item h3 {
    margin-bottom: 6px;
  }
  .index-list .body .item .text,
  .md-reference .item .text {
    height: 75px;
  }
  .gb-meun .right-sear {
    width: 420px;
    height: 35px;
  }
  .gb-meun .right-sear form {
    height: 33px;
  }
  .gb-meun .right-sear .select select,
  .gb-meun .right-sear input {
    line-height: 30px;
    height: 30px;
  }
  .index-list .body .item .img .left-text .number,
  .md-reference .item .img .left-text .number {
    font-size: 30px;
  }
  .house-project-slide .body .item {
    height: 445px;
  }
  .house-project-slide {
    background: #fff;
  }
  .house-project-slide .wrap {
    padding-bottom: 20px;
  }
  .house-project-slide .body .item .btns a {
    width: 148px;
  }
  .house-project-slide .body .item .text .img {
    width: 48px;
    height: 48px;
  }
  .house-project-slide .body .item .text .title {
    margin-left: 60px;
  }
  .index-list .body .slide3 .item:before {
    width: 70px;
    height: 70px;
    background-size: contain;
  }
  .house-list .head,
  .house-project-slide .head {
    font-size: 24px;
  }
  .house-list .body,
  .house-project-slide .body {
    margin-top: 0;
  }
}
