
@media (max-width: 1600px)
    .index-list .body .tab-item .pager .swiper-pagination
        font-size 20px
    .index-about .video-begin
        width 50px
        height 50px
    .aside-nav.active .nav li .subnav
        transform translateX(45px)
    .aside-nav .logo img
        height 45px
    .index-home .nav
        height 73px
        font-size 16px
        padding 0
    .index-vr .head p
        font-size 12px
        line-height 14px
    .index-vr .head h3
        margin-bottom 20px
    .index-home .nav .item a
        font-size 16px
    .index-home .con .body .left-input .location input
        font-size 14px
    .index-home .nav .item .icon
        width 19px
        top 10px
        margin-right 10px
        background-size contain
    .index-list .wrap
        padding-bottom 80px
    .index-list .body .tab-item .pager
        bottom -58px
    .index-list .head li
        padding 0 30px
    .index-averages .left-chart
        height 275px
    .index-averages .right-list .item .tit
        height 65px
        margin-bottom 25px
    .index-download .right-img
        flex 0 0 500px
    .header
        font-size 12px
        height 59px
    .header .site-nav a
        font-size 12px
        height 30px
        line-height 30px
        padding 0 10px
    .index-list .body .item h3 a, .md-reference .item h3 a
        font-size 18px
    .index-list .body .item h4 a, .md-reference .item h4 a
        font-size 12px
    .md-house-details .icon
        font-size 12px
    .md-house-details .text h3
        margin-top 10px
    .house-description .body
        font-size 12px
        line-height 1.5
    .house-terms .body
        padding 20px 0
    .house-project-slide .body .tab-item .item .date
        font-size 12px
        padding 0 15px
    .house-project-slide .body .tab-item .item .btns
        padding 0 15px
    .house-project-slide .body .tab-item .item:before
        width 66px
        height 66px
    .house-floor .body:before
        width 32px
        height 32px
        background-size contain
    .house-info .body li, .house-info .body .icon
        height 80px
    .house-info .body
        height 160px
    .index-more .more
        transform scale(0.8)
    .header .meun-close, .header .meun-icon
        transform scale(0.8)
    .header .en .subnav
        font-size 12px
    .index-home .index-banner .banner-con .slogen li
        height 100px
    .index-home .index-banner .banner-con .slogen img
        max-height 100%
    .index-home .index-banner .banner-con .slogen li .logo
        height 100px
    .index-home .index-banner .banner-con .slogen h3
        font-size 28px
    .index-home .index-banner .banner-con .slogen h4
        font-size 42px
    .index-home .index-banner .banner-con .slogen h5
        font-size 12px
    .aside-nav .nav li a
        height 40px
        line-height 40px
    .aside-nav .logo
        height 59px
        line-height 59px
        padding 0 5px
    .aside-nav.active .logo img
        height 33px
    .aside-nav .nav-btn
        background-size 30px auto
    .aside-nav.active
        width 43px
    .layout.active
        padding-left 43px
    .aside-nav .nav li a
        padding-left 0
    .aside-nav .nav li .icon
        flex 0 0 43px
        background-size 38%
    .aside-nav.active .nav-btn
        width 43px
    .index-download .txt
        font-size 12px
        line-height 14px
    .index-download .title, .index-averages .head h3, .index-list .head li, .index-vr .head h3, .index-news .title h3, .index-about .content .texts li .tit
        font-size 36px
        margin-top 15px
    .index-download .qrcode
        margin-top 30px
        margin-bottom 30px
    .index-download
        height 460px
    .index-averages
        padding-top 35px
    .index-download .title
        margin-top 0
        margin-bottom 10px
    .index-list .head li .icon
        transform scale(0.8)
        top 8px
    .index-about .content
        padding 30px
    .index-about .content .text-tab ul .icon
        background-size contain
        width 45px
        height 45px
    .footer .last .qrcode ul img
        width 100px
        height 100px
    .index-list .head li .icon
        position relative
    .index-averages .right-list .item h3
        font-size 35px
    .index-news .item .swiper-slide
        padding 0 25px
        height 260px
    .index-list .body .item .tags, .md-reference .item .tags
        font-size 12px
        padding 0 15px
    .index-news
        padding-top 45px
    .index-news .title
        margin-bottom 20px
    .share-links
        height 110px
    .index-about .content
        transition none
    .index-about .content.animated
        width 680px
        left 540px
        height 358px
    .index-home .nav .item
        line-height 44px
    .footer .foot-copyright
        height 54px
        font-size 12px
    .footer .foot-copyright .btn
        width 107px
        height 23px
        background-size 77px 19px
    .footer .item a, .footer .item p
        font-size 12px
    .footer .item li
        line-height 12px
    .footer .item .tit
        font-size 16px
    .index-about .video
        height 415px
    .index-list .body .tab-item .pager .swiper-button-next, .index-list .body .tab-item .pager .swiper-button-prev
        width 33px
        height 33px
        background-size contain
    .index-about
        padding 60px 0
    .index-list .body .item .img img
        height 208px
    .index-list .body .tags
        height 50px
    .index-list .body h3
        margin-bottom 2px
    .index-list .body .item
        height 345px
    .index-list .body .item:before
        width 36px
        height 36px
        background-size 18px 10px
    .index-list .body .item .img .right-link
        transform scale(0.8)
        top 10px
        right 10px
    .index-news .swiper-slide h3 a
        font-size 25px
        line-height 1.1
    .index-list .body h3 a
        font-size 22px
    .index-about .video
        width 800px
    .index-news .swiper-slide h3
        padding-bottom 10px
        margin-bottom 20px
    .index-news .slick-list
        height 360px
    .index-news .swiper-slide p
        font-size 12px
        line-height 17px
    .index-news .swiper-slide h3
        font-size 22px
        line-height 22px
    .index-list .body h4 a
        font-size 12px
    .index-home .con .head dd
        font-size 20px
    .index-home .con .body .search
        margin-top 12px
    .index-home .con .head dt
        font-size 16px
    .index-home .index-banner .banner-con .icon-publish
        top 2px
    .index-home .con .body .submit
        height 48px
        font-size 16px
    .index-home .con .body .left-input
        height 48px
        line-height 48px
        font-size 14px
    .index-home .con .body .icon
        background-size contain
        height 15px
        width 25px
    .index-home .con .body .icon-room
        width 12px
        top 2px
    .index-news .slick-dots li
        height 12px
    .index-news .slick-dots li.slick-active
        width 44px
    .index-home .con .body .icon-price
        width 10px
        top 3px
    .index-home .con .body .icon-type
        height 12px
        top 2px
    .index-news .swiper-slide .btn
        width 106px
        height 34px
        line-height 32px
        font-size 12px
        left 25px
    .index-news .slick-list
        height 340px
    .footer .footer-links
        padding 25px 40px
        padding-left 55px
        padding-right 25px
    .footer .last .qrcode ul h3
        margin-top 5px
    .footer .last .qrcode ul li
        margin-bottom 15px
        margin-left 15px
    .index-list
        padding-top 40px
    .index-list .body
        margin-top 20px
    .index-list .body .item .img .left-text .number
        font-size 30px
    .share-links a
        width 36px
        height 36px
        background-size auto 15px
    .footer .foot-copyright
        padding 0 25px
    .index-download
        margin-top 35px
    .wrap
        width 1200px
    .index-averages .left-chart
        flex 0 0 500px
    .index-averages .right-list .item
        margin-left 15px
        padding 52px 15px
    .index-download .left-con
        flex 0 0 550px
    .index-vr
        padding-top 50px
    .index-list .body .title
        display flex
        flex-direction column
        justify-content center
    .md-reference .item .img .right-link
        transform scale(0.8)
    .index-home .con .body .left-input
        width 740px
    .index-averages .tab-head li
        font-size 16px
    .index-averages .tab-head li
        height 38px
        line-height 36px
        flex 0 0 108px
    .md-comparison .head dd
        width 250px
    .md-comparison .head dt
        width 140px
    .md-comparison .head dt h3
        font-size 22px
        height 90px
    .md-comparison .head .item .img img
        height 157px
    .md-comparison .head .item
        height 235px
    .md-comparison .body tr td
        font-size 14px
    .md-careers .title, .md-careers-post .head .title
        font-size 25px
    .md-careers-post .head .btn
        height 45px
        line-height 45px
        width 230px
        font-size 16px
    .md-careers-post .body
        font-size 14px
    .index-wrap
        background-size 100% auto
    .index-vr .body .img:before
        display none
    .md-careers .title, .md-careers-post .head .title
        font-size 20px
    .md-careers .text
        font-size 12px
    .md-careers .tags, .md-careers-post .head .tags
        font-size 12px
    .md-careers .tags .icon, .md-careers-post .head .tags .icon
        width 14px
        background-size contain
    .md-careers .title, .md-careers-post .head .title
        margin-bottom 5px
    .md-careers .tags, .md-careers-post .head .tags
        padding-bottom 15px
        margin-bottom 15px
    .md-careers li
        height 290px
        padding 25px
    .md-careers .text
        height 130px
    .md-careers .btn
        font-size 14px
    .md-news-post .head .title
        font-size 26px
    .md-news-post .head .tags
        bottom 10px
    .md-news-post .body
        padding 35px 0
    .md-news-post .body .left-text
        width 880px
    .md-news-post .body .right-list
        margin-left 930px
    .md-news-post .body .right-list li .title a
        font-size 16px
    .md-news-post .body .right-list li .date
        font-size 12px
    .md-news-post .body .right-list li
        padding 15px 0
    .md-news-post .body
        font-size 14px
    .gb-banner
        height 270px
    .md-service li .texts
        width 450px
    .md-service li
        margin-bottom 45px
    .service-form
        margin-top 45px
    .service-form .form-wrap ul li .tit
        font-size 12px
    .service-form .form-wrap ul li .input input
        font-size 12px
    .service-form .form-wrap ul li textarea.input
        font-size 12px
    .md-contact .text
        font-size 12px
    .md-contact .list .item h3
        font-size 16px
    .md-contact .list .icon
        transform scale(0.6)
    .md-contact .list .item
        padding-left 55px
        min-height 40px
        margin-bottom 10px
    .md-contact .social-platform a
        transform scale(0.6)
        margin-left -20px
    .md-contact .social-platform dl
        font-size 12px
    .md-honer .item .text .title
        font-size 32px
        margin-bottom 25px
    .md-honer .item .text .txt
        font-size 14px
        line-height 1.6
    .md-guide .title
        font-size 26px
        margin 30px 0
    .md-guide .list li .icon
        transform scale(0.6)
    .md-guide .list li
        padding-left 125px
    .md-guide .list .text h3
        font-size 18px
    .md-guide .list .text p
        font-size 12px
    .md-guide .list li .icon
        top 18px
    .detail-banner .data ul h3
        font-size 50px
    .detail-banner .data ul li
        height 150px
    .detail-banner .data
        width 550px
    .detail-banner .data ul h4
        font-size 16px
    .detail-banner
        height 550px
    .md-project .back-nav .back
        font-size 16px
    .md-project .back-nav .back .icon-back
        height 14px
        background-size contain
        top 2px
    .md-content .gb-title h3
        font-size 22px
    .md-project .post-text
        font-size 12px
    .md-guide .list .text
        padding-top 22px
        padding-bottom 20px
    .md-honer .item >div
        height 445px
    .md-honer .item .text
        padding 60px 40px
    .service-form .form-wrap .submit
        margin-top 30px
        margin-bottom 30px
    .md-service .title
        font-size 22px
        margin-bottom 30px
    .md-contact .map-box
        height 500px
    .service-form .form-title
        font-size 36px
    .service-form .form-wrap ul li
        height 40px
        line-height 38px
    .md-service li .img
        margin-left 500px
    .md-service li:nth-child(2n) .img img
        width 560px
    .md-service li:nth-child(2n) .texts
        margin-left 600px
    .md-service li .texts .txt
        height 85px
    .md-service li .imgs .item img
        height 220px
        width 185px
    .md-service li:nth-child(2n) .texts
        padding-left 5px
    .md-service li .texts .txt
        font-size 14px
    .md-service li .texts .tit
        font-size 38px
    .md-news-post .body .right-list li .date
        margin-bottom 5px
    .index-vr .body .btns a
        width 303px
        height 40px
        line-height 38px
    .index-vr .body .btns .icon
        width 17px
        height 17px
        background-size cover
        top 3px
    .md-about .texts, .gb-banner .title
        width 1200px
    .gb-banner .title
        font-size 35px
    .gb-banner .title span
        padding-bottom 20px
    .gb-banner .title span:before
        height 3px
    .gb-banner .nav
        width 1280px
    .gb-head-menu a
        font-size 22px
    .gb-banner .nav
        padding 0 50px
    .md-about .texts
        padding-top 50px
    .md-about .title
        font-size 32px
    .md-about .ceo-msg .logo
        width 480px
        height 250px
    .md-about .ceo-msg >div
        height 400px
    .md-about .ceo-msg .text
        padding 50px
    .md-about .our .icon
        width 45px
        height 45px
        background-size contain
    .md-about .our .item
        margin 20px 0
    .md-about .our
        padding 50px 0
    .md-about .talent .item>div
        height 400px
    .md-about .talent .odd .text
        padding 45px
    .md-about .talent .item .text
        padding 45px
    .md-about .choose
        padding-top 60px
    .md-about .icon
        height 30px
        width 30px
        background-size contain
    .md-content
        padding 45px 0
    .md-padding
        padding-top 20px
    .md-error .top
        font-size 120px
    .md-error .bottom h3
        font-size 22px
    .md-ucenter .top-con >div
        height 150px
    .md-ucenter .top-con
        height 150px
    .md-ucenter .top-con .user-info .img
        width 64px
        height 64px
    .md-ucenter .top-con .user-infos .tit
        line-height 30px
        font-size 12px
    .md-ucenter .top-con .user-infos .txt
        line-height 30px
        font-size 12px
    .md-ucenter .top-con .user-infos li
        flex 0 0 320px
        margin-bottom 8px
    .md-ucenter .top-con .user-infos
        padding-top 20px
    .md-ucenter .bottom-con .left-con li a
        font-size 14px
        line-height 45px
        padding-left 40px
    .md-ucenter .left-con
        width 225px
    .md-ucenter .right-con
        margin-left 225px
    .md-ucenter .right-con
        padding 0 35px
    .md-ucenter .bottom-con .right-con
        padding 20px 35px
    .md-ucenter
        margin-bottom 20px
    .layout.is-ucenter
        padding-top 65px
    .md-ucenter .right-con-browse .body .text .price
        font-size 18px
    .md-ucenter .top-con .user-infos .edit
        font-size 14px
        background-size 16px auto
        padding-left 25px
    .md-ucenter .right-con-message .texts .texts-title
        font-size 24px
    .md-ucenter .bottom-con .right-con .head .title span, .md-ucenter .bottom-con .right-con .head .title a
        font-size 16px
    .md-ucenter .bottom-con .right-con .head .title .icon-backup
        background-size 12px auto
    .md-ucenter .right-con-message .body li a .new
        font-size 12px
    .md-ucenter .right-con-password .password-form .btn
        font-size 16px
    .md-ucenter .right-con-password .password-form .text
        height 40px
        line-height 38px
    .md-ucenter .right-con-publish .tit
        font-size 14px
    .md-ucenter .right-con-publish li
        flex 0 0 285px
    .md-ucenter .bottom-con .right-con .head .right-link a
        height 24px
        font-size 14px
        width 200px
    .md-ucenter .bottom-con .right-con .head .icon-publish
        background-size 14px auto
    .md-ucenter .right-con-publish .foot a
        font-size 16px
        width 80px
        height 35px
        line-height 35px
    .md-ucenter .right-con-publish .text, .md-ucenter .right-con-submit .item .input
        height 35px
        line-height 33px
    .md-ucenter .right-con-submit .body .submit input
        width 200px
        font-size 18px
        height 40px
    .md-ucenter .right-con-browse .body .img img
        width 187px
        height 118px
    .md-ucenter .right-con-browse .body .text
        margin-left 215px
    .md-ucenter .right-con-browse .body .text h4
        margin-bottom 10px
    .md-ucenter .right-con-browse .body .text .price
        margin-bottom 10px
    .md-ucenter .top-con .user-infos .text
        height 30px
        line-height 28px
    .md-team .body ul li, .md-team-details .item
        flex 0 0 224px
        margin-right 20px
    .md-team .body .img
        height 300px
    .md-team .body ul li h3, .md-team-details .item h3
        margin-top 15px
        font-size 22px
    .md-team .body ul li h4, .md-team-details .item h4
        font-size 14px
    .gb-pager a, .gb-pager span
        font-size 14px
        height 35px
        line-height 33px
        width 35px
    .gb-pager .page-btn
        width 95px
    .gb-pager
        padding 25px 0
    .md-team .body ul li, .md-team-details .item
        margin-bottom 25px
    .md-team .body
        padding-bottom 0
    .md-content
        padding 20px 0
    .md-team .body ul li.is-best:before, .md-team-details .item.is-best:before
        width 60px
        height 60px
        background-size contain
    .md-team .body ul .img .icon-phone, .md-team-details .item .img .icon-phone
        background-size contain
        width 20px
    .gb-banner .nav
        height 60px
    .gb-banner .nav .back
        height 60px
        width 60px
        background-size contain
    .md-team-details .right-con .title
        font-size 22px
    .md-team-details .right-con
        margin-left 300px
    .md-team-details .form li .input, .md-team-details .form li.select .input
        font-size 14px
        height 40px
        line-height 38px
    .md-team-details .form li.select
        height 41px
    .md-team-details .form li.select input.input
        height 38px
    .md-team-details .form li textarea.input
        height 180px
    .md-team-details .form .submit, .service-form .form-wrap .submit
        height 45px
        width 220px
        line-height 45px
    .index-list .body .item .img img, .md-reference .item .img img
        height 240px
    .md-reference .item .text
        padding 10px 20px
    .index-list .body .item, .md-reference .item
        height 375px
    .index-list .body .item .tags, .md-reference .item .tags
        height 45px
    .index-list .body .item .text, .md-reference .item .text
        height 85px
    .index-list .body .item:before, .md-reference .item:before
        width 36px
        height 36px
        background-size 18px 10px
    .md-project .item
        height 420px
    .md-project .item:before
        width 70px
        height 70px
        background-size contain
    .md-project .item .text .img
        width 50px
        height 50px
    .md-project .item .text .title
        margin-left 65px
    .md-project .item h3 a
        font-size 20px
    .md-project .item h4 a
        font-size 14px
    .md-project .item .date
        padding 0 16px
        font-size 14px
    .md-project .item .btns a
        width 160px
    .md-project .item
        height 450px
        .btn
            padding 0 20px
    .detail-banner .text h3
        font-size 32px
    .detail-banner .text p
        font-size 14px
        margin-top 10px
    .md-reference .sear-box, .md-dubai .sear-box
        height 40px
        line-height 38px
    .md-dubai .sear-box input.submit, .md-dubai .sear-box input.text
        height 35px
        font-size 12px
    .md-dubai .sear-box input.submit
        transform scale(0.8)
    .md-dubai .body .item
        height auto
    .md-dubai .body .item .text-left h4
        font-size 30px
    .md-dubai .body .item .text>div, .md-dubai .body .item .text
        height 90px
    .md-dubai .body .item .text-left
        width 90px
    .md-dubai .body .item .text-right
        margin-left 105px
    .md-dubai .body .item .text-right h3
        font-size 18px
        margin-bottom 5px
    .md-dubai .body .item .text-right p
        font-size 12px
        background-size auto 12px
    .md-dubai .body .item .text-left h3
        font-size 14px
    .md-dubai .body .item .img, .md-dubai .body .item .img img
        height 236px
    .md-emaar .emaar-text
        padding 20px 35px
    .md-emaar .emaar-text .img
        width 65px
        height 99px
    .md-emaar .emaar-text .txt
        font-size 12px
        margin-left 180px
    .md-developers li
        height 145px
    .md-developers li .black-pic img
        width 45px
        height 45px
    .md-developers li .hide
        padding 25px
    .md-developers li .hide .txt
        font-size 12px
    .md-developers li .hide .more
        font-size 12px
    .md-developers li .hide .color-pic
        flex 0 0 45px
        height 45px
    .md-ucenter .right-con-reservation .body .date li, .md-ucenter .right-con-reservation .body .row .text
        height 38px
        line-height 36px
        font-size 12px
    .md-ucenter .right-con-reservation .body .row-text .text
        height 150px
    .md-ucenter .right-con-reservation .body .row-submit .submit
        width 220px
        font-size 14px
    .md-ucenter .right-con-reservation .body .title
        font-size 16px
    .md-ucenter .right-con-reservation .body .date li span
        font-size 16px
    .md-ucenter .right-con-reservation .body .date li em
        font-size 12px
    .md-house-details .left-con
        width 920px
    .md-house-details .view
        width 785px
    .md-house-details .preview .swiper-container
        height 438px
    .md-house-details .preview .swiper-slide
        height 78px
    .md-house-details .preview .swiper-slide
        margin-top 8px
    .md-house-details .view:before
        width 45px
        height 45px
        background-size contain
    .md-house-details .preview
        width 120px
    .md-house-details .house-project ul li
        font-size 14px
    .md-house-details .house-project .icon
        transform scale(0.8)
    .md-house-details .gb-head
        font-size 24px
        margin-top 15px
        margin-bottom 5px
    .md-house-details .house-project ul li
        height 45px
    .md-house-details .house-say .text span
        font-size 24px
    .md-house-details .house-say .say-submit input
        font-size 16px
        height 40px
    .md-reference-list .filter .filter-item .to
        font-size 12px
    .md-house-details .house-comments .head .page
        height 30px
        width 40px
    .md-house-details .house-comments .head .right-info
        font-size 12px
    .house-list .wrap
        padding-bottom 20px
    .md-house-details .house-title
        font-size 32px
    .md-house-details .house-title
        margin-bottom 0
    .md-house-details .right-con .share
        margin-bottom 0
    .gb-banner .title
        bottom 115px
    .md-house-details .right-con
        width 210px
    .md-house-details .right-con .link a .icon
        transform scale(0.8)
    .md-house-details .right-con .btn a
        font-size 14px
        height 40px
        line-height 40px
    .md-house-details .view
        height 495px
    .md-house-details .house-info .body .text h3
        font-size 16px
    .md-house-details .house-info .body .text h4
        font-size 12px
    .md-house-details .house-info .body .icon
        transform scale(0.8)
        margin-right 5px
    .gb-banner .publish
        font-size 14px
        width 200px
    .gb-banner .publish .icon-publish
        transform scale(0.8)
    .md-reference-list .filter .filter-item a
        font-size 12px
    .md-reference-list .filter .filter-item a
        padding 6px 5px
    .index-list .body .item h3, .md-reference .item h3
        margin-bottom 6px
    .index-list .body .item .text, .md-reference .item .text
        height 75px
    .gb-meun .right-sear
        width 420px
        height 35px
        form
            height 33px
    .gb-meun .right-sear .select select, .gb-meun .right-sear input
        line-height 30px
        height 30px
    .index-list .body .item .img .left-text .number, .md-reference .item .img .left-text .number
        font-size 30px
    .house-project-slide .body .item
        height 445px
    .house-project-slide
        .wrap
            padding-bottom 20px
        background #ffffff
    .house-project-slide .body .item .btns a
        width 148px
    .house-project-slide .body .item .text .img
        width 48px
        height 48px
    .house-project-slide .body .item .text .title
        margin-left 60px
    .index-list .body .slide3 .item:before
        width 70px
        height 70px
        background-size contain
    .house-list .head, .house-project-slide .head
        font-size 24px
    .house-list .body ,.house-project-slide .body
        margin-top 0
