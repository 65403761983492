.house-list
    background #ffffff

    .head
        font-size 30px
        color #000
        margin-bottom 0
        padding-bottom 10px
    .swiper-slide
        box-shadow -2.179px 3.355px 9px 0px rgba(0, 0, 0, 0.1)
    .wrap
        padding-bottom 70px
.md-house-details
    background #ffffff
    padding 30px 0

    .wrap
        display flex
        justify-content space-between
    .right-con
        width 252px

        .img
            img

            width 100%
    .link
        display flex
        margin-top 20px

        a
            flex 1
            display flex
            align-items center
            justify-content center
            height 38px
            line-height 38px
            border 1px solid #bdbdbd
            margin-left -1px
            border-radius 3px

            &:first-child
                border-radius 3px 0 0 3px
            &:last-child
                border-radius 0 3px 3px 0
            &:nth-child(2)
                border-radius 0
            &:hover
                background #3ac34c
                border 1px solid #3ac34c

                .icon-phone
                    bg(house-phone-1, png)
                .icon-user
                    bg(house-user-1, png)
                .icon-email
                    bg(house-email-1, png)
        .icon
            wd(25, 25)
        .icon-phone
            bg(house-phone, png)
        .icon-user
            bg(house-user, png)
        .icon-email
            bg(house-email, png)
    .btn
        text-align center
        margin-top 20px

        a
            font-size 18px
            color #ffffff
            display block
            background #282828
            height 48px
            line-height 48px
            border-radius 3px

            &:hover
                opacity 0.9
    .share
        text-align right
        height 54px
        display flex
        align-items center
        justify-content flex-end
        margin-bottom 15px

        .icon
            width 25px
            height 25px
        .icon1
            background-image url('../images/news-icon1.png')
        .icon2
            background-image url('../images/news-icon2.png')
        .icon3
            background-image url('../images/news-icon3.png')
        .icon4
            background-image url('../images/news-icon4.png')
        .icon5
            background-image url('../images/news-icon5.png')
    .text
        h3
            font-size 28px
            margin-top 25px
            margin-bottom 10px

            a
                color #222222

                &:hover
                    color $color
            h4
                font-size 16px
                color #838383
    .tags
        margin-top 5px
    .contact
        margin-top 12px
        font-size 16px
        color #606060
        line-height 32px

        .icon
            padding-left 0
            width 25px
            height 20px
            top 5px
            background-position center
        .icon-phone
            background-image url('../images/team-phone.png')
        .icon-tel
            background-image url('../images/team-tell.png')
        .icon-mail
            background-image url('../images/team-mail.png')
    .icon
        font-size 16px
        color #838383
        background-repeat no-repeat
        background-position left center
        padding-left 20px
    .icon-cn
        background-size 16px auto
        background-image url('../images/cn.png')
    .icon-usa
        margin-left 10px
        background-size 16px auto
        background-image url('../images/us.png')
    .left-con
        width 1113px
    .house-title
        min-height 54px
        line-height 54px
        font-size 46px
        color #000000
        margin-bottom 15px
    .pc-slide
        display flex
        justify-content space-between
        position relative

        .show-vrbox
            display inline-block
            width 82px
            height 82px
            background-image url('../images/house-vr.png')
            position absolute
            top 0
            left 0
            z-index 2
            cursor pointer
        .vr-box
            position absolute
            left 0
            right 0
            bottom 0
            top 0
            opacity 0
            visibility hidden
            transition 0.2s
            background #ffffff
            z-index 99
        &.active
            border 1px solid #f0f0f0
            box-shadow -1.294px 4.83px 5px 0px rgba(0, 0, 0, 0.1)

            .vr-box
                display block
                opacity 1
                visibility visible
            .show-vrbox
                left auto
                right 0
                z-index 100
                width 40px
                height 40px
                background-repeat no-repeat
                background-position center
                background-image url('../images/vr-close.png')
                background-color rgba(#000, 0.5)
    .gb-head
        font-size 30px
        color #000000
        border-bottom 1px solid #919191
        padding-bottom 15px
        margin-top 47px
        margin-bottom 20px
    .view
        width 950px
        height 597px
        position relative
        overflow hidden
    .swiper-slide
        cursor pointer
        overflow hidden
        img
            width 100%
    .preview
        position relative
        width 148px

        .swiper-container
            height 540px
        .arrow
            height 28px
            background-color #8d8d8d
            display block
            background-position center
            background-repeat no-repeat

            &.arrow-right
                background-image url('../images/house-arrow2.png')
            &.arrow-left
                background-image url('../images/house-arrow1.png')
            &:hover
                background-color $color
        .swiper-slide
            margin-top 13px
            height 92px
            overflow hidden
            opacity 0.25
        .swiper-slide.active-nav
            opacity 1
            border 3px solid $color
.house-info
    .head
        margin-top 38px
        display flex
        justify-content space-between
        align-items center
    .btn
        width 286px
        height 38px
        border 1px solid #ababab
        font-size 14px
        color #171727
        display inline-block
        border-radius 3px
        text-align center
        line-height 36px

        .icon-col
            width 14px
            height 15px
            top 3px
            display inline-block
            margin-right 5px
            background-image url('../images/icon-col.png')
        &:hover
            background $color
            border 1px solid $color
            color #ffffff

            .icon-col
                background-image url('../images/icon-col-1.png')
    .price
        display flex
        align-items center

        .left-price
            width 30px

            span
                font-size 16px
                color #000000
        .icon-rate
            margin-top 3px
            margin-left 3px
            width 24px
            height 12px
            background-image url('../images/icon-head1.png')
    .right-price
        font-size 46px
        color #000000
    .addr
        font-size 16px
        color #525252
    .icon-addr
        width 15px
        height 18px
        top 3px
        margin-right 5px
        background-image url('../images/house-aadr.png')
    .body
        margin-top 30px
        height 220px
        background #f8f6f7
        border-top 1px solid #e5e5e5

        ul
            display flex
            flex-wrap wrap
        li
            height 110px
            max-width 23%
            flex 0 0 23%
            display flex
            align-items center

            &:nth-child(3), &:nth-child(7)
                flex 0 0 30%
                max-width 30%
        .icon
            height 110px
            flex 0 0 58px
            background-position right center
            margin-right 15px
        .icon1
            background-image url('../images/house-icon1.png')
        .icon2
            background-image url('../images/house-icon2.png')
        .icon3
            background-image url('../images/house-icon3.png')
        .icon4
            background-image url('../images/house-icon4.png')
        .icon5
            background-image url('../images/house-icon5.png')
        .icon6
            background-image url('../images/house-icon6.png')
        .icon7
            background-image url('../images/house-icon7.png')
        .icon8
            background-image url('../images/house-icon8.png')
        .icon9
            background-image url('../images/house-icon9.png')
        .icon10
            background-image url('../images/house-icon10.png')
        .icon11
            background-image url('../images/house-icon11.png')
        .text
            h3
                font-size 24px
                color #000000
                margin-bottom 5px
            h4
                font-size 16px
                color #000000
.house-terms
    table
        width 100%
        border 1px solid #dddddd

        .align-right
            text-align right
            padding-right 15px
            padding-left 0
        .align-center
            text-align center
        thead
            th
                border-bottom 1px solid #dddddd
                border-right 1px solid #dddddd
                height 73px
                font-weight normal
                font-size 18px
                color #000000
            th:nth-child(1)
                width 99px
        tbody
            tr:nth-child(odd)
                td
                    background #f8f6f7
            tr:hover
                td
                    background #f8f6f7 + 10%
            td
                padding-left 15px
                height 42px
                border-bottom 1px solid #dddddd
                border-right 1px solid #dddddd
                font-size 16px
                color #4d4d4f
.tab-con
    display none

    &:first-child
        display block
.head
    .tab-title
        &:hover
            cursor pointer
            color $color
        &:after
            content ''
            width 20px
            margin 0 25px
            display inline-block
            height 40px
            background-repeat no-repeat
            background-position bottom center
            background-image url('../images/house-line.png')
        &:last-child
            &:after
                display none
    .active
        color $color
.house-description
    .body
        font-size 14px
        color #525252
        line-height 25px
.house-floor
    .body
        position relative
        cursor pointer

        &:before
            content ''
            display inline-block
            width 54px
            height 54px
            position absolute
            right 0
            bottom 0
            background-image url('../images/zoom.png')
.house-project
    ul
        display flex
        flex-wrap wrap
        align-items center

        li
            height 65px
            flex 0 0 25%
            font-size 18px
    .icon
        wd(25, 25)

        margin-right 10px
    for icon in park security pool restaurant supermarket school access playroom balcony internet facilitie view allowed speed
        .icon-{icon}
            bg('icon-' + icon, png)

            top 5px
.house-map
    .body
        height 495px
.house-say
    .head
        margin-bottom 0
    .text
        display flex
        align-items center
        justify-content center

        span
            font-size 30px
            color #000000
            cursor pointer
            margin 0 2px
    .text, textarea
        background #f8f6f7
        height 250px
        padding 10px
        font-size 16px
        width 100%
        border 1px solid #f8f6f7
    .say-submit
        text-align center
        margin-top 20px

        input
            font-size 22px
            width 170px
            height 54px
            background $color
            color #ffffff
            border-radius 5px
            cursor pointer

            &:hover
                opacity 0.9
.house-comments
    .head
        margin-bottom 0
        justify-content space-between
        align-items flex-end
        display flex

        .page
            width 45px
            height 37px
            border 1px solid #828282
            display inline-block
            background-repeat no-repeat
            background-position center
            margin-left 10px

            &:hover
                background-color #828282
        .prev
            bg(comment-prev-1, png)
            &:hover
                bg(comment-prev, png)
        .next
            bg(comment-next1, png)
            &:hover
                bg(comment-next, png)
        .right-info
            display flex
            align-items center
            font-size 16px
            color #aaaaaa

            i
                color #000000
    .body
        li
            padding 20px 0
            border-bottom 1px solid #e5e5e5

            &:last-child
                border-bottom none
            .hd
                display flex
                align-items center

                .user-img
                    margin-right 15px
                    width 46px
                    height 46px
                    border-radius 50%
                    overflow hidden
                .date
                    margin-left 120px
                .user-name
                    font-size 16px
                    color #000000
                .date
                    font-size 14px
                    color #909090
            .bd
                font-size 14px
                line-height 25px
                margin-top 15px
                color #525252
            .ft
                font-size 14px
                color #1f6bb8
                margin-top 25px
.house-project-slide
    background #ffffff
    padding-top 20px

    .head
        font-size 30px
        color #000
        margin-bottom 0
        padding-bottom 10px
    .body
        background #ffffff

        .item
            box-shadow 0px 5px 5px 0px rgba(0, 0, 0, 0.08)
            height 536px

            .text
                overflow hidden

                .img
                    width 63px
                    height 63px
                    float left

                    img
                        width 100%
                        height 100%
                .title
                    margin-left 80px
            .date
                font-size 16px
                color #828282
                padding 0 25px
                margin-bottom 15px
            .btns
                display flex
                align-items center
                justify-content space-between
                padding 0 25px

                a
                    width 195px
                    height 34px
                    line-height 32px
                    text-align center
                    border-radius 3px
                    border 1px solid #ababab
                    color #171727
                    cursor pointer
                    display inline-block
                    transition background 0.3s

                    &:hover
                        background $color
                        border 1px solid $color
                        color #ffffff
            &:before
                width 90px
                height 90px
                display inline-block
                background-color transparent
                background-image none
                background-size contain !important
            &.item-luxury
                &:before
                    background-image url('../images/item-luxury.png')
            &.item-new
                &:before
                    background-image url('../images/item-news.png')
.md-house-map
    min-height 800px
