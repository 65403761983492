@charset 'UTF-8'
@font-face
    font-family 'RobotoRegular'
    src url('../fonts/Roboto-Regular.woff2')
    font-weight normal
    font-style normal
@font-face
    font-family 'optimariman'
    src url('../fonts/OPTIMAROMAN.ttf')
    font-weight normal
    font-style normal
@font-face
    font-family 'RobotoThin'
    src url('../fonts/Roboto-Thin.ttf')
    font-weight normal
    font-style normal

select
    background none
//     -webkit-appearance: none;
body, ol, ul, h1, h2, h3, h4, h5, h6, p, th, td, dl, dd, form, fieldset, legend, input, textarea, select
    margin 0
    padding 0

body
    font-family 'RobotoThin', 'Helvetica Neue', Helvetica, Tahoma, Arial, 'Microsoft Yahei', 'PingFang SC', 'Hiragino Sans GB', 'WenQuanYi Micro Hei', sans-serif
    font-size 12px

h1, h2, h3, h4, h5, h6
    font-weight normal
    font-size 100%

table
    border-collapse collapse
    border-spacing 0

/* set html5 elements to block */
ul, div, article, aside, footer, header, hgroup, menu, nav, section
    box-sizing border-box
    -moz-box-sizing border-box
    -webkit-box-sizing border-box

a
    text-decoration none
    transition background 218ms cubic-bezier(0.42, 0, 0.58, 1)

a:hover
    text-decoration none

li
    list-style none

/* container width */
img
    border 0
    vertical-align middle

input, button, textarea ,select
    border 0 none
    outline 0 none
    font-family Arial, sans-serif
    // -webkit-appearance: none;
address, em, i
    font-style normal

textarea
    resize none
    -webkit-appearance: none;
html
    -moz-text-size-adjust 100%
    -webkit-text-size-adjust 100%

label
    cursor pointer

$primary = #0B61B1
$primaryhover = #0A508E
$primary2 = #0183D6
$primary2hover = #DE7C03

input[type='submit']
    background transparent

::selection
    color #fff
    background $primary

::-moz-selection
    color #fff
    background $primary

/* 定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸 */
::-webkit-scrollbar
    width 6px
    height 8px
    background-color $primary

/* 定义滚动条轨道 内阴影+圆角 */
::-webkit-scrollbar-track
    box-shadow inset 0 0 6px rgba(0, 0, 0, 0.3)
    background-color #fff

/* 定义滑块 内阴影+圆角 */
::-webkit-scrollbar-thumb
    box-shadow inset 0 0 6px rgba(0, 0, 0, 0.3)
    background-color #cccccc

.clearfix
    display block
    zoom 1

    &:after
        content ''
        display block
        font-size 0
        height 0
        clear both
        visibility hidden

toe(num)
    overflow hidden
    text-overflow ellipsis // 文本溢出显示省略号
    display -webkit-box
    -webkit-line-clamp num
    -webkit-box-orient vertical

bg(name, type)
    background-image url('../images/' + name + '.' + type)
    background-repeat no-repeat
    background-position center

wd($w, $h)
    width $w px
    height $h px